import axios from "../network";
import * as types from "../types/notice";

const getNoticesRequest = () => {
	return {
		type: types.GET_NOTICES_REQUEST,
	};
};

const getNoticesSuccess = (data) => {
	return {
		type: types.GET_NOTICES_SUCCESS,
		data,
	};
};

const getNoticesFailure = (error) => {
	return {
		type: types.GET_NOTICES_FAILURE,
		error,
	};
};

export const getNotices = (token, data) => {
	return (dispatch) => {
		dispatch(getNoticesRequest());
		return axios
			.get("/admin/notice/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getNoticesSuccess(res.data));
			})
			.catch((err) => dispatch(getNoticesFailure(err)));
	};
};

const getNoticeInfoRequest = () => {
	return {
		type: types.GET_NOTICE_INFO_REQUEST,
	};
};

const getNoticeInfoSuccess = (data) => {
	return {
		type: types.GET_NOTICE_INFO_SUCCESS,
		data,
	};
};

const getNoticeInfoFailure = (error) => {
	return {
		type: types.GET_NOTICE_INFO_FAILURE,
		error,
	};
};

export const getNoticeInfo = (token, noticeID) => {
	return (dispatch) => {
		dispatch(getNoticeInfoRequest());
		return axios
			.get("/admin/notice/", {
				headers: { token: token },
				params: { notice_id: noticeID },
				data: {},
			})
			.then((res) => {
				return dispatch(getNoticeInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getNoticeInfoFailure(err)));
	};
};

const createNoticeRequest = () => {
	return {
		type: types.CREATE_NOTICE_REQUEST,
	};
};

const createNoticeSuccess = (data) => {
	return {
		type: types.CREATE_NOTICE_SUCCESS,
		data,
	};
};

const createNoticeFailure = (error) => {
	return {
		type: types.CREATE_NOTICE_FAILURE,
		error,
	};
};

export const createNotice = (token, data) => {
	return (dispatch) => {
		dispatch(createNoticeRequest());
		return axios
			.post("/admin/notice/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createNoticeSuccess(res.data));
			})
			.catch((err) => dispatch(createNoticeFailure(err)));
	};
};

const updateNoticeRequest = () => {
	return {
		type: types.UPDATE_NOTICE_REQUEST,
	};
};

const updateNoticeSuccess = (data) => {
	return {
		type: types.UPDATE_NOTICE_SUCCESS,
		data,
	};
};

const updateNoticeFailure = (error) => {
	return {
		type: types.UPDATE_NOTICE_FAILURE,
		error,
	};
};

export const updateNotice = (token, data) => {
	return (dispatch) => {
		dispatch(updateNoticeRequest());
		return axios
			.put("/admin/notice/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateNoticeSuccess(res.data));
			})
			.catch((err) => dispatch(updateNoticeFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_NOTICE_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_NOTICE_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_NOTICE_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_NOTICE_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_NOTICE_PAGE,
		page: page,
	};
};
