/*
概要 : 文字列をbooleanに変換する際に、Boolean("false") だと true になってしまうので、より直感的な真偽値への変換を行う関数
params : target(any)
return : (boolean)
*/
export const convertToBoolean = (target) => {
	if (typeof target !== 'string') { 
		return Boolean(target); 
	}

	const lowerStr = target.toLowerCase();
	var convertedStr = target;

	switch (lowerStr) {
		case "true":
			convertedStr = true;
			break;
		case "false":
			convertedStr = false;
			break;
		default:
			convertedStr = target !== "";
	}

	return convertedStr;
}
