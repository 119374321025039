// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";
import { convertToBoolean } from "utils/convertToBoolean";

// action
import {
  getOrders,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/order";

// constants
import { applicationList } from "constants/application";
import { ORDER_STATUS } from "constants/status";

// style
import "./style.scss";

const Order = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.order.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const orderList = useSelector((state) => state.order.orderList.list);
  const orderCount = useSelector((state) => state.order.orderList.count);
  const isProcessing = useSelector((state) => state.order.isProcessing);
  const filter = useSelector((state) => state.order.filter);
  const error = useSelector((state) => state.order.error);
  const currentPage = useSelector((state) => state.order.currentPage);

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FIELDS = {
    orderNumber: "order_number",
    cartID: "cart_id",
    customerFullName: "customer_fullname",
    mail: "mail",
    postalCode: "postal_code",
    phoneNumber: "phone_number",
    address: "address",
    orderStatus: "order_status",
    orderDateStart: "order_date_start",
    orderDateEnd: "order_date_end",
    okinawaIsland: "okinawa_island",
    sortBy: "sort_by",
    sortType: "sort_type",
    product: "product",
    route: "route",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      order_number: elements[FIELDS.orderNumber].value,
      cart_id: elements[FIELDS.cartID].value,
      customer_fullname: elements[FIELDS.customerFullName].value,
      mail: elements[FIELDS.mail].value,
      postal_code: elements[FIELDS.postalCode].value,
      phone_number: elements[FIELDS.phoneNumber].value,
      address: elements[FIELDS.address].value,
      order_status: elements[FIELDS.orderStatus].value,
      order_date_start: elements[FIELDS.orderDateStart].value.replace(
        /-/g,
        "/"
      ),
      order_date_end: elements[FIELDS.orderDateEnd].value.replace(/-/g, "/"),
      okinawa_island: elements[FIELDS.okinawaIsland].value,
      route: elements[FIELDS.route].value,
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      product: elements[FIELDS.product].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getOrders(token, data));
    }

    const filterData = {
      cartID: elements[FIELDS.cartID].value,
      orderNumber: elements[FIELDS.orderNumber].value,
      customerFullName: elements[FIELDS.customerFullName].value,
      mail: elements[FIELDS.mail].value,
      postalCode: elements[FIELDS.postalCode].value,
      phoneNumber: elements[FIELDS.phoneNumber].value,
      address: elements[FIELDS.address].value,
      orderStatus: elements[FIELDS.orderStatus].value,
      orderDateStart: elements[FIELDS.orderDateStart].value,
      orderDateEnd: elements[FIELDS.orderDateEnd].value,
      okinawaIsland: convertToBoolean(elements[FIELDS.okinawaIsland].value),
      route: elements[FIELDS.route].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
      product: elements[FIELDS.product].value,
    };

    dispatch(setFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/order/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="order_list list">
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>カート番号</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.cartID}
                    type="text"
                    defaultValue={filter.cartID}
                  />
                </td>
              </tr>
              <tr>
                <th>注文番号</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.orderNumber}
                    type="text"
                    defaultValue={filter.orderNumber}
                  />
                </td>
              </tr>
              <tr>
                <th>注文者氏名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.customerFullName}
                    type="text"
                    defaultValue={filter.customerFullName}
                  />
                </td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.phoneNumber}
                    type="text"
                    defaultValue={filter.phoneNumber}
                  />
                </td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.mail}
                    type="text"
                    defaultValue={filter.mail}
                  />
                </td>
              </tr>
              <tr>
                <th>郵便番号</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.postalCode}
                    type="text"
                    defaultValue={filter.postalCode}
                  />
                </td>
              </tr>
              <tr>
                <th>住所</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.address}
                    type="text"
                    defaultValue={filter.address}
                  />
                </td>
              </tr>
              <tr>
                <th>注文日時</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.orderDateStart}
                    type="date"
                    defaultValue={filter.orderDateStart}
                  />
                  〜
                  <input
                    className="design_input"
                    name={FIELDS.orderDateEnd}
                    type="date"
                    defaultValue={filter.orderDateEnd}
                  />
                </td>
              </tr>
              <tr>
                <th>流入経路</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.route}
                    type="text"
                    defaultValue={filter.route}
                  />
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.orderStatus}
                      type="radio"
                      value={ORDER_STATUS.shipped}
                      defaultChecked={
                        filter.orderStatus === ORDER_STATUS.shipped
                      }
                    />
                    発送完了
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.orderStatus}
                      type="radio"
                      value={ORDER_STATUS.unshipped}
                      defaultChecked={
                        filter.orderStatus === ORDER_STATUS.unshipped
                      }
                    />
                    未発送
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.orderStatus}
                      type="radio"
                      value={ORDER_STATUS.returning}
                      defaultChecked={
                        filter.orderStatus === ORDER_STATUS.returning
                      }
                    />
                    返品対応中
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.orderStatus}
                      type="radio"
                      value={ORDER_STATUS.returned}
                      defaultChecked={
                        filter.orderStatus === ORDER_STATUS.returned
                      }
                    />
                    返金済み
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.orderStatus}
                      type="radio"
                      value={ORDER_STATUS.cancel}
                      defaultChecked={
                        filter.orderStatus === ORDER_STATUS.cancel
                      }
                    />
                    キャンセル
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.orderStatus}
                      type="radio"
                      value=""
                      defaultChecked={filter.orderStatus === ""}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>商品の種類</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.product}
                      type="radio"
                      value="king"
                      defaultChecked={filter.product === "king"}
                    />
                    キング
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.product}
                      type="radio"
                      value="queen"
                      defaultChecked={filter.product === "queen"}
                    />
                    クイーン
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.product}
                      type="radio"
                      value=""
                      defaultChecked={filter.product === ""}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>沖縄・離島への注文</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.okinawaIsland}
                      type="radio"
                      value={true}
                      defaultChecked={filter.okinawaIsland}
                    />
                    沖縄・離島
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.okinawaIsland}
                      type="radio"
                      value={false}
                      defaultChecked={!filter.okinawaIsland}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>ソート</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                    defaultValue={filter.sortBy}
                  >
                    <option value="order_at">注文日時</option>
                    <option value="">なし</option>
                  </select>
                  <label className="radio_button">
                    <input
                      type="radio"
                      name={FIELDS.sortType}
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      type="radio"
                      name={FIELDS.sortType}
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="order_cart_id">カート番号</th>
                <th className="order_number">注文番号</th>
                <th className="order_status">注文ステータス</th>
                <th className="order_route">流入経路</th>
                <th className="order_name">購入者氏名</th>
                <th className="order_postal_code">郵便番号</th>
                <th className="order_address">住所</th>
                <th className="order_phone_number">電話番号</th>
                <th className="order_mail">メールアドレス</th>
                <th className="order_date">注文日時</th>
                <th className="order_total">
                  購入金額
                  <br />
                  （総計）
                </th>
              </tr>
            </thead>
            <tbody>
              {orderList.map((order) => (
                <OrderItem
                  order={order}
                  selectItem={selectItem}
                  key={order.order_number}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(orderCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
    </>
  );
};

export default Order;

const OrderItem = (props) => {
  const {
    cart_id,
    order_number,
    order_status,
    customer_name,
    customer_postal_code,
    customer_address,
    customer_phone,
    customer_mail,
    route,
    order_at,
    total,
  } = props.order;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(order_number)}>
      <td>{cart_id}</td>
      <td>{order_number}</td>
      <td>{order_status}</td>
      <td>{route}</td>
      <td>{customer_name}</td>
      <td>{customer_postal_code}</td>
      <td>{customer_address}</td>
      <td>{customer_phone}</td>
      <td>{customer_mail}</td>
      <td>{dateToString(order_at, "yyyy/MM/dd HH:mm")}</td>
      <td>&yen;{total.toLocaleString()}</td>
    </tr>
  );
};
