// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

// components
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import AdminUserCreate from "./AdminUserCreate";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// action
import {
  getAdminUsers,
  adminUserSetFilter,
  adminUserClearStatus,
  adminUserChangePage,
} from "redux/actions/adminUser";
import { logout } from "redux/actions/auth";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const AdminUser = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.adminuser.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const adminUserList = useSelector(
    (state) => state.adminUser.adminUserList.list
  );
  const adminUserCount = useSelector(
    (state) => state.adminUser.adminUserList.count
  );
  const adminUserIsProcessing = useSelector(
    (state) => state.adminUser.adminUserIsProcessing
  );
  const adminUserFilter = useSelector(
    (state) => state.adminUser.adminUserFilter
  );
  const adminUserError = useSelector((state) => state.adminUser.adminUserError);
  const adminUserCurrentPage = useSelector(
    (state) => state.adminUser.adminUserCurrentPage
  );

  const [pageOffset, setPageOffset] = useState(
    (adminUserCurrentPage - 1) * pageLimit
  );
  const [isAdminUserCreate, setAdminUserCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(adminUserFilter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const FIELDS = {
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(adminUserChangePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(adminUserChangePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getAdminUsers(token, data));
    }

    const filterData = {
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(adminUserSetFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/adminuser/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  useEffect(() => {
    if (adminUserError) {
      switch (adminUserError.response.data.code) {
        case 1000:
          window.alert("再度ログインしてください");
          dispatch(logout(token));
          break;
        case 1003:
          window.alert("権限がありません");
          break;
        case 1004:
          window.alert("不正な管理ユーザIDです");
          break;
        case 1006:
          window.alert("不正な権限グループIDです");
          break;
        default:
          window.alert("予期せぬエラーが発生しました");
          break;
      }
      dispatch(adminUserClearStatus());
    }
  }, [adminUserError]);

  return (
    <>
      <div className="notice_list list">
        <div>
          <Link to="/adminuser/permissiongroup/">権限グループ一覧へ</Link>
        </div>
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={adminUserFilter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="created_at">作成日時</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={adminUserFilter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={adminUserFilter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={adminUserIsProcessing}
            />
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setAdminUserCreate(true)}
                disabled={adminUserIsProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="admin_user_id">ユーザID</th>
                <th className="name">名前</th>
                <th className="is_super">スーパーユーザー</th>
                <th className="permission_group">権限グループ</th>
                <th className="create">作成日時</th>
              </tr>
            </thead>
            <tbody>
              {adminUserList.map((adminUser) => (
                <AdminUserItem
                  adminUser={adminUser}
                  selectItem={selectItem}
                  key={adminUser.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(adminUserCount / pageLimit)}
            currentIndex={adminUserCurrentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      {adminUserIsProcessing && <Loader />}
      {isAdminUserCreate && (
        <Modal close={() => setAdminUserCreate(false)}>
          <AdminUserCreate close={() => setAdminUserCreate(false)} />
        </Modal>
      )}
    </>
  );
};

export default AdminUser;

const AdminUserItem = (props) => {
  const { id, name, is_super, permission_group, created_at } = props.adminUser;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="admin_user_id">
        <div className="inner">{id}</div>
      </td>
      <td className="name">
        <div className="inner">{name}</div>
      </td>
      <td className="is_super">
        <div className="inner">{is_super ? "○" : "-"}</div>
      </td>
      <td className="permission_group">
        <div className="inner">
          {permission_group ? permission_group.name : "なし"}
        </div>
      </td>
      <td className="create">{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
