import axios from "../network";
import * as types from "../types/ugc";

const getUgcListRequest = () => {
	return {
		type: types.GET_UGC_LIST_REQUEST,
	};
};

const getUgcListSuccess = (data) => {
	return {
		type: types.GET_UGC_LIST_SUCCESS,
		data,
	};
};

const getUgcListFailure = (error) => {
	return {
		type: types.GET_UGC_LIST_FAILURE,
		error,
	};
};

export const getUgcList = (token, data) => {
	return (dispatch) => {
		dispatch(getUgcListRequest());
		return axios
			.get("/admin/ugc/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getUgcListSuccess(res.data));
			})
			.catch((err) => dispatch(getUgcListFailure(err)));
	};
};

const getUgcInfoRequest = () => {
	return {
		type: types.GET_UGC_INFO_REQUEST,
	};
};

const getUgcInfoSuccess = (data) => {
	return {
		type: types.GET_UGC_INFO_SUCCESS,
		data,
	};
};

const getUgcInfoFailure = (error) => {
	return {
		type: types.GET_UGC_INFO_FAILURE,
		error,
	};
};

export const getUgcInfo = (token, id) => {
	return (dispatch) => {
		dispatch(getUgcInfoRequest());
		return axios
			.get("/admin/ugc/", {
				headers: { token: token },
				params: { ugc_id: id },
				data: {},
			})
			.then((res) => {
				return dispatch(getUgcInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getUgcInfoFailure(err)));
	};
};

const createUgcRequest = () => {
	return {
		type: types.CREATE_UGC_REQUEST,
	};
};

const createUgcSuccess = (data) => {
	return {
		type: types.CREATE_UGC_SUCCESS,
		data,
	};
};

const createUgcFailure = (error) => {
	return {
		type: types.CREATE_UGC_FAILURE,
		error,
	};
};

export const createUgc = (token, data) => {
	return (dispatch) => {
		dispatch(createUgcRequest());
		return axios
			.post("/admin/ugc/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createUgcSuccess(res.data));
			})
			.catch((err) => dispatch(createUgcFailure(err)));
	};
};

const updateUgcRequest = () => {
	return {
		type: types.UPDATE_UGC_REQUEST,
	};
};

const updateUgcSuccess = (data) => {
	return {
		type: types.UPDATE_UGC_SUCCESS,
		data,
	};
};

const updateUgcFailure = (error) => {
	return {
		type: types.UPDATE_UGC_FAILURE,
		error,
	};
};

export const updateUgc = (token, data) => {
	return (dispatch) => {
		dispatch(updateUgcRequest());
		return axios
			.put("/admin/ugc/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateUgcSuccess(res.data));
			})
			.catch((err) => dispatch(updateUgcFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_UGC_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_UGC_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_UGC_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_UGC_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_UGC_PAGE,
		page: page,
	};
};
