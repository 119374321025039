// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import {
  createAdminUser,
  adminUserClearStatus,
  getPermissionGroups,
} from "redux/actions/adminUser";

// utils
import { createPassword } from "utils/password";

// style
import "./style.scss";

const AdminUserCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const adminUserIsProcessing = useSelector(
    (state) => state.adminUser.adminUserIsProcessing
  );
  const adminUserIsCreateComplete = useSelector(
    (state) => state.adminUser.adminUserIsCreateComplete
  );
  const permissionGroupList = useSelector(
    (state) => state.adminUser.permissionGroupList.list
  );
  const dispatch = useDispatch();

  const FIELDS = {
    id: "id",
    name: "name",
    password: "password",
    permissionGroupId: "permission_group_id",
  };

  let elements = {};

  const getPassword = () => {
    elements[FIELDS.password].value = createPassword();
    handleChange();
  };

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every((field) => {
      if (field === FIELDS.permissionGroupId) {
        return true;
      }
      return elements[field].value;
    });

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      id: elements[FIELDS.id].value,
      name: elements[FIELDS.name].value,
      password: elements[FIELDS.password].value,
      permission_group_id: elements[FIELDS.permissionGroupId].value,
    };

    if (token) {
      dispatch(createAdminUser(token, data));
    }
  };

  useEffect(() => {
    if (adminUserIsCreateComplete) {
      window.alert("作成しました");
      dispatch(adminUserClearStatus());
      props.close();
    }
  }, [adminUserIsCreateComplete]);

  useEffect(() => {
    dispatch(getPermissionGroups(token));
  }, []);

  return (
    <div className="notice_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>管理ユーザID</th>
              <td>
                <input
                  className="design_input"
                  name={FIELDS.id}
                  type="text"
                  style={{ width: "250px" }}
                />
              </td>
            </tr>
            <tr>
              <th>名前</th>
              <td>
                <input
                  className="design_input"
                  name={FIELDS.name}
                  type="text"
                  style={{ width: "250px" }}
                />
              </td>
            </tr>
            <tr>
              <th>パスワード</th>
              <td>
                <input
                  className="design_input"
                  name={FIELDS.password}
                  type="text"
                  style={{ width: "250px" }}
                />
                <button
                  className="design_button"
                  type="button"
                  onClick={() => getPassword()}
                  style={{ marginLeft: "20px" }}
                >
                  パスワード生成
                </button>
              </td>
            </tr>
            <tr>
              <th>権限</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.permissionGroupId}
                  style={{ width: "250px" }}
                >
                  {permissionGroupList.map((permissionGroup, index) => (
                    <option value={permissionGroup.id} key={index}>
                      {permissionGroup.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || adminUserIsProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default AdminUserCreate;

const PermissionGroupItem = (props) => {
  const { permissionGroup, FIELDS } = props;

  return (
    <div>
      <input
        name={FIELDS.permissionGroupId}
        type="radio"
        value={permissionGroup.id}
      />
      {permissionGroup.name}
    </div>
  );
};
