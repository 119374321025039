import axios from "../network";
import * as types from "../types/offlineStoreOrder";

const getOfflineStoreOrdersRequest = () => {
  return {
    type: types.GET_OFFLINE_STORE_ORDERS_REQUEST,
  };
};

const getOfflineStoreOrdersSuccess = (data) => {
  return {
    type: types.GET_OFFLINE_STORE_ORDERS_SUCCESS,
    data,
  };
};

const getOfflineStoreOrdersFailure = (error) => {
  return {
    type: types.GET_OFFLINE_STORE_ORDERS_FAILURE,
    error,
  };
};

export const getOfflineStoreOrders = (token, data) => {
  return (dispatch) => {
    dispatch(getOfflineStoreOrdersRequest());
    return axios
      .get("/admin/offline-store/orders/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => {
        return dispatch(getOfflineStoreOrdersSuccess(res.data));
      })
      .catch((err) => dispatch(getOfflineStoreOrdersFailure(err)));
  };
};

const getOfflineStoreOrderInfoRequest = () => {
  return {
    type: types.GET_OFFLINE_STORE_ORDER_INFO_REQUEST,
  };
};

const getOfflineStoreOrderInfoSuccess = (data) => {
  return {
    type: types.GET_OFFLINE_STORE_ORDER_INFO_SUCCESS,
    data,
  };
};

const getOfflineStoreOrderInfoFailure = (error) => {
  return {
    type: types.GET_OFFLINE_STORE_ORDER_INFO_FAILURE,
    error,
  };
};

export const getOfflineStoreOrderInfo = (token, orderNumber) => {
  return (dispatch) => {
    dispatch(getOfflineStoreOrderInfoRequest());
    return axios
      .get(`/admin/offline-store/orders/${orderNumber}/`, {
        headers: { token: token },
        data: {},
      })
      .then((res) => {
        return dispatch(getOfflineStoreOrderInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getOfflineStoreOrderInfoFailure(err)));
  };
};

const changeOfflineStoreOrderItemStatusRequest = () => {
  return {
    type: types.CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_REQUEST,
  };
};

const changeOfflineStoreOrderItemStatusSuccess = (data) => {
  return {
    type: types.CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_SUCCESS,
    data,
  };
};

const changeOfflineStoreOrderItemStatusFailure = (error) => {
  return {
    type: types.CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_FAILURE,
    error,
  };
};

export const changeOfflineStoreOrderItemStatus = (token, itemId, status) => {
  return (dispatch) => {
    dispatch(changeOfflineStoreOrderItemStatusRequest());
    return axios
      .put(
        `/admin/offline-store/orders/items/${itemId}/status/`,
        { status: status },
        { headers: { token: token } }
      )
      .then((res) => {
        return dispatch(changeOfflineStoreOrderItemStatusSuccess(res.data));
      })
      .catch((err) => dispatch(changeOfflineStoreOrderItemStatusFailure(err)));
  };
};

export const clearStatus = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_ORDER_STATUS,
  };
};

export const setFilter = (data) => {
  return {
    type: types.SET_OFFLINE_STORE_ORDER_FILTER,
    data: data,
  };
};

export const clearFilter = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_ORDER_FILTER,
  };
};

export const clearError = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_ORDER_ERROR,
  };
};

export const changePage = (page) => {
  return {
    type: types.CHANGE_OFFLINE_STORE_ORDER_PAGE,
    page: page,
  };
};
