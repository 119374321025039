import * as types from "../types/coupon";

const couponInfoInitialState = {
	code: "",
	name: "",
	discount_rate: 0,
	status: "",
	expiration_date: "",
	start_data: "",
};

const couponAdaptInitialState = {
	coupon: "",
	status: "",
	target_cart_count: 0,
	done_cart_count: 0,
	expected_end_time: null,
	created_at: null,
}

const filterInitialState = {
	code: "",
	name: "",
	exclusionBenefitsCoupon: true,
	status: "all",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	info: couponInfoInitialState,
	couponAdapt: couponAdaptInitialState,
	couponList: {
		list: [],
		count: 0,
	},
	orderList: {
		list: [],
		total: 0,
	},
	isUpdateComplete: false,
	isCreateComplete: false,
	isAdaptComplete: false,
	currentPage: 1,
	isProcessing: false,
	filter: filterInitialState,
	error: null,
};

const order = (state = initialState, action) => {
	switch (action.type) {
		// クーポン一覧取得
		case types.GET_COUPONS_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_COUPONS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				couponList: {
					list: action.data.coupon_list,
					count: action.data.count,
				},
			};
		case types.GET_COUPONS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// クーポン情報取得
		case types.GET_COUPON_INFO_REQUEST:
			return {
				...state,
				isProcessing: true,
				info: couponInfoInitialState,
			};
		case types.GET_COUPON_INFO_SUCCESS:
			return { ...state, isProcessing: false, info: action.data.coupon };
		case types.GET_COUPON_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// クーポンが使用された注文一覧取得
		case types.GET_COUPON_ORDERS_REQUEST:
			return { ...state, isProcessing: true, error: null };
		case types.GET_COUPON_ORDERS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				orderList: {
					list: action.data.order_list,
					total: action.data.order_total,
				},
			};
		case types.GET_COUPON_ORDERS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// クーポン作成
		case types.CREATE_COUPON_REQUEST:
			return {
				...state,
				isProcessing: true,
				isCreateComplete: false,
				error: null,
			};
		case types.CREATE_COUPON_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_COUPON_FAILURE:
			return {
				...state,
				isProcessing: false,
				isCreateComplete: false,
				error: action.error,
			};

		// クーポン編集
		case types.UPDATE_COUPON_REQUEST:
			return {
				...state,
				isProcessing: true,
				isUpdateComplete: false,
				error: null,
			};
		case types.UPDATE_COUPON_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_COUPON_FAILURE:
			return {
				...state,
				isProcessing: false,
				isUpdateComplete: false,
				error: action.error,
			};

		// 買い物中カートにクーポン適用
		case types.ADAPT_COUPON_REQUEST:
			return {
				...state,
				isProcessing: true,
				isAdaptComplete: false,
				error: null,
			};
		case types.ADAPT_COUPON_SUCCESS:
			return { ...state, isProcessing: false, isAdaptComplete: true };
		case types.ADAPT_COUPON_FAILURE:
			return {
				...state,
				isProcessing: false,
				isAdaptComplete: false,
				error: action.error,
			};
		
		// クーポン一斉適用処理経過確認
		case types.GET_COUPON_ADAPT_REQUEST:
			return {
				...state,
				isProcessing: true,
				error: null,
			};
		case types.GET_COUPON_ADAPT_SUCCESS:
			return { ...state, isProcessing: false, couponAdapt: action.data};
		case types.GET_COUPON_ADAPT_FAILURE:
			return {
				...state,
				isProcessing: false,
				error: action.error,
			};

		case types.CLEAR_COUPON_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false, isAdaptComplete: false };
		case types.SET_COUPON_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_COUPON_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_COUPN_ERROR:
			return { ...state, error: null };
		case types.CHANGE_COUPON_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default order;
