// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// component
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import CouponCreate from "./CouponCreate";
import CouponAdaptProgress from "./CouponAdaptProgress";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";
import { convertToBoolean } from "utils/convertToBoolean";

// action
import {
  getCoupons,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/coupon";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const Coupon = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.coupon.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const couponList = useSelector((state) => state.coupon.couponList.list);
  const couponCount = useSelector((state) => state.coupon.couponList.count);
  const isProcessing = useSelector((state) => state.coupon.isProcessing);
  const filter = useSelector((state) => state.coupon.filter);
  const error = useSelector((state) => state.coupon.error);
  const currentPage = useSelector((state) => state.coupon.currentPage);

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isCouponCreate, setCouponCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);
  const [showCouponAdapt, setShowCouponAdapt] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const FIELDS = {
    code: "code",
    name: "name",
    status: "status",
    exclusionBenefitsCoupon: "exclusion_benefits_coupon",
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  const statusDictionary = {
    enabled: true,
    disabled: false,
    all: null,
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const search = (offset) => {
    const data = {
      coupon_code: elements[FIELDS.code].value,
      coupon_name: elements[FIELDS.name].value,
      exclusion_benefits_coupon: elements[FIELDS.exclusionBenefitsCoupon].value,
      status: statusDictionary[elements[FIELDS.status].value],
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getCoupons(token, data));
    }

    const filterData = {
      code: elements[FIELDS.code].value,
      name: elements[FIELDS.name].value,
      exclusionBenefitsCoupon: convertToBoolean(
        elements[FIELDS.exclusionBenefitsCoupon].value
      ),
      status: elements[FIELDS.status].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFilter(filterData));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const selectItem = (code) => {
    navigate("/coupon/" + code);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="coupon_list list">
        <button
          className="design_button"
          onClick={() => setShowCouponAdapt(true)}
        >
          クーポン一斉適用状況確認
        </button>
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>クーポンコード</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.code}
                    type="text"
                    defaultValue={filter.code}
                  />
                </td>
              </tr>
              <tr>
                <th>クーポン名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.name}
                    type="text"
                    defaultValue={filter.name}
                  />
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="enabled"
                      defaultChecked={filter.status === "enabled"}
                    />
                    使用可能
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="disabled"
                      defaultChecked={filter.status === "disabled"}
                    />
                    使用不可
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="all"
                      defaultChecked={filter.status === "all"}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>購入者特典</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.exclusionBenefitsCoupon}
                      type="radio"
                      value={false}
                      defaultChecked={!filter.exclusionBenefitsCoupon}
                    />
                    表示
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.exclusionBenefitsCoupon}
                      type="radio"
                      value={true}
                      defaultChecked={filter.exclusionBenefitsCoupon}
                    />
                    非表示
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                    defaultValue={filter.sortBy}
                  >
                    <option value="code">商品コード</option>
                    <option value="name">商品名</option>
                    <option value="created_at">作成日時</option>
                    <option value="discount_rate">割引率</option>
                    <option value="start_date">使用開始日時</option>
                    <option value="expiration_date">使用期限</option>
                    <option value="">なし</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setCouponCreate(true)}
                disabled={isProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="code">クーポンコード</th>
                <th className="name">クーポン名</th>
                <th className="discount">割引率</th>
                <th className="status">ステータス</th>
                <th className="start">使用開始日時</th>
                <th className="expiration">使用期限</th>
                <th className="create">作成日時</th>
              </tr>
            </thead>
            <tbody>
              {couponList.map((coupon) => (
                <CouponItem
                  coupon={coupon}
                  selectItem={selectItem}
                  key={coupon.code}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(couponCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isCouponCreate && (
        <Modal close={() => setCouponCreate(false)}>
          <CouponCreate close={() => setCouponCreate(false)} />
        </Modal>
      )}
      {showCouponAdapt && (
        <Modal close={() => setShowCouponAdapt(false)}>
          <CouponAdaptProgress />
        </Modal>
      )}
    </>
  );
};

export default Coupon;

const CouponItem = (props) => {
  const {
    code,
    name,
    discount_rate,
    status,
    expiration_date,
    start_date,
    created_at,
  } = props.coupon;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(code)}>
      <td className="code">{code}</td>
      <td className="name">{name}</td>
      <td className="discount">{discount_rate}%OFF</td>
      <td className="status">{status ? "使用可能" : "使用不可"}</td>
      <td className="start">
        {start_date && dateToString(start_date, "yyyy/MM/dd HH:mm")}
      </td>
      <td className="expiration">
        {expiration_date && dateToString(expiration_date, "yyyy/MM/dd HH:mm")}
      </td>
      <td className="create">{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
