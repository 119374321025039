import * as types from "../types/adminUser";

const permissionGroupInfoInitialState = {
  id: "",
  name: "",
  created_at: "",
  permission_list: [],
};

const adminUserInfoInitialState = {
  id: "",
  name: "",
  is_super: false,
  permission_group: permissionGroupInfoInitialState,
  created_at: "",
};

const filterInitialState = {
  name: "",
  sortBy: "created_at",
  sortType: "desc",
};

const initialState = {
  adminUserInfo: adminUserInfoInitialState,
  adminUserList: {
    list: [],
    count: 0,
  },
  adminUserIsProcessing: false,
  adminUserFilter: filterInitialState,
  adminUserIsUpdateComplete: false,
  adminUserIsCreateComplete: false,
  adminUserIsDeleteComplete: false,
  adminUserIsLockoutCancelComplete: false,
  adminUserCurrentPage: 1,
  adminUserError: null,
  permissionGroupInfo: permissionGroupInfoInitialState,
  permissionGroupList: {
    list: [],
    count: 0,
  },
  permissionGroupIsProcessing: false,
  permissionGroupFilter: filterInitialState,
  permissionGroupIsUpdateComplete: false,
  permissionGroupIsCreateComplete: false,
  permissionGroupIsDeleteComplete: false,
  permissionGroupCurrentPage: 1,
  permissionGroupError: null,
};

const adminUser = (state = initialState, action) => {
  switch (action.type) {
    // 管理ユーザ一覧取得
    case types.GET_ADMIN_USERS_REQUEST:
      return { ...state, adminUserIsProcessing: true };
    case types.GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserList: {
          list: action.data.admin_user_list,
          count: action.data.count,
        },
      };
    case types.GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserError: action.error,
      };

    // 管理ユーザ情報取得
    case types.GET_ADMIN_USER_INFO_REQUEST:
      return { ...state, adminUserIsProcessing: true };
    case types.GET_ADMIN_USER_INFO_SUCCESS:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserInfo: action.data.admin_user,
      };
    case types.GET_ADMIN_USER_INFO_FAILURE:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserError: action.error,
      };

    // 管理ユーザ作成
    case types.CREATE_ADMIN_USER_REQUEST:
      return { ...state, adminUserIsProcessing: true };
    case types.CREATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserIsCreateComplete: true,
      };
    case types.CREATE_ADMIN_USER_FAILURE:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserError: action.error,
      };

    // 管理ユーザ編集
    case types.UPDATE_ADMIN_USER_REQUEST:
      return { ...state, adminUserIsProcessing: true };
    case types.UPDATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserIsUpdateComplete: true,
      };
    case types.UPDATE_ADMIN_USER_FAILURE:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserError: action.error,
      };

    // 管理ユーザー削除
    case types.DELETE_ADMIN_USER_REQUEST:
      return { ...state, adminUserIsProcessing: true };
    case types.DELETE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserIsDeleteComplete: true,
      };
    case types.DELETE_ADMIN_USER_FAILURE:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserError: action.error,
      };

    // ロックアウト解除
    case types.LOCKOUT_CANCEL_REQUEST:
      return { ...state, adminUserIsProcessing: true };
    case types.LOCKOUT_CANCEL_SUCCESS:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserIsLockoutCancelComplete: true,
      };
    case types.LOCKOUT_CANCEL_FAILURE:
      return {
        ...state,
        adminUserIsProcessing: false,
        adminUserError: action.error,
      };

    // 管理ユーザ用
    case types.CLEAR_ADMIN_USER_STATUS:
      return {
        ...state,
        adminUserIsUpdateComplete: false,
        adminUserIsCreateComplete: false,
        adminUserIsDeleteComplete: false,
        adminUserIsLockoutCancelComplete: false,
      };
    case types.SET_ADMIN_USER_FILTER:
      return { ...state, adminUserFilter: action.data };
    case types.CLEAR_ADMIN_USER_FILTER:
      return { ...state, adminUserFilter: filterInitialState };
    case types.CLEAR_ADMIN_USER_ERROR:
      return { ...state, adminUserError: null };
    case types.CHANGE_ADMIN_USER_PAGE:
      return { ...state, adminUserCurrentPage: action.page };

    // 権限グループ一覧取得
    case types.GET_PERMISSION_GROUPS_REQUEST:
      return { ...state, permissionGroupIsProcessing: true };
    case types.GET_PERMISSION_GROUPS_SUCCESS:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupList: {
          list: action.data.permission_group_list,
          count: action.data.count,
        },
      };
    case types.GET_PERMISSION_GROUPS_FAILURE:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupError: action.error,
      };

    // 権限グループ情報取得
    case types.GET_PERMISSION_GROUP_INFO_REQUEST:
      return { ...state, permissionGroupIsProcessing: true };
    case types.GET_PERMISSION_GROUP_INFO_SUCCESS:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupInfo: action.data.permission_group,
      };
    case types.GET_PERMISSION_GROUP_INFO_FAILURE:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupError: action.error,
      };

    // 権限グループ作成
    case types.CREATE_PERMISSION_GROUP_REQUEST:
      return { ...state, permissionGroupIsProcessing: true };
    case types.CREATE_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupIsCreateComplete: true,
      };
    case types.CREATE_PERMISSION_GROUP_FAILURE:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupError: action.error,
      };

    // 権限グループ編集
    case types.UPDATE_PERMISSION_GROUP_REQUEST:
      return { ...state, permissionGroupIsProcessing: true };
    case types.UPDATE_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupIsUpdateComplete: true,
      };
    case types.UPDATE_PERMISSION_GROUP_FAILURE:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupError: action.error,
      };

    // 権限グループ削除
    case types.DELETE_PERMISSION_GROUP_REQUEST:
      return { ...state, permissionGroupIsProcessing: true };
    case types.DELETE_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupIsDeleteComplete: true,
      };
    case types.DELETE_PERMISSION_GROUP_FAILURE:
      return {
        ...state,
        permissionGroupIsProcessing: false,
        permissionGroupError: action.error,
      };

    // 権限グループ用
    case types.CLEAR_PERMISSION_GROUP_STATUS:
      return {
        ...state,
        permissionGroupIsUpdateComplete: false,
        permissionGroupIsCreateComplete: false,
        permissionGroupIsDeleteComplete: false,
      };
    case types.SET_PERMISSION_GROUP_FILTER:
      return { ...state, permissionGroupFilter: action.data };
    case types.CLEAR_PERMISSION_GROUP_FILTER:
      return { ...state, permissionGroupFilter: filterInitialState };
    case types.CLEAR_PERMISSION_GROUP_ERROR:
      return { ...state, permissionGroupError: null };
    case types.CHANGE_PERMISSION_GROUP_PAGE:
      return { ...state, permissionGroupCurrentPage: action.page };

    default:
      return state;
  }
};

export default adminUser;
