// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateFaqProduct, clearStatus } from "redux/actions/faq";

// style
import "./style.scss";

const ProductEdit = (props) => {
  // params
  const { id, manage_id, name, is_open, priority } = props.product;

  const FIELDS = {
    manageID: "manage_id",
    name: "name",
    isOpen: "is_open",
    priority: "priority",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.faq.isProcessing);
  const isUpdateComplete = useSelector((state) => state.faq.isUpdateComplete);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeFaq();
    }
  };

  const changeFaq = () => {
    const data = {
      manage_id: elements[FIELDS.manageID].value,
      faq_product_id: id,
      name: elements[FIELDS.name].value,
      is_open: elements[FIELDS.isOpen].value,
      priority: elements[FIELDS.priority].value,
    };

    if (token) {
      dispatch(updateFaqProduct(token, data));
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="faq_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="faq_item">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.manageID}
                  defaultValue={manage_id}
                />
              </td>
            </tr>
            <tr>
              <th>商品グループ名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.isOpen}
                  defaultValue={is_open}
                >
                  <option value={true}>表示</option>
                  <option value={false}>非表示</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>表示優先度</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.priority}
                  defaultValue={priority}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
