// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { createFaq, clearStatus, getFaqCategories } from "redux/actions/faq";

// style
import "./style.scss";

const FaqCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [inputAnswer, setInputAnswer] = useState("");

  const token = useSelector((state) => state.auth.token);
  const categoryList = useSelector((state) => state.faq.categoryList.list);
  const productList = useSelector((state) => state.faq.productList.list);
  const isProcessing = useSelector((state) => state.faq.isProcessing);
  const isCreateComplete = useSelector((state) => state.faq.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    manageID: "manage_id",
    question: "question",
    answer: "answer",
    categoryID: "category_id",
    productID: "faq_product_id",
    isOpen: "is_open",
    priority: "priority",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      manage_id: elements[FIELDS.manageID].value,
      question: elements[FIELDS.question].value,
      answer: elements[FIELDS.answer].value,
      category_id: elements[FIELDS.categoryID].value,
      is_open: elements[FIELDS.isOpen].value,
      priority: elements[FIELDS.priority].value,
    };

    if (token) {
      dispatch(createFaq(token, data));
    }
  };

  const fetchCategories = () => {
    const data = {
      faq_product_id: elements[FIELDS.productID].value,
      offset: 0,
      limit: 100,
    };
    dispatch(getFaqCategories(token, data));
    elements[FIELDS.categoryID].value = "";
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="faq_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.manageID}
                />
              </td>
            </tr>
            <tr>
              <th>質問</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.question}
                />
              </td>
            </tr>
            <tr>
              <th>回答</th>
              <td>
                <div className="faq_answer_container">
                  <textarea
                    className="design_input"
                    name={FIELDS.answer}
                    onChange={(e) => setInputAnswer(e.target.value)}
                  />
                  <div
                    className="faq_detail_anwer_preview"
                    dangerouslySetInnerHTML={{ __html: inputAnswer }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>商品グループ</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.productID}
                  onChange={fetchCategories}
                  defaultValue={""}
                >
                  {productList.map((product) => (
                    <option value={product.id} key={product.id}>
                      {product.name}
                    </option>
                  ))}
                  <option value={""}>-</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>カテゴリ</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.categoryID}
                  defaultValue={""}
                >
                  {categoryList.map((category) => (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  ))}
                  <option value={""}>-</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                表示優先度
                <span
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "400",
                    marginLeft: "10px",
                  }}
                >
                  (0~1000)
                </span>
              </th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  style={{ width: "200px" }}
                  name={FIELDS.priority}
                />
                <span
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "400",
                    marginLeft: "10px",
                  }}
                >
                  ※数値が高いものから表示されます
                </span>
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select className="design_select" name={FIELDS.isOpen}>
                  <option value={true}>表示</option>
                  <option value={false}>非表示</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default FaqCreate;
