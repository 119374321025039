// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import NoticeEdit from "./NoticeEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import { getNoticeInfo, clearError } from "redux/actions/notice";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const NoticeDetail = () => {
  const { noticeId } = useParams();
  const applicationId = applicationList.notice.id;

  const [isNoticeEdit, setNoticeEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const notice = useSelector((state) => state.notice.info);
  const error = useSelector((state) => state.notice.error);
  const isProcessing = useSelector((state) => state.notice.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchNotice = () => {
    dispatch(getNoticeInfo(token, noticeId));
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <>
      <div className="notice_detail detail">
        <div className="list_back_container">
          <Link to="/notice">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>お知らせ内容</th>
              <td>{notice.text}</td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(notice.created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>{notice.is_open ? "公開" : "非公開"}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <button
              className="design_button"
              onClick={() => setNoticeEdit(true)}
              disabled={isProcessing}
            >
              編集
            </button>
          )}
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isNoticeEdit && (
        <Modal close={() => setNoticeEdit(false)}>
          <NoticeEdit
            notice={notice}
            close={() => {
              setNoticeEdit(false);
              fetchNotice();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default NoticeDetail;
