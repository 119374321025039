// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { logout } from "redux/actions/auth";

// constants
import { getErrorMessageByCode } from "constants/errorMessage";

const ErrorMessage = ({ error, clear }) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      let message;

      if (error.response.data.code) {
        message = getErrorMessageByCode(error.response.data.code).context;
      } else {
        message = "予期せぬエラー\n" + error.response.data.priority[0];
      }

      // 返金失敗時のエラー（ code: 6000 ）では返金失敗額を表示
      if (error.response.data.code === 6000) {
        message += "\n返金失敗額: " + error.response.data.refund_amount + "円";
      }

      window.alert(message);

      if (error.response.data.code === 1000) {
        dispatch(logout(token));
      }

      clear();
    }
  }, [error]);
  return <></>;
};

export default ErrorMessage;
