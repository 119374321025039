export const GET_NOTICES_REQUEST = "GET_NOTICES_REQUEST";
export const GET_NOTICES_SUCCESS = "GET_NOTICES_SUCCESS";
export const GET_NOTICES_FAILURE = "GET_NOTICES_FAILURE";

export const GET_NOTICE_INFO_REQUEST = "GET_NOTICE_INFO_REQUEST";
export const GET_NOTICE_INFO_SUCCESS = "GET_NOTICE_INFO_SUCCESS";
export const GET_NOTICE_INFO_FAILURE = "GET_NOTICE_INFO_FAILURE";

export const CREATE_NOTICE_REQUEST = "CREATE_NOTICE_REQUEST";
export const CREATE_NOTICE_SUCCESS = "CREATE_NOTICE_SUCCESS";
export const CREATE_NOTICE_FAILURE = "CREATE_NOTICE_FAILURE";

export const UPDATE_NOTICE_REQUEST = "UPDATE_NOTICE_REQUEST";
export const UPDATE_NOTICE_SUCCESS = "UPDATE_NOTICE_SUCCESS";
export const UPDATE_NOTICE_FAILURE = "UPDATE_NOTICE_FAILURE";

export const CLEAR_NOTICE_STATUS = "CLEAR_NOTICE_STATUS";

export const SET_NOTICE_FILTER = "SET_NOTICE_FILTER";
export const CLEAR_NOTICE_FILTER = "CLEAR_NOTICE_FILTER";

export const CLEAR_NOTICE_ERROR = "CLEAR_NOTICE_ERROR";
export const CHANGE_NOTICE_PAGE = "CHANGE_NOTICE_PAGE";
