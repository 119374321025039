// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import AdminUserEdit from "./AdminUserEdit";

// actions
import {
  getAdminUserInfo,
  deleteAdminUser,
  lockoutCancel,
  adminUserClearStatus,
  adminUserClearError,
} from "redux/actions/adminUser";
import { logout, clearToken } from "redux/actions/auth";
import { clearInfo } from "redux/actions/myAccount";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const AdminUserDetail = () => {
  const { adminUserId } = useParams();
  const applicationId = applicationList.adminuser.id;

  const [isAdminUserEdit, setAdminUserEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const adminUser = useSelector((state) => state.adminUser.adminUserInfo);
  const adminUserError = useSelector((state) => state.adminUser.adminUserError);
  const adminUserIsProcessing = useSelector(
    (state) => state.adminUser.adminUserIsProcessing
  );
  const adminUserIsDeleteComplete = useSelector(
    (state) => state.adminUser.adminUserIsDeleteComplete
  );
  const adminUserIsLockoutCancelComplete = useSelector(
    (state) => state.adminUser.adminUserIsLockoutCancelComplete
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const getPermissionType = (applicationId) => {
    if (adminUser.permission_group) {
      const targetPermission = adminUser.permission_group.permission_list.find(
        (permission) => permission.application_id === applicationId
      );
      const permissionType = targetPermission ? targetPermission.type : "";
      return permissionType;
    } else {
      return "";
    }
  };

  const deleteUser = () => {
    const data = {
      admin_user_id: adminUserId,
    };

    let confirmMessage = "管理ユーザを削除しようとしています。よろしいですか？";
    if (myAccount.id === adminUserId) {
      confirmMessage =
        "ご利用中のアカウントを削除しようとしています。よろしいですか？";
    }

    if (window.confirm(confirmMessage)) {
      dispatch(deleteAdminUser(token, data));
    }
  };

  const adminLockoutCancel = () => {
    dispatch(lockoutCancel(token, adminUserId));
  };

  const fetchAdminUser = () => {
    dispatch(getAdminUserInfo(token, adminUserId));
  };

  useEffect(() => {
    if (adminUserIsDeleteComplete) {
      window.alert("削除しました");
      dispatch(adminUserClearStatus());
      if (myAccount.id === adminUserId) {
        dispatch(clearInfo());
        dispatch(clearToken());
      } else {
        navigate("/adminuser/");
      }
    }
  }, [adminUserIsDeleteComplete]);

  useEffect(() => {
    if (adminUserIsLockoutCancelComplete) {
      window.alert("ロックアウトを解除しました");
      dispatch(adminUserClearStatus());
    }
  }, [adminUserIsLockoutCancelComplete]);

  useEffect(() => {
    fetchAdminUser();
  }, []);

  useEffect(() => {
    if (adminUserError) {
      switch (adminUserError.response.data.code) {
        case 1000:
          window.alert("再度ログインしてください");
          dispatch(logout(token));
          break;
        case 1003:
          window.alert("権限がありません");
          break;
        case 1004:
          window.alert("管理ユーザーIDが不正です");
          break;
        case 1006:
          window.alert("権限グループIDが不正です");
          break;
        default:
          window.alert("予期せぬエラーが発生しました");
          break;
      }
      dispatch(adminUserClearError());
    }
  }, [adminUserError]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  return (
    <>
      <div className="admin_user_detail detail">
        <div className="list_back_container">
          <Link to="/adminuser">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>{adminUser.id}</td>
            </tr>
            <tr>
              <th>名前</th>
              <td>{adminUser.name}</td>
            </tr>
            <tr>
              <th>スーパーユーザー</th>
              <td>{adminUser.is_super ? "○" : "-"}</td>
            </tr>
            <tr>
              <th>権限グループ</th>
              <td>
                {adminUser.permission_group
                  ? adminUser.permission_group.name
                  : "なし"}
              </td>
            </tr>
            <tr>
              <th>権限</th>
              <td className="permission_list_container">
                <table>
                  <tbody>
                    {Object.keys(applicationList).map(
                      (applicationId, index) =>
                        applicationList[applicationId].id && (
                          <tr key={index} className="permission_container">
                            <th>
                              {applicationList[applicationId].applicationName}
                            </th>
                            {getPermissionType(applicationId) === "" && (
                              <td className="no_permission permission_type">
                                なし
                              </td>
                            )}
                            {getPermissionType(applicationId) === "read" && (
                              <td className="read_permission permission_type">
                                読み込み
                              </td>
                            )}
                            {getPermissionType(applicationId) === "write" && (
                              <td className="write_permission permission_type">
                                読み込み・書き込み
                              </td>
                            )}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(adminUser.created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <>
              <button
                className="design_button"
                onClick={() => setAdminUserEdit(true)}
                disabled={adminUserIsProcessing}
              >
                編集
              </button>
              <button
                className="design_button delete_button"
                onClick={() => deleteUser()}
                disabled={adminUserIsProcessing || adminUser.is_super}
              >
                削除
              </button>
              <button
                className="design_button"
                onClick={() => adminLockoutCancel()}
                disabled={adminUserIsProcessing || adminUser.is_super}
              >
                ロックアウト解除
              </button>
            </>
          )}
        </div>
      </div>
      {adminUserIsProcessing && <Loader />}
      {isAdminUserEdit && (
        <Modal close={() => setAdminUserEdit(false)}>
          <AdminUserEdit
            adminUser={adminUser}
            close={() => {
              setAdminUserEdit(false);
              fetchAdminUser();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default AdminUserDetail;
