import * as types from "../types/user";

const userInfoInitialState = {
	id: "",
	mail: "",
	created_at: "",
	order_info_list: [],
};

const filterInitialState = {
	userID: "",
	email: "",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	filter: filterInitialState,
	userInfo: userInfoInitialState,
	userList: {
		list: [],
		count: 0,
	},
	isProcessing: false,
	isUpdateComplete: false,
	currentPage: 1,
	error: null,
};

const user = (state = initialState, action) => {
	switch (action.type) {
		// ユーザ一覧取得
		case types.GET_USERS_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_USERS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				userList: {
					list: action.data.user_list,
					count: action.data.count,
				},
			};
		case types.GET_USERS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// ユーザ情報取得
		case types.GET_USER_INFO_REQUEST:
			return {
				...state,
				isProcessing: true,
			};
		case types.GET_USER_INFO_SUCCESS:
			return {
				...state,
				isProcessing: false,
				userInfo: action.data.user,
			};
		case types.GET_USER_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// ユーザー情報更新
		case types.UPDATE_USER_REQUEST:
			return {
				...state,
				isProcessing: true,
			};
		case types.UPDATE_USER_SUCCESS:
			return { ...state, isUpdateComplete: true, isProcessing: false };
		case types.UPDATE_USER_FAILURE:
			return {
				...state,
				isProcessing: false,
				error: action.error,
			};

		case types.CLEAR_USER_STATUS:
			return { ...state, isUpdateComplete: false };
		case types.SET_USER_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_USER_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_USER_ERROR:
			return { ...state, error: null };
		case types.CHANGE_USER_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default user;
