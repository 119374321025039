// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import StockEdit from "./StockEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import {
  getStockInfo,
  clearError,
  deleteStock,
  clearStatus,
} from "redux/actions/stock";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";
import { convertStatus } from "utils/stock";

// constants
import { applicationList } from "constants/application";
import { getProductByID } from "constants/products";
import { getWarehouseByID } from "constants/warehouse";

// style
import "./style.scss";

const StockDetail = () => {
  const { stockId } = useParams();
  const applicationId = applicationList.stock.id;

  const [isStockEdit, setStockEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const stock = useSelector((state) => state.stock.info);
  const error = useSelector((state) => state.stock.error);
  const isProcessing = useSelector((state) => state.stock.isProcessing);
  const isDeleteComplete = useSelector((state) => state.stock.isDeleteComplete);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const handleDelete = () => {
    if (
      window.confirm(
        "本当に 「出庫ID: " +
          stock.warehousing_id +
          " ,商品名: " +
          getProductByID(stock.product_id).name +
          "」の入庫予定を削除しますか？"
      )
    ) {
      const data = {
        stock_id: stock.id,
      };
      dispatch(deleteStock(token, data));
    }
  };

  const fetchStock = () => {
    if (token) {
      dispatch(getStockInfo(token, stockId));
    }
  };

  useEffect(() => {
    if (isDeleteComplete) {
      dispatch(clearStatus());
      navigate("/stock");
    }
  }, [isDeleteComplete]);

  useEffect(() => {
    fetchStock();
  }, []);

  return (
    <>
      <div className="stock_detail detail">
        <div className="list_back_container">
          <Link to="/stock">一覧に戻る</Link>
        </div>
        {stock.product_id ? (
          <>
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>商品名</th>
                  <td>{getProductByID(stock.product_id).name}</td>
                </tr>
                <tr>
                  <th>商品ID</th>
                  <td>{stock.product_id}</td>
                </tr>
                <tr>
                  <th>入庫ID</th>
                  <td>
                    {stock.warehousing_id ? stock.warehousing_id : "未設定"}
                  </td>
                </tr>
                <tr>
                  <th>入庫日</th>
                  <td>
                    {stock.warehousing_date &&
                      dateToString(stock.warehousing_date, "yyyy/MM/dd")}
                  </td>
                </tr>
                <tr>
                  <th>初期入庫数</th>
                  <td>
                    {stock.initial_quantity ? stock.initial_quantity : "未設定"}
                  </td>
                </tr>
                <tr>
                  <th>在庫数</th>
                  <td>{stock.quantity}</td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{convertStatus(stock.arrival_status)}</td>
                </tr>
                <tr>
                  <th>作成日</th>
                  <td>{dateToString(stock.created_at, "yyyy/MM/dd HH:mm")}</td>
                </tr>
                <tr>
                  <th>倉庫</th>
                  <td>
                    {stock.warehouse}({getWarehouseByID(stock.warehouse).place})
                  </td>
                </tr>
                <tr>
                  <th>有効・無効</th>
                  <td>{stock.is_active ? "有効" : "無効"}</td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <>
                  <button
                    className="design_button"
                    onClick={() => setStockEdit(true)}
                    disabled={isProcessing}
                  >
                    編集
                  </button>
                  <button
                    className="design_button delete_button"
                    onClick={() => handleDelete()}
                    disabled={isProcessing}
                  >
                    削除
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isStockEdit && (
        <Modal close={() => setStockEdit(false)}>
          <StockEdit
            stock={stock}
            close={() => {
              setStockEdit(false);
              fetchStock();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default StockDetail;
