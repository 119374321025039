export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILURE = "GET_REVIEWS_FAILURE";

export const GET_REVIEW_INFO_REQUEST = "GET_REVIEW_INFO_REQUEST";
export const GET_REVIEW_INFO_SUCCESS = "GET_REVIEW_INFO_SUCCESS";
export const GET_REVIEW_INFO_FAILURE = "GET_REVIEW_INFO_FAILURE";

export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST";
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const CREATE_REVIEW_FAILURE = "CREATE_REVIEW_FAILURE";

export const UPDATE_REVIEW_REQUEST = "UPDATE_REVIEW_REQUEST";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";
export const UPDATE_REVIEW_FAILURE = "UPDATE_REVIEW_FAILURE";

export const CLEAR_REVIEW_STATUS = "CLEAR_REVIEW_STATUS";

export const SET_REVIEW_FILTER = "SET_REVIEW_FILTER";
export const CLEAR_REVIEW_FILTER = "CLEAR_REVIEW_FILTER";

export const CLEAR_REVIEW_ERROR = "CLEAR_REVIEW_ERROR";
export const CHANGE_REVIEW_PAGE = "CHANGE_REVIEW_PAGE";
