// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createStock, clearStatus } from "redux/actions/stock";

// style
import "./style.scss";

const CouponCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.stock.isProcessing);
  const isCreateComplete = useSelector((state) => state.stock.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    warehousingId: "warehousing_id",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      warehousing_id: elements[FIELDS.warehousingId].value,
    };

    if (token) {
      dispatch(createStock(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="stock_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>入庫ID</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.warehousingId}
                  style={{ width: "250px" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default CouponCreate;
