// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import FaqEdit from "./FaqEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import { getFaq, getFaqCategories, clearError } from "redux/actions/faq";

// utils
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const FaqDetail = () => {
  const { faqId } = useParams();
  const applicationId = applicationList.faq.id;

  const [isFaqEdit, setFaqEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const faq = useSelector((state) => state.faq.faqInfo);
  const error = useSelector((state) => state.faq.error);
  const isProcessing = useSelector((state) => state.faq.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchFaq = () => {
    dispatch(getFaq(token, faqId));
  };

  useEffect(() => {
    fetchFaq();
    dispatch(getFaqCategories(token, { offset: 0, limit: 100 }));
  }, []);

  return (
    <>
      <div className="faq_detail detail">
        <div className="list_back_container">
          <Link to="/faq">一覧に戻る</Link>
        </div>
        <div className="faq_flex">
          <div className="faq_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{faq.manage_id ? faq.manage_id : "未設定"}</td>
                </tr>
                <tr>
                  <th>質問</th>
                  <td>{faq.question}</td>
                </tr>
                <tr>
                  <th>回答</th>
                  <td>
                    <div
                      className="faq_detail_anwer"
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>商品グループ</th>
                  <td>{faq.category.faq_product.name}</td>
                </tr>
                <tr>
                  <th>カテゴリ</th>
                  <td>{faq.category.name}</td>
                </tr>
                <tr>
                  <th>表示優先度</th>
                  <td>{faq.priority ? faq.priority : "未設定"}</td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{faq.is_open ? "表示" : "非表示"}</td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <button
                  className="design_button"
                  onClick={() => setFaqEdit(true)}
                  disabled={isProcessing}
                >
                  編集
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isFaqEdit && (
        <Modal close={() => setFaqEdit(false)}>
          <FaqEdit
            faq={faq}
            close={() => {
              setFaqEdit(false);
              fetchFaq();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default FaqDetail;
