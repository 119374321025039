// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  createOfflineStoreSale,
  clearStatus,
} from "redux/actions/offlineStoreSale";
import { getOfflineStores } from "redux/actions/offlineStore";

// style
import "./style.scss";

const OfflineStoreSaleCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector(
    (state) => state.offlineStoreSale.isProcessing
  );
  const isCreateComplete = useSelector(
    (state) => state.offlineStoreSale.isCreateComplete
  );

  const dispatch = useDispatch();

  const FIELDS = {
    saleName: "sale_name",
    discountRate: "discount_rate",
    startAt: "start_at",
    endAt: "end_at",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const addStore = (store) => {
    setSelectedStores((prev) => {
      // 元のselectedStores配列（prev）に同じオブジェクトが含まれているかチェック
      const isAlreadySelected = prev.some(
        (selectedStore) => selectedStore.store_id === store.store_id
      );

      // 含まれていなければ追加、含まれていればそのまま
      return isAlreadySelected ? prev : [...prev, store];
    });
  };

  const deleteStore = (store) => {
    setSelectedStores((prev) => prev.filter((s) => s.id !== store.id));
  };

  const create = () => {
    const data = {
      name: elements[FIELDS.saleName].value,
      discount_rate: elements[FIELDS.discountRate].value,
      start_at: elements[FIELDS.startAt].value,
      end_at: elements[FIELDS.endAt].value,
      store_id_list: selectedStores.map((store) => store.store_id),
    };

    if (token) {
      dispatch(createOfflineStoreSale(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      props.close();
      dispatch(clearStatus());
    }
  }, [isCreateComplete]);

  useEffect(() => {
    dispatch(getOfflineStores(token));
  }, []);

  return (
    <div className="offline_store_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>セール名</th>
              <td>
                <input
                  type="text"
                  id={FIELDS.saleName}
                  className="design_input"
                />
              </td>
            </tr>
            <tr>
              <th>対象店舗</th>
              <td>
                <div>
                  {selectedStores.map((store) => (
                    <div className="selected_items" key={store.store_id}>
                      <span className="item_name">{store.store_name}</span>
                      <span
                        className="delete_button"
                        onClick={() => deleteStore(store)}
                      ></span>
                    </div>
                  ))}
                </div>
                <AddSelectForm
                  selectedStores={selectedStores}
                  addStore={(store) => addStore(store)}
                />
              </td>
            </tr>
            <tr>
              <th>割引率（％）</th>
              <td>
                <input
                  type="number"
                  id={FIELDS.discountRate}
                  className="design_input"
                />
              </td>
            </tr>
            <tr>
              <th>セール開始日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.startAt}
                />
              </td>
            </tr>
            <tr>
              <th>セール終了日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.endAt}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default OfflineStoreSaleCreate;

const AddSelectForm = ({ selectedStores, addStore }) => {
  const offlineStores = useSelector(
    (state) => state.offlineStore.offlineStoreList.list
  );
  const [selectedStoreId, setSelectedStoreId] = useState("");

  // 選択された店舗の ID を管理
  const handleChange = (e) => {
    setSelectedStoreId(e.target.value);
  };

  // 追加ボタンが押されたときの処理
  const handleAdd = (e) => {
    const selectedStore = offlineStores.find(
      (store) => store.store_id === selectedStoreId
    );
    if (selectedStore) {
      addStore(selectedStore);
      setSelectedStoreId(""); // 選択リセット
    }
  };

  const availableStores = useMemo(() => {
    return offlineStores.filter(
      (store) =>
        !selectedStores.some((selected) => selected.store_id === store.store_id)
    );
  }, [offlineStores, selectedStores]);

  return (
    <>
      <select
        className="design_select"
        name="offline_stores"
        value={selectedStoreId}
        onChange={handleChange}
      >
        <option value="" disabled>
          店舗を選択してください
        </option>
        {availableStores.map((store) => (
          <option key={store.store_id} value={store.store_id}>
            {store.store_name}
          </option>
        ))}
      </select>
      <button type="button" className="design_button" onClick={handleAdd}>
        追加
      </button>
    </>
  );
};
