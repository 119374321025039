// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import Edit from "./Edit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import { clearError, getFaqCategory } from "redux/actions/faq";

// utils
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const FaqCategoryDetail = () => {
  const { faqCategoryId } = useParams();
  const applicationId = applicationList.faq.id;

  const [isCategoryEdit, setCategoryEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const category = useSelector((state) => state.faq.categoryInfo);
  const error = useSelector((state) => state.faq.error);
  const isProcessing = useSelector((state) => state.faq.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchFaqCategory = () => {
    dispatch(getFaqCategory(token, faqCategoryId));
  };

  useEffect(() => {
    fetchFaqCategory();
  }, []);

  return (
    <>
      <div className="faq_detail detail">
        <div className="list_back_container">
          <Link to="/faq/category">一覧に戻る</Link>
        </div>
        <div className="faq_flex">
          <div className="faq_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{category.manage_id ? category.manage_id : "未設定"}</td>
                </tr>
                <tr>
                  <th>カテゴリ名</th>
                  <td>{category.name}</td>
                </tr>
                <tr>
                  <th>商品グループ名</th>
                  <td>{category.faq_product.name}</td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{category.is_open ? "表示" : "非表示"}</td>
                </tr>
                <tr>
                  <th>表示優先度</th>
                  <td>{category.priority ? category.priority : "未設定"}</td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <button
                  className="design_button"
                  onClick={() => setCategoryEdit(true)}
                  disabled={isProcessing}
                >
                  編集
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isCategoryEdit && (
        <Modal close={() => setCategoryEdit(false)}>
          <Edit
            category={category}
            close={() => {
              setCategoryEdit(false);
              fetchFaqCategory();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default FaqCategoryDetail;
