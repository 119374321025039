import * as types from "../types/news";

const newsInfoInitialState = {
	id: "",
	url: "",
	title: "",
	tags: [],
	description: "",
	posted_at: "",
	created_at: "",
	is_open: "",
};

const filterInitialState = {
	tags: [],
	status: "default",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	filter: filterInitialState,
	info: newsInfoInitialState,
	newsList: {
		list: [],
		count: 0,
	},
	isProcessing: false,
	isUpdateComplete: false,
	isCreateComplete: false,
	currentPage: 1,
	error: null,
};

const news = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_NEWS_LIST_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_NEWS_LIST_SUCCESS:
			return {
				...state,
				isProcessing: false,
				newsList: {
					list: action.data.news_list,
					count: action.data.count,
				},
			};
		case types.GET_NEWS_LIST_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		case types.GET_NEWS_INFO_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_NEWS_INFO_SUCCESS:
			return {
				...state,
				isProcessing: false,
				info: action.data.news,
			};
		case types.GET_NEWS_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CREATE_NEWS_REQUEST:
			return { ...state, isProcessing: true };
		case types.CREATE_NEWS_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_NEWS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.UPDATE_NEWS_REQUEST:
			return { ...state, isProcessing: true };
		case types.UPDATE_NEWS_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_NEWS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CLEAR_NEWS_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false };
		case types.SET_NEWS_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_NEWS_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_NEWS_ERROR:
			return { ...state, error: null };
		case types.CHANGE_NEWS_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default news;
