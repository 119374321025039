import * as types from "../types/ugc";

const mediaInfoInitialState = {
	id: "",
	is_video: false,
	video_url: "",
	image_url: "",
	is_main: false,
};

const ugcInfoInitialState = {
	id: "",
	url: "",
	name: "",
	caption: "",
	posted_at: "",
	is_open: false,
	media_list: [],
	register_type: "",
};

const filterInitialState = {
	status: "default",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	info: ugcInfoInitialState,
	ugcList: {
		list: [],
		count: 0,
	},
	isUpdateComplete: false,
	isCreateComplete: false,
	currentPage: 1,
	isProcessing: false,
	filter: filterInitialState,
	error: null,
};

const order = (state = initialState, action) => {
	switch (action.type) {
		// UGC一覧取得
		case types.GET_UGC_LIST_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_UGC_LIST_SUCCESS:
			return {
				...state,
				isProcessing: false,
				ugcList: {
					list: action.data.ugc_list,
					count: action.data.count,
				},
			};
		case types.GET_UGC_LIST_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// UGC情報取得
		case types.GET_UGC_INFO_REQUEST:
			return {
				...state,
				isProcessing: true,
				info: ugcInfoInitialState,
			};
		case types.GET_UGC_INFO_SUCCESS:
			return { ...state, isProcessing: false, info: action.data.ugc };
		case types.GET_UGC_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// UGC作成
		case types.CREATE_UGC_REQUEST:
			return {
				...state,
				isProcessing: true,
			};
		case types.CREATE_UGC_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_UGC_FAILURE:
			return {
				...state,
				isProcessing: false,
				error: action.error,
			};

		// UGC編集
		case types.UPDATE_UGC_REQUEST:
			return {
				...state,
				isProcessing: true,
			};
		case types.UPDATE_UGC_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_UGC_FAILURE:
			return {
				...state,
				isProcessing: false,
				error: action.error,
			};

		case types.CLEAR_UGC_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false };
		case types.SET_UGC_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_UGC_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_UGC_ERROR:
			return { ...state, error: null };
		case types.CHANGE_UGC_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default order;
