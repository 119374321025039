// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import {
  createGiftProduct,
  clearStatus,
  getGiftProductDeliveryDate,
} from "redux/actions/gift";

// constant
import { productList, pillow } from "constants/products";

// component
import GiftProductFormItem from "components/pages/Gift/GiftCreate/GiftProductFormItem";

// style
import "./style.scss";

const GiftProductCreate = ({ gift, close }) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [targetProductList, setTargetProductList] = useState([]);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isCreateComplete = useSelector((state) => state.gift.isCreateComplete);

  const dispatch = useDispatch();

  const PRODUCT_FIELDS = {
    quantity: "quantity",
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const handleChangeQuantity = (productID, quantity) => {
    setTargetProductList((prevList) => {
      const index = prevList.findIndex(
        (target) => target.product_id === productID
      );

      if (index !== -1) {
        const updateList = [...prevList];
        updateList[index].quantity = quantity;
        return updateList;
      } else {
        return [...prevList, { product_id: productID, quantity }];
      }
    });
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const convertProductData = (list) => {
    return list
      .filter((target) => target.quantity !== 0)
      .map((target) => {
        const productID = target.product_id;
        const quantity = target.quantity;

        const deliveryDateField = productID + "_" + PRODUCT_FIELDS.deliveryDate;
        const deliveryDate = elements[deliveryDateField]
          ? elements[deliveryDateField].value
          : "";

        const deliveryTimeField = productID + "_" + PRODUCT_FIELDS.deliveryTime;
        const deliveryTime = elements[deliveryTimeField]
          ? elements[deliveryTimeField].value
          : "";

        return {
          product_id: productID,
          quantity,
          delivery_date: deliveryDate,
          delivery_time: deliveryTime,
        };
      });
  };

  const create = () => {
    const data = {
      gift_id: gift.id,
      product_list: convertProductData(targetProductList),
    };

    if (token) {
      dispatch(createGiftProduct(token, data));
    }
  };

  useEffect(() => {
    if (token && targetProductList.length !== 0) {
      const data = {
        product_list: targetProductList,
        postal_code: gift.postal_code,
      };
      dispatch(getGiftProductDeliveryDate(token, data));

      const isAllEmptyQuantityFields = targetProductList.every(
        (target) => target.quantity === 0
      );

      setIsSubmitEnabled(!isAllEmptyQuantityFields);
    }
  }, [token, targetProductList]);

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      close();
    }
  }, [isCreateComplete]);

  return (
    <div className="gift_product_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        {productList
          .filter((product) => product.productID !== pillow.productID)
          .map((productInfo, index) => (
            <GiftProductFormItem
              productInfo={productInfo}
              changeQuantity={handleChangeQuantity}
              key={index}
            />
          ))}
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default GiftProductCreate;
