// libs
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

// constants
import { mattressKing } from "constants/products";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const GiftProductFormItem = ({ productInfo, changeQuantity }) => {
  const FIELDS = {
    quantity: "quantity",
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
  };

  const productDeliveryList = useSelector(
    (state) => state.gift.productDeliveryList
  );

  const [selectedProductQuantity, setSelectedProductQuantity] = useState(0);
  const [productDeliveryInfo, setProductDeliveryInfo] = useState();
  const [isEnabledSpecification, setIsEnabledSpecification] = useState(false);

  const deliveryDateInputRef = useRef();
  const deliveryTimeInputRef = useRef();

  const isKing = mattressKing.productID === productInfo.productID;

  const resetDeliveryDateTime = () => {
    if (deliveryTimeInputRef.current && deliveryDateInputRef.current) {
      deliveryDateInputRef.current.value = "";
      deliveryTimeInputRef.current.value = "";
    }
  };

  const handleChangeQuantity = (quantity) => {
    setSelectedProductQuantity(quantity);
    changeQuantity(productInfo.productID, quantity);
  };

  useEffect(() => {
    const isEnabledQuantity = selectedProductQuantity !== 0;
    const isEnabledCandidate =
      productDeliveryInfo && productDeliveryInfo.candidate_delivery_date;
    setIsEnabledSpecification(isEnabledQuantity && isEnabledCandidate);
  }, [selectedProductQuantity, productDeliveryInfo]);

  useEffect(() => {
    if (!productDeliveryList) return;

    const target = productDeliveryList.find(
      (product) => product.product_id === productInfo.productID
    );
    setProductDeliveryInfo(target);
  }, [productDeliveryList]);

  return (
    <table className="product_item">
      <tbody>
        <tr>
          <th>{productInfo.name}</th>
          <td>
            <select
              className="design_select"
              name={productInfo.productID + "_" + FIELDS.quantity}
              onChange={(e) => handleChangeQuantity(parseInt(e.target.value))}
              required
            >
              {[...Array(8)].map((_, index) => (
                <option value={index} key={index}>
                  {index}個
                </option>
              ))}
            </select>
          </td>
        </tr>
        {isEnabledSpecification && (
          <>
            <tr className="delivery_date_input">
              <th>配送日</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={productInfo.productID + "_" + FIELDS.deliveryDate}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{ width: "50%" }}
                  min={
                    productDeliveryInfo &&
                    dateToString(
                      productDeliveryInfo.candidate_delivery_date,
                      "yyyy-MM-dd"
                    )
                  }
                  max={
                    productDeliveryInfo &&
                    dateToString(
                      productDeliveryInfo.candidate_delivery_date_end,
                      "yyyy-MM-dd"
                    )
                  }
                  ref={deliveryDateInputRef}
                />
              </td>
            </tr>
            <tr className="delivery_time_input">
              <th>配送時間帯</th>
              <td>
                <select
                  className="design_select"
                  name={productInfo.productID + "_" + FIELDS.deliveryTime}
                  ref={deliveryTimeInputRef}
                >
                  <option value="">時間希望なし</option>
                  <option value="AM">AM</option>
                  {isKing ? (
                    <>
                      <option value="12-18">12-18時</option>
                      <option value="18-21">18-21時</option>
                    </>
                  ) : (
                    <>
                      <option value="14">14-16時</option>
                      <option value="16">16-18時</option>
                      <option value="18">18-20時</option>
                      <option value="19">19-21時</option>
                    </>
                  )}
                </select>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default GiftProductFormItem;
