// libs
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import {
  getServerStatus,
  changeServerStatus,
  clearError,
  clearStatus,
} from "redux/actions/serverStatus";

// utils
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

import "./style.scss";

const ServerStatus = () => {
  const applicationId = applicationList.serverstatus.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const error = useSelector((state) => state.serverStatus.error);
  const serverStatus = useSelector((state) => state.serverStatus.status);
  const isLoading = useSelector((state) => state.serverStatus.isLoading);
  const isCompleted = useSelector((state) => state.serverStatus.isCompleted);

  const [selectStatus, setStatus] = useState(null);
  const [isEmergency, setIsEmergency] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const statusToString = (value) => {
    switch (value) {
      case "maintenance":
        return "メンテナンス中";
      case "working":
        return "動作中";
      default:
        return "";
    }
  };

  const handleSubmit = () => {
    if (window.confirm(`${statusToString(selectStatus)}に変更しますか？`)) {
      dispatch(changeServerStatus(token, selectStatus, isEmergency));
    }
  };

  useEffect(() => {
    if (isCompleted) {
      window.alert("メンテナンス情報を更新しました");
      dispatch(clearStatus());
      dispatch(getServerStatus(token));
    }
  }, [isCompleted]);

  useEffect(() => {
    setStatus(serverStatus);
  }, [serverStatus]);

  useEffect(() => {
    if (token) {
      dispatch(getServerStatus(token));
    } else {
      navigate("/login");
    }
  }, [token]);

  return (
    <div className="server_status">
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isLoading && <Loader />}
      <div className="title">メンテナンス設定</div>
      <div className="current_status">
        サーバ状況：<span>{statusToString(serverStatus)}</span>
      </div>
      {writeIsAllowed && (
        <>
          <div className="checkbox_container">
            <input 
              type="checkbox"
              onChange={(e) => setIsEmergency(e.target.checked)}
              disabled={serverStatus === "maintenance"}
            /> 
            緊急メンテナンス
          </div>
          <div className="select_container">
            <label className="radio_button">
              <input
                type="radio"
                value="maintenance"
                onChange={(e) => setStatus(e.target.value)}
                checked={selectStatus === "maintenance"}
                disabled={serverStatus === "maintenance"}
              />
              メンテナンス中に変更
            </label>
            <label className="radio_button">
              <input
                type="radio"
                value="working"
                onChange={(e) => setStatus(e.target.value)}
                checked={selectStatus === "working"}
                disabled={serverStatus === "working"}
              />
              動作中に変更
            </label>
          </div>
          <div className="button_container">
            <button
              className="design_button"
              onClick={handleSubmit}
              disabled={serverStatus === selectStatus || isLoading}
            >
              切り替え
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ServerStatus;
