export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const CLEAR_AUTH_STATUS = "CLEAR_AUTH_STATUS";
export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN";