export const GET_BUSINESS_CONTACTS_REQUEST = "GET_BUSINESS_CONTACTS_REQUEST";
export const GET_BUSINESS_CONTACTS_SUCCESS = "GET_BUSINESS_CONTACTS_SUCCESS";
export const GET_BUSINESS_CONTACTS_FAILURE = "GET_BUSINESS_CONTACTS_FAILURE";

export const GET_BUSINESS_CONTACT_INFO_REQUEST = "GET_BUSINESS_CONTACT_INFO_REQUEST";
export const GET_BUSINESS_CONTACT_INFO_SUCCESS = "GET_BUSINESS_CONTACT_INFO_SUCCESS";
export const GET_BUSINESS_CONTACT_INFO_FAILURE = "GET_BUSINESS_CONTACT_INFO_FAILURE";

export const GET_BUSINESS_CONTACT_ORDERS_REQUEST = "GET_BUSINESS_CONTACT_ORDERS_REQUEST";
export const GET_BUSINESS_CONTACT_ORDERS_SUCCESS = "GET_BUSINESS_CONTACT_ORDERS_SUCCESS";
export const GET_BUSINESS_CONTACT_ORDERS_FAILURE = "GET_BUSINESS_CONTACT_ORDERS_FAILURE";

export const UPDATE_BUSINESS_CONTACT_REQUEST = "UPDATE_BUSINESS_CONTACT_REQUEST";
export const UPDATE_BUSINESS_CONTACT_SUCCESS = "UPDATE_BUSINESS_CONTACT_SUCCESS";
export const UPDATE_BUSINESS_CONTACT_FAILURE = "UPDATE_BUSINESS_CONTACT_FAILURE";

export const CLEAR_BUSINESS_CONTACT_STATUS = "CLEAR_BUSINESS_CONTACT_STATUS";

export const SET_BUSINESS_CONTACT_FILTER = "SET_BUSINESS_CONTACT_FILTER";

export const CLEAR_BUSINESS_CONTACT_ERROR = "CLEAR_BUSINESS_CONTACT_ERROR";
export const CHANGE_BUSINESS_CONTACT_PAGE = "CHANGE_BUSINESS_CONTACT_PAGE";
