// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";
import Modal from "components/commons/Modal";
import OfflineStoreSaleEdit from "components/pages/OfflineStoreSaleDetail/OfflineStoreSaleEdit";

// action
import {
  getOfflineStoreSaleInfo,
  clearStatus,
  clearError,
} from "redux/actions/offlineStoreSale";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const OfflineStoreSaleDetail = () => {
  const { offlineStoreSaleId } = useParams();
  const applicationId = applicationList.offline_store_sales.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const offlineStoreSale = useSelector((state) => state.offlineStoreSale.info);
  const error = useSelector((state) => state.offlineStoreSale.error);
  const isProcessing = useSelector(
    (state) => state.offlineStoreSale.isProcessing
  );
  const [idOfflineStoreSaleEdit, setIsOfflineStoreSaleEdit] = useState(false);
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchOfflineStoreSale = () => {
    dispatch(getOfflineStoreSaleInfo(token, offlineStoreSaleId));
  };

  useEffect(() => {
    fetchOfflineStoreSale();
  }, []);

  return (
    <>
      <div className="offline_store_sale_detail detail">
        <div className="list_back_container">
          <Link to="/offline-store-sale/">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>セール名</th>
              <td>{offlineStoreSale.name}</td>
            </tr>
            <tr>
              <th>店舗名</th>
              <td>
                {offlineStoreSale.stores.length > 0 &&
                  offlineStoreSale.stores
                    .map((store) => store.store_name)
                    .join(",")}
              </td>
            </tr>
            <tr>
              <th>割引率（％）</th>
              <td>{offlineStoreSale.discount_rate}</td>
            </tr>
            <tr>
              <th>開始日時</th>
              <td>
                {dateToString(offlineStoreSale.start_at, "yyyy/MM/dd HH:mm")}
              </td>
            </tr>
            <tr>
              <th>終了日時</th>
              <td>
                {dateToString(offlineStoreSale.end_at, "yyyy/MM/dd HH:mm")}
              </td>
            </tr>

            <tr>
              <th>作成日時</th>
              <td>
                {dateToString(offlineStoreSale.created_at, "yyyy/MM/dd HH:mm")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <button
              className="design_button"
              onClick={() => setIsOfflineStoreSaleEdit(true)}
              disabled={isProcessing}
            >
              編集
            </button>
          )}
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {idOfflineStoreSaleEdit && (
        <Modal close={() => setIsOfflineStoreSaleEdit(false)}>
          <OfflineStoreSaleEdit
            offlineStoreSale={offlineStoreSale}
            close={() => {
              setIsOfflineStoreSaleEdit(false);
              fetchOfflineStoreSale();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default OfflineStoreSaleDetail;
