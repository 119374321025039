// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

// component
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import AdminUserPermissionGroupCreate from "./AdminUserPermissionGroupCreate";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// action
import {
  getPermissionGroups,
  permissionGroupSetFilter,
  permissionGroupClearError,
  permissionGroupChangePage,
} from "redux/actions/adminUser";
import { logout } from "redux/actions/auth";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const AdminUserPermissionGroup = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.adminuser.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const permissionGroupList = useSelector(
    (state) => state.adminUser.permissionGroupList.list
  );
  const permissionGroupCount = useSelector(
    (state) => state.adminUser.permissionGroupList.count
  );
  const permissionGroupIsProcessing = useSelector(
    (state) => state.adminUser.permissionGroupIsProcessing
  );
  const permissionGroupFilter = useSelector(
    (state) => state.adminUser.permissionGroupFilter
  );
  const permissionGroupError = useSelector(
    (state) => state.adminUser.permissionGroupError
  );
  const permissionGroupCurrentPage = useSelector(
    (state) => state.adminUser.permissionGroupCurrentPage
  );

  const [pageOffset, setPageOffset] = useState(
    (permissionGroupCurrentPage - 1) * pageLimit
  );
  const [isPermissionGroupCreate, setPermissionGroupCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(
    permissionGroupFilter.sortBy
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const FIELDS = {
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(permissionGroupChangePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(permissionGroupChangePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getPermissionGroups(token, data));
    }

    const filterData = {
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(permissionGroupSetFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/adminuser/permissiongroup/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  useEffect(() => {
    if (permissionGroupError) {
      switch (permissionGroupError.response.data.code) {
        case 1000:
          window.alert("再度ログインしてください");
          dispatch(logout(token));
          break;
        case 1003:
          window.alert("権限がありません");
          break;
        case 1005:
          window.alert("不正な権限リストです");
          break;
        case 1007:
          window.alert("不正な名前です");
          break;
        case 3000:
          window.alert("不正なソートが設定されています");
          break;
        default:
          window.alert("予期せぬエラーが発生しました");
          break;
      }
      dispatch(permissionGroupClearError());
    }
  }, [permissionGroupError]);

  return (
    <>
      <div className="notice_list list">
        <div>
          <Link to="/adminuser/">管理ユーザ一覧へ</Link>
        </div>
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={permissionGroupFilter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="created_at">作成日時</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={permissionGroupFilter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={permissionGroupFilter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={permissionGroupIsProcessing}
            />
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setPermissionGroupCreate(true)}
                disabled={permissionGroupIsProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="name">権限グループ名</th>
                <th className="create">作成日時</th>
              </tr>
            </thead>
            <tbody>
              {permissionGroupList.map((permissionGroup) => (
                <PermissionGroupItem
                  permissionGroup={permissionGroup}
                  selectItem={selectItem}
                  key={permissionGroup.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(permissionGroupCount / pageLimit)}
            currentIndex={permissionGroupCurrentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      {permissionGroupIsProcessing && <Loader />}
      {isPermissionGroupCreate && (
        <Modal close={() => setPermissionGroupCreate(false)}>
          <AdminUserPermissionGroupCreate
            close={() => setPermissionGroupCreate(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default AdminUserPermissionGroup;

const PermissionGroupItem = (props) => {
  const { id, name, created_at } = props.permissionGroup;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="name">
        <div className="inner">{name}</div>
      </td>
      <td className="create">{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
