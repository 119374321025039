import * as types from "../types/sale";

const saleInfoInitialState = {
	name: "",
	coupon: {
		code: "",
	},
	start_at: "",
	end_at: "",
	is_open: "",
};

const filterInitialState = {
	status: "default",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	info: saleInfoInitialState,
	saleList: {
		list: [],
		count: 0,
	},
	isUpdateComplete: false,
	isCreateComplete: false,
	currentPage: 1,
	isProcessing: false,
	filter: filterInitialState,
	error: null,
};

const sale = (state = initialState, action) => {
	switch (action.type) {
		// セール一覧取得
		case types.GET_SALE_LIST_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_SALE_LIST_SUCCESS:
			return {
				...state,
				isProcessing: false,
				saleList: {
					list: action.data.sale_list,
					count: action.data.count,
				},
			};
		case types.GET_SALE_LIST_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// セール情報取得
		case types.GET_SALE_INFO_REQUEST:
			return {
				...state,
				isProcessing: true,
				info: saleInfoInitialState,
			};
		case types.GET_SALE_INFO_SUCCESS:
			return { ...state, isProcessing: false, info: action.data.sale };
		case types.GET_SALE_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// セール作成
		case types.CREATE_SALE_REQUEST:
			return {
				...state,
				isProcessing: true,
				isCreateComplete: false,
				error: null,
			};
		case types.CREATE_SALE_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_SALE_FAILURE:
			return {
				...state,
				isProcessing: false,
				isCreateComplete: false,
				error: action.error,
			};

		// セール情報編集
		case types.UPDATE_SALE_REQUEST:
			return {
				...state,
				isProcessing: true,
				isUpdateComplete: false,
				error: null,
			};
		case types.UPDATE_SALE_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_SALE_FAILURE:
			return {
				...state,
				isProcessing: false,
				isUpdateComplete: false,
				error: action.error,
			};

		case types.CLEAR_SALE_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false };
		case types.SET_SALE_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_SALE_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_SALE_ERROR:
			return { ...state, error: null };
		case types.CHANGE_SALE_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default sale;
