// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  updateAdminUser,
  adminUserClearStatus,
  getPermissionGroups,
} from "redux/actions/adminUser";
import { getMyAccountInfo } from "redux/actions/myAccount";

// utils
import { dateToString } from "utils/date";

// styles
import "./style.scss";

const AdminUserEdit = (props) => {
  const { id, name, is_super, permission_group, created_at } = props.adminUser;

  const FIELDS = {
    name: "name",
    permissionGroupId: "permission_group_id",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const adminUserIsProcessing = useSelector(
    (state) => state.adminUser.adminUserIsProcessing
  );
  const adminUserIsUpdateComplete = useSelector(
    (state) => state.adminUser.adminUserIsUpdateComplete
  );
  const permissionGroupList = useSelector(
    (state) => state.adminUser.permissionGroupList.list
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeAdminUser();
    }
  };

  const changeAdminUser = () => {
    const data = {
      admin_user_id: id,
      name: elements[FIELDS.name].value,
      permission_group_id: elements[FIELDS.permissionGroupId].value,
    };

    if (token) {
      dispatch(updateAdminUser(token, data));
    }
  };

  useEffect(() => {
    if (adminUserIsUpdateComplete) {
      window.alert("変更しました");
      dispatch(adminUserClearStatus());
      props.close();
      if (myAccount.id === id) {
        dispatch(getMyAccountInfo(token));
      }
    }
  }, [adminUserIsUpdateComplete]);

  useEffect(() => {
    dispatch(getPermissionGroups(token));
  }, []);

  return (
    <div className="admin_user_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="admin_user_item">
          <tbody>
            <tr>
              <th>id</th>
              <td>{id}</td>
            </tr>
            <tr>
              <th>名前</th>
              <td>
                <input
                  className="design_input"
                  name={FIELDS.name}
                  type="text"
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>権限</th>
              <td>
                <input
                  name={FIELDS.permissionGroupId}
                  type="radio"
                  value=""
                  defaultChecked={permission_group === null}
                  disabled={is_super}
                />
                権限なし
                {permissionGroupList.map((permissionGroup, index) => (
                  <PermissionGroupItem
                    permissionGroup={permissionGroup}
                    FIELDS={FIELDS}
                    defaultPermissionGroupId={
                      permission_group ? permission_group.id : null
                    }
                    is_super={is_super}
                    key={index}
                  />
                ))}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={adminUserIsProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default AdminUserEdit;

const PermissionGroupItem = (props) => {
  const { permissionGroup, FIELDS, defaultPermissionGroupId, is_super } = props;

  return (
    <div>
      <input
        name={FIELDS.permissionGroupId}
        type="radio"
        value={permissionGroup.id}
        defaultChecked={defaultPermissionGroupId === permissionGroup.id}
        disabled={is_super}
      />
      {permissionGroup.name}
    </div>
  );
};
