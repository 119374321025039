// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateNews, clearStatus } from "redux/actions/news";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const ProductEdit = (props) => {
  // params
  const { id, title, description, url, is_open, tags, posted_at } = props.news;

  const FIELDS = {
    title: "title",
    description: "description",
    url: "url",
    tags: "tags",
    isOpen: "is_open",
    postedAt: "posted_at",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.news.isProcessing);
  const isUpdateComplete = useSelector((state) => state.news.isUpdateComplete);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeNews();
    }
  };

  const changeNews = () => {
    const data = {
      news_id: id,
      title: elements[FIELDS.title].value,
      description: elements[FIELDS.description].value,
      url: elements[FIELDS.url].value,
      tags: elements[FIELDS.tags].value.split(","),
      posted_at: elements[FIELDS.postedAt].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    if (token) {
      dispatch(updateNews(token, data));
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="news_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="news_item">
          <tbody>
            <tr>
              <th>タイトル</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.title}
                  defaultValue={title}
                />
              </td>
            </tr>
            <tr>
              <th>説明</th>
              <td>
                <textarea
                  className="design_input"
                  name={FIELDS.description}
                  style={{ resize: "none", height: "30vh" }}
                  defaultValue={description}
                />
              </td>
            </tr>
            <tr>
              <th>記事URL</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.url}
                  defaultValue={url}
                />
              </td>
            </tr>
            <tr>
              <th>タグ（カンマ区切り）</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.tags}
                  defaultValue={tags.join(",")}
                />
              </td>
            </tr>
            <tr>
              <th>掲載日時</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={FIELDS.postedAt}
                  defaultValue={dateToString(posted_at, "yyyy-MM-dd")}
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.isOpen}
                  defaultValue={is_open}
                >
                  <option value={true}>公開</option>
                  <option value={false}>非公開</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
