export const GET_FAQ_PRODUCTS_REQUEST = "GET_FAQ_PRODUCTS_REQUEST";
export const GET_FAQ_PRODUCTS_SUCCESS = "GET_FAQ_PRODUCTS_SUCCESS";
export const GET_FAQ_PRODUCTS_FAILURE = "GET_FAQ_PRODUCTS_FAILURE";

export const CREATE_FAQ_PRODUCT_REQUEST = "CREATE_FAQ_PRODUCT_REQUEST";
export const CREATE_FAQ_PRODUCT_SUCCESS = "CREATE_FAQ_PRODUCT_SUCCESS";
export const CREATE_FAQ_PRODUCT_FAILURE = "CREATE_FAQ_PRODUCT_FAILURE";

export const UPDATE_FAQ_PRODUCT_REQUEST = "UPDATE_FAQ_PRODUCT_REQUEST";
export const UPDATE_FAQ_PRODUCT_SUCCESS = "UPDATE_FAQ_PRODUCT_SUCCESS";
export const UPDATE_FAQ_PRODUCT_FAILURE = "UPDATE_FAQ_PRODUCT_FAILURE";

export const GET_FAQ_PRODUCT_REQUEST = "GET_FAQ_PRODUCT_REQUEST";
export const GET_FAQ_PRODUCT_SUCCESS = "GET_FAQ_PRODUCT_SUCCESS";
export const GET_FAQ_PRODUCT_FAILURE = "GET_FAQ_PRODUCT_FAILURE";

export const SET_FAQ_PRODUCT_FILTER = "SET_FAQ_PRODUCT_FILTER";
export const CLEAR_FAQ_PRODUCT_FILTER = "CLEAR_FAQ_PRODUCT_FILTER";


export const GET_FAQ_CATEGORIES_REQUEST = "GET_FAQ_CATEGORIES_REQUEST";
export const GET_FAQ_CATEGORIES_SUCCESS = "GET_FAQ_CATEGORIES_SUCCESS";
export const GET_FAQ_CATEGORIES_FAILURE = "GET_FAQ_CATEGORIES_FAILURE";

export const CREATE_FAQ_CATEGORY_REQUEST = "CREATE_FAQ_CATEGORY_REQUEST";
export const CREATE_FAQ_CATEGORY_SUCCESS = "CREATE_FAQ_CATEGORY_SUCCESS";
export const CREATE_FAQ_CATEGORY_FAILURE = "CREATE_FAQ_CATEGORY_FAILURE";

export const UPDATE_FAQ_CATEGORY_REQUEST = "UPDATE_FAQ_CATEGORY_REQUEST";
export const UPDATE_FAQ_CATEGORY_SUCCESS = "UPDATE_FAQ_CATEGORY_SUCCESS";
export const UPDATE_FAQ_CATEGORY_FAILURE = "UPDATE_FAQ_CATEGORY_FAILURE";

export const GET_FAQ_CATEGORY_REQUEST = "GET_FAQ_CATEGORY_REQUEST";
export const GET_FAQ_CATEGORY_SUCCESS = "GET_FAQ_CATEGORY_SUCCESS";
export const GET_FAQ_CATEGORY_FAILURE = "GET_FAQ_CATEGORY_FAILURE";

export const SET_FAQ_CATEGORY_FILTER = "SET_FAQ_CATEGORY_FILTER";
export const CLEAR_FAQ_CATEGORY_FILTER = "CLEAR_FAQ_CATEGORY_FILTER";

export const GET_FAQS_REQUEST = "GET_FAQS_REQUEST";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAILURE = "GET_FAQS_FAILURE";

export const CREATE_FAQ_REQUEST = "CREATE_FAQ_REQUEST";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_FAILURE = "CREATE_FAQ_FAILURE";

export const UPDATE_FAQ_REQUEST = "UPDATE_FAQ_REQUEST";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAILURE = "UPDATE_FAQ_FAILURE";

export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const CLEAR_FAQ_STATUS = "CLEAR_MEDIA_STATUS";

export const SET_FAQ_FILTER = "SET_MEDIA_FILTER";
export const CLEAR_FAQ_FILTER = "CLEAR_MEDIA_FILTER";

export const CLEAR_FAQ_ERROR = "CLEAR_MEDIA_ERROR";
export const CHANGE_FAQ_PAGE = "CHANGE_MEDIA_PAGE";
