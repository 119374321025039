import * as types from "../types/offlineStoreSale";

const offlineStoreSaleInfoInitialState = {
  id: "",
  stores: [],
  discount_rate: "",
  start_at: "",
  end_at: "",
  created_at: "",
};

const filterInitialState = {
  saleName: "",
  storeName: "",
  sortBy: "created_at",
  sortType: "desc",
};

const initialState = {
  info: offlineStoreSaleInfoInitialState,
  offlineStoreSaleList: {
    list: [],
    count: 0,
  },
  isProcessing: false,
  filter: filterInitialState,
  isUpdateComplete: false,
  isCreateComplete: false,
  currentPage: 1,
  error: null,
};

const offlineStoreSale = (state = initialState, action) => {
  switch (action.type) {
    // オフライン店舗セール一覧取得
    case types.GET_OFFLINE_STORE_SALES_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_OFFLINE_STORE_SALES_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        offlineStoreSaleList: {
          list: action.data.sales,
          count: action.data.count,
        },
      };
    case types.GET_OFFLINE_STORE_SALES_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗セール情報取得
    case types.GET_OFFLINE_STORE_SALE_INFO_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_OFFLINE_STORE_SALE_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        info: action.data,
      };
    case types.GET_OFFLINE_STORE_SALE_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗セール情報作成
    case types.CREATE_OFFLINE_STORE_SALE_REQUEST:
      return { ...state, isProcessing: true };
    case types.CREATE_OFFLINE_STORE_SALE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isCreateComplete: true,
      };
    case types.CREATE_OFFLINE_STORE_SALE_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗セール変更
    case types.UPDATE_OFFLINE_STORE_SALE_REQUEST:
      return { ...state, isProcessing: true };
    case types.UPDATE_OFFLINE_STORE_SALE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isUpdateComplete: true,
        info: action.data,
      };
    case types.UPDATE_OFFLINE_STORE_SALE_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    case types.CLEAR_OFFLINE_STORE_SALE_STATUS:
      return {
        ...state,
        isUpdateComplete: false,
        isCreateComplete: false,
      };
    case types.SET_OFFLINE_STORE_SALE_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_OFFLINE_STORE_SALE_FILTER:
      return { ...state, filter: filterInitialState };
    case types.CLEAR_OFFLINE_STORE_SALE_ERROR:
      return { ...state, error: null };
    case types.CHANGE_OFFLINE_STORE_SALE_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default offlineStoreSale;
