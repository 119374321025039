import * as types from "../types/faq";

const faqProductInfoInitialState = {
	id: "",
	name: "",
	is_open: "",
	manage_id: "",
	priority: 0,
};

const faqCategoryInfoInitialState = {
	id: "",
	name: "",
	is_open: "",
	faq_product: faqProductInfoInitialState,
	manage_id: "",
	priority: 0,
};

const faqInfoInitialState = {
	id: "",
	question: "",
	answer: "",
	is_open: "",
	category: faqCategoryInfoInitialState,
	manage_id: "",
	priority: 0,
};

const productFilterInitialState = {
	status: "default",
	sortBy: "priority",
	sortType: "desc",
}

const categoryFilterInitialState = {
	faqProductID: "",
	status: "default",
	sortBy: "priority",
	sortType: "desc",
}

const faqFilterInitialState = {
	faqProductID: "",
	status: "default",
	sortBy: "priority",
	sortType: "desc",
};

const initialState = {
	categoryInfo: faqCategoryInfoInitialState,
	categoryList: {
		list: [],
		count: 0,
	},
	productInfo: faqProductInfoInitialState,
	productList: {
		list: [],
		count: 0
	},
	faqInfo: faqInfoInitialState,
	faqList: {
		list: [],
		count: 0,
	},
	isProcessing: false,
	isUpdateComplete: false,
	isCreateComplete: false,
	currentPage: 1,
	faqFilter: faqFilterInitialState,
	categoryFilter: categoryFilterInitialState,
	productFilter: productFilterInitialState,
	error: null,
};

const faq = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_FAQ_PRODUCTS_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_FAQ_PRODUCTS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				productList: {
					list: action.data.faq_product_list,
					count: action.data.count,
				},
			};
		case types.GET_FAQ_PRODUCTS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CREATE_FAQ_PRODUCT_REQUEST:
			return { ...state, isProcessing: true };
		case types.CREATE_FAQ_PRODUCT_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_FAQ_PRODUCT_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.UPDATE_FAQ_PRODUCT_REQUEST:
			return { ...state, isProcessing: true };
		case types.UPDATE_FAQ_PRODUCT_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_FAQ_PRODUCT_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.GET_FAQ_PRODUCT_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_FAQ_PRODUCT_SUCCESS:
			return {
				...state,
				isProcessing: false,
				productInfo: action.data.faq_product,
			};
		case types.GET_FAQ_PRODUCT_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.SET_FAQ_PRODUCT_FILTER:
			return { ...state, productFilter: action.data };
		case types.CLEAR_FAQ_PRODUCT_FILTER:
			return { ...state, productFilter: productFilterInitialState };

		case types.GET_FAQ_CATEGORIES_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_FAQ_CATEGORIES_SUCCESS:
			return {
				...state,
				isProcessing: false,
				categoryList: {
					list: action.data.category_list,
					count: action.data.count,
				},
			};
		case types.GET_FAQ_CATEGORIES_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CREATE_FAQ_CATEGORY_REQUEST:
			return { ...state, isProcessing: true };
		case types.CREATE_FAQ_CATEGORY_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_FAQ_CATEGORY_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.UPDATE_FAQ_CATEGORY_REQUEST:
			return { ...state, isProcessing: true };
		case types.UPDATE_FAQ_CATEGORY_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_FAQ_CATEGORY_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.GET_FAQ_CATEGORY_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_FAQ_CATEGORY_SUCCESS:
			return {
				...state,
				isProcessing: false,
				categoryInfo: action.data.category,
			};
		case types.GET_FAQ_CATEGORY_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.SET_FAQ_CATEGORY_FILTER:
			return { ...state, categoryFilter: action.data };
		case types.CLEAR_FAQ_CATEGORY_FILTER:
			return { ...state, categoryFilter: categoryFilterInitialState };

		case types.GET_FAQS_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_FAQS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				faqList: {
					list: action.data.faq_list,
					count: action.data.count,
				},
			};
		case types.GET_FAQS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CREATE_FAQ_REQUEST:
			return { ...state, isProcessing: true };
		case types.CREATE_FAQ_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_FAQ_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.UPDATE_FAQ_REQUEST:
			return { ...state, isProcessing: true };
		case types.UPDATE_FAQ_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_FAQ_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.GET_FAQ_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_FAQ_SUCCESS:
			return { ...state, isProcessing: false, faqInfo: action.data.faq };
		case types.GET_FAQ_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CLEAR_FAQ_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false };
		case types.SET_FAQ_FILTER:
			return { ...state, faqFilter: action.data };
		case types.CLEAR_FAQ_FILTER:
			return { ...state, faqFilter: faqFilterInitialState };
		case types.CLEAR_FAQ_ERROR:
			return { ...state, error: null };
		case types.CHANGE_FAQ_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default faq;
