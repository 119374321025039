import axios from "../network";
import * as types from "../types/offlineStore";

const getOfflineStoresRequest = () => {
  return {
    type: types.GET_OFFLINE_STORES_REQUEST,
  };
};

const getOfflineStoresSuccess = (data) => {
  return {
    type: types.GET_OFFLINE_STORES_SUCCESS,
    data,
  };
};

const getOfflineStoresFailure = (error) => {
  return {
    type: types.GET_OFFLINE_STORES_FAILURE,
    error,
  };
};

export const getOfflineStores = (token, data) => {
  return (dispatch) => {
    dispatch(getOfflineStoresRequest());
    return axios
      .get("/admin/offline-store/stores/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => dispatch(getOfflineStoresSuccess(res.data)))
      .catch((err) => dispatch(getOfflineStoresFailure(err)));
  };
};

const getOfflineStoreInfoRequest = () => {
  return {
    type: types.GET_OFFLINE_STORE_INFO_REQUEST,
  };
};

const getOfflineStoreInfoSuccess = (data) => {
  return {
    type: types.GET_OFFLINE_STORE_INFO_SUCCESS,
    data,
  };
};

const getOfflineStoreInfoFailure = (error) => {
  return {
    type: types.GET_OFFLINE_STORE_INFO_FAILURE,
    error,
  };
};

export const getOfflineStoreInfo = (token, storeId) => {
  return (dispatch) => {
    dispatch(getOfflineStoreInfoRequest());
    return axios
      .get(`/admin/offline-store/stores/${storeId}/`, {
        headers: { token: token },
        data: {},
      })
      .then((res) => {
        return dispatch(getOfflineStoreInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getOfflineStoreInfoFailure(err)));
  };
};

const createOfflineStoreRequest = () => {
  return {
    type: types.CREATE_OFFLINE_STORE_REQUEST,
  };
};

const createOfflineStoreSuccess = (data) => {
  return {
    type: types.CREATE_OFFLINE_STORE_SUCCESS,
    data,
  };
};

const createOfflineStoreFailure = (error) => {
  return {
    type: types.CREATE_OFFLINE_STORE_FAILURE,
    error,
  };
};

export const createOfflineStore = (token, data) => {
  return (dispatch) => {
    dispatch(createOfflineStoreRequest());
    return axios
      .post("/admin/offline-store/stores/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(createOfflineStoreSuccess(res.data));
      })
      .catch((err) => dispatch(createOfflineStoreFailure(err)));
  };
};

const deleteOfflineStoreRequest = () => {
  return {
    type: types.DELETE_OFFLINE_STORE_REQUEST,
  };
};

const deleteOfflineStoreSuccess = (data) => {
  return {
    type: types.DELETE_OFFLINE_STORE_SUCCESS,
    data,
  };
};

const deleteOfflineStoreFailure = (error) => {
  return {
    type: types.DELETE_OFFLINE_STORE_FAILURE,
    error,
  };
};

export const deleteOfflineStore = (token, storeId) => {
  return (dispatch) => {
    dispatch(deleteOfflineStoreRequest());
    return axios
      .delete(`/admin/offline-store/stores/${storeId}/`, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(deleteOfflineStoreSuccess(res.data));
      })
      .catch((err) => dispatch(deleteOfflineStoreFailure(err)));
  };
};

const resetPasswordOfflineStoreRequest = () => {
  return {
    type: types.RESET_PASSWORD_OFFLINE_STORE_REQUEST,
  };
};

const resetPasswordOfflineStoreSuccess = (data) => {
  return {
    type: types.RESET_PASSWORD_OFFLINE_STORE_SUCCESS,
    data,
  };
};

const resetPasswordOfflineStoreFailure = (error) => {
  return {
    type: types.RESET_PASSWORD_OFFLINE_STORE_FAILURE,
    error,
  };
};

export const resetPasswordOfflineStore = (token, storeId) => {
  return (dispatch) => {
    dispatch(resetPasswordOfflineStoreRequest());
    return axios
      .post(
        `/admin/offline-store/stores/${storeId}/reset-password/`,
        {},
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        return dispatch(resetPasswordOfflineStoreSuccess(res.data));
      })
      .catch((err) => dispatch(resetPasswordOfflineStoreFailure(err)));
  };
};

export const clearStatus = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_STATUS,
  };
};

export const setFilter = (data) => {
  return {
    type: types.SET_OFFLINE_STORE_FILTER,
    data: data,
  };
};

export const clearFilter = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_FILTER,
  };
};

export const clearError = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_ERROR,
  };
};

export const changePage = (page) => {
  return {
    type: types.CHANGE_OFFLINE_STORE_PAGE,
    page: page,
  };
};
