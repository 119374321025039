// libs
import React from "react";

// styles
import "./style.scss";

const Pager = ({ pageCount, currentIndex, changeIndex }) => {
  const handlePrevButton = () => {
    if (currentIndex !== 0) {
      changeIndex(currentIndex - 1);
    }
  };

  const handleNextButton = () => {
    if (currentIndex !== pageCount - 1) {
      changeIndex(currentIndex + 1);
    }
  };

  return (
    <div className="pager">
      <button onClick={handlePrevButton}>前へ</button>
      <div className="select_container">
        <div className="context">
          {currentIndex + 1}/{pageCount}
        </div>
        <i className="fas fa-chevron-down" />
        <select
          onChange={(event) => changeIndex(parseInt(event.target.value, 10))}
        >
          {[...Array(pageCount)].map((_, index) => (
            <option value={index} key={index}>
              {parseInt(index, 10) + 1}/{pageCount}
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleNextButton}>次へ</button>
    </div>
  );
};

export default Pager;
