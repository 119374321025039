export const GET_SALE_LIST_REQUEST = "GET_SALE_LIST_REQUEST";
export const GET_SALE_LIST_SUCCESS = "GET_SALE_LIST_SUCCESS";
export const GET_SALE_LIST_FAILURE = "GET_SALE_LIST_FAILURE";

export const GET_SALE_INFO_REQUEST = "GET_SALE_INFO_REQUEST";
export const GET_SALE_INFO_SUCCESS = "GET_SALE_INFO_SUCCESS";
export const GET_SALE_INFO_FAILURE = "GET_SALE_INFO_FAILURE";

export const CREATE_SALE_REQUEST = "CREATE_SALE_REQUEST";
export const CREATE_SALE_SUCCESS = "CREATE_SALE_SUCCESS";
export const CREATE_SALE_FAILURE = "CREATE_SALE_FAILURE";

export const UPDATE_SALE_REQUEST = "UPDATE_SALE_REQUEST";
export const UPDATE_SALE_SUCCESS = "UPDATE_SALE_SUCCESS";
export const UPDATE_SALE_FAILURE = "UPDATE_SALE_FAILURE";

export const CLEAR_SALE_STATUS = "CLEAR_SALE_STATUS";

export const SET_SALE_FILTER = "SET_SALE_FILTER";
export const CLEAR_SALE_FILTER = "CLEAR_SALE_FILTER";

export const CLEAR_SALE_ERROR = "CLEAR_SALE_ERROR";
export const CHANGE_SALE_PAGE = "CHANGE_SALE_PAGE";
