// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  updateOfflineStoreSale,
  clearStatus,
} from "redux/actions/offlineStoreSale";
import { getOfflineStores } from "redux/actions/offlineStore";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const OfflineStoreSaleEdit = (props) => {
  // params
  const { id, name, stores, discount_rate, start_at, end_at } =
    props.offlineStoreSale;
  const [selectedStores, setSelectedStores] = useState([]);

  const FIELDS = {
    name: "name",
    discountRate: "discount_rate",
    startAt: "start_at",
    endAt: "end_at",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector(
    (state) => state.offlineStoreSale.isProcessing
  );
  const isUpdateComplete = useSelector(
    (state) => state.offlineStoreSale.isUpdateComplete
  );
  const offlineStores = useSelector(
    (state) => state.offlineStore.offlineStoreList.list
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeOfflineStoreSale();
    }
  };

  const changeOfflineStoreSale = () => {
    const data = {
      name: elements[FIELDS.name].value,
      discount_rate: elements[FIELDS.discountRate].value,
      store_id_list: selectedStores.map((store) => store.store_id),
      start_at: elements[FIELDS.startAt].value,
      end_at: elements[FIELDS.endAt].value,
    };

    if (token) {
      dispatch(updateOfflineStoreSale(token, id, data));
    }
  };

  const addStore = (store) => {
    setSelectedStores((prev) => {
      // 元のselectedStores配列（prev）に同じオブジェクトが含まれているかチェック
      const isAlreadySelected = prev.some(
        (selectedStore) => selectedStore.store_id === store.store_id
      );

      // 含まれていなければ追加、含まれていればそのまま
      return isAlreadySelected ? prev : [...prev, store];
    });
  };

  const deleteStore = (store) => {
    setSelectedStores((prev) =>
      prev.filter((s) => s.store_id !== store.store_id)
    );
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  useEffect(() => {
    dispatch(getOfflineStores(token));
  }, []);

  useEffect(() => {
    setSelectedStores(stores);
  }, [offlineStores]);

  return (
    <div className="offlineStoreSale_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="offlineStoreSale_item">
          <tbody>
            <tr>
              <th>セール名</th>
              <td>
                <input
                  type="text"
                  name={FIELDS.name}
                  className="design_input"
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>対象店舗</th>
              <td>
                <div>
                  {selectedStores.map((store) => (
                    <div className="selected_items" key={store.store_id}>
                      <span className="item_name">{store.store_name}</span>
                      <span
                        className="delete_button"
                        onClick={() => deleteStore(store)}
                      ></span>
                    </div>
                  ))}
                </div>
                <AddSelectForm
                  selectedStores={selectedStores}
                  addStore={(store) => addStore(store)}
                />
              </td>
            </tr>
            <tr>
              <th>割引率（％）</th>
              <td>
                <input
                  type="text"
                  name={FIELDS.discountRate}
                  className="design_input"
                  defaultValue={discount_rate}
                />
              </td>
            </tr>
            <tr>
              <th>セール開始日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.startAt}
                  defaultValue={dateToString(start_at, "yyyy-MM-ddTHH:mm")}
                />
              </td>
            </tr>
            <tr>
              <th>セール終了日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.endAt}
                  defaultValue={dateToString(end_at, "yyyy-MM-ddTHH:mm")}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default OfflineStoreSaleEdit;

const AddSelectForm = ({ selectedStores, addStore }) => {
  const offlineStores = useSelector(
    (state) => state.offlineStore.offlineStoreList.list
  );
  const [selectedStoreId, setSelectedStoreId] = useState("");

  // 選択された店舗の ID を管理
  const handleChange = (e) => {
    setSelectedStoreId(e.target.value);
  };

  // 追加ボタンが押されたときの処理
  const handleAdd = (e) => {
    e.preventDefault();
    const selectedStore = offlineStores.find(
      (store) => store.store_id === selectedStoreId
    );
    if (selectedStore) {
      addStore(selectedStore);
      setSelectedStoreId(""); // 選択リセット
    }
  };

  const availableStores = useMemo(() => {
    return offlineStores.filter(
      (store) =>
        !selectedStores.some((selected) => selected.store_id === store.store_id)
    );
  }, [offlineStores, selectedStores]);

  return (
    <>
      <select
        className="design_select"
        name="offline_stores"
        value={selectedStoreId}
        onChange={handleChange}
      >
        <option value="" disabled>
          店舗を選択してください
        </option>
        {availableStores.map((store) => (
          <option key={store.id} value={store.id}>
            {store.name}
          </option>
        ))}
      </select>
      <button type="button" className="design_button" onClick={handleAdd}>
        追加
      </button>
    </>
  );
};
