import axios from "../network";
import * as types from "../types/businessContact";

const getBusinessContactsRequest = () => {
  return {
    type: types.GET_BUSINESS_CONTACTS_REQUEST,
  };
};

const getBusinessContactsSuccess = (data) => {
  return {
    type: types.GET_BUSINESS_CONTACTS_SUCCESS,
    data,
  };
};

const getBusinessContactsFailure = (error) => {
  return {
    type: types.GET_BUSINESS_CONTACTS_FAILURE,
    error,
  };
};

export const getBusinessContacts = (token, data) => {
  return (dispatch) => {
    dispatch(getBusinessContactsRequest());
    return axios
      .get("/admin/businesscontact/list/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => dispatch(getBusinessContactsSuccess(res.data)))
      .catch((err) => dispatch(getBusinessContactsFailure(err)));
  };
};

const getBusinessContactInfoRequest = () => {
  return {
    type: types.GET_BUSINESS_CONTACT_INFO_REQUEST,
  };
};

const getBusinessContactInfoSuccess = (data) => {
  return {
    type: types.GET_BUSINESS_CONTACT_INFO_SUCCESS,
    data,
  };
};

const getBusinessContactInfoFailure = (error) => {
  return {
    type: types.GET_BUSINESS_CONTACT_INFO_FAILURE,
    error,
  };
};

export const getBusinessContactInfo = (token, businessContactId) => {
  return (dispatch) => {
    dispatch(getBusinessContactInfoRequest());
    return axios
      .get("/admin/businesscontact/", {
        headers: { token: token },
        params: { business_contact_id: businessContactId },
        data: {},
      })
      .then((res) => dispatch(getBusinessContactInfoSuccess(res.data)))
      .catch((err) => dispatch(getBusinessContactInfoFailure(err)));
  };
};

const updateBusinessContactRequest = () => {
  return {
    type: types.UPDATE_BUSINESS_CONTACT_REQUEST,
  };
};

const updateBusinessContactSuccess = (data) => {
  return {
    type: types.UPDATE_BUSINESS_CONTACT_SUCCESS,
    data,
  };
};

const updateBusinessContactFailure = (error) => {
  return {
    type: types.UPDATE_BUSINESS_CONTACT_FAILURE,
    error,
  };
};

export const updateBusinessContact = (token, data) => {
  return (dispatch) => {
    dispatch(updateBusinessContactRequest());
    return axios
      .put("/admin/businesscontact/", data, {
        headers: { token: token },
      })
      .then((res) => dispatch(updateBusinessContactSuccess(res.data)))
      .catch((err) => dispatch(updateBusinessContactFailure(err)));
  };
};

export const clearStatus = () => {
  return {
    type: types.CLEAR_BUSINESS_CONTACT_STATUS,
  };
};

export const setFilter = (data) => {
  return {
    type: types.SET_BUSINESS_CONTACT_FILTER,
    data: data,
  };
};

export const clearError = () => {
  return {
    type: types.CLEAR_BUSINESS_CONTACT_ERROR,
  };
};

export const changePage = (page) => {
  return {
    type: types.CHANGE_BUSINESS_CONTACT_PAGE,
    page: page,
  };
};
