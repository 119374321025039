// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// component
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import Create from "./Create";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// action
import {
  getNewsList,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/news";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const News = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.news.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const newsList = useSelector((state) => state.news.newsList.list);
  const newsCount = useSelector((state) => state.news.newsList.count);
  const isProcessing = useSelector((state) => state.news.isProcessing);
  const filter = useSelector((state) => state.news.filter);
  const error = useSelector((state) => state.news.error);
  const currentPage = useSelector((state) => state.news.currentPage);

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isNewsCreate, setNewsCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const statusDictionary = {
    default: null,
    public: true,
    closed: false,
  };

  const FIELDS = {
    tags: "tags",
    status: "status",
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      tags: elements[FIELDS.tags].value,
      is_open: statusDictionary[elements[FIELDS.status].value],
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getNewsList(token, data));
    }

    const filterData = {
      tags: elements[FIELDS.tags].value,
      status: elements[FIELDS.status].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/news/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="news_list list">
        {isNewsCreate && (
          <Modal close={() => setNewsCreate(false)}>
            <Create close={() => setNewsCreate(false)} />
          </Modal>
        )}
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>タグ</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.tags}
                    type="text"
                    defaultValue={filter.tags}
                  />
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="public"
                      defaultChecked={filter.status === "public"}
                    />
                    公開
                  </label>

                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="closed"
                      defaultChecked={filter.status === "closed"}
                    />
                    非公開
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="default"
                      defaultChecked={filter.status === "default"}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={filter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="created_at">作成日時</option>
                    <option value="posted_at">掲載日付</option>
                    <option value="">なし</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setNewsCreate(true)}
                disabled={isProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="title">タイトル</th>
                <th className="description">説明</th>
                <th className="url">タグ</th>
                <th className="status">ステータス</th>
                <th className="create">作成日時</th>
                <th className="post">掲載日付</th>
              </tr>
            </thead>
            <tbody>
              {newsList.map((news) => (
                <NewsItem news={news} selectItem={selectItem} key={news.id} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(newsCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
    </>
  );
};

export default News;

const NewsItem = (props) => {
  const { id, title, description, tags, posted_at, created_at, is_open } =
    props.news;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="title">
        <div className="inner">{title}</div>
      </td>
      <td className="description">
        <div className="inner">{description}</div>
      </td>
      <td className="tags">{tags.join(",")}</td>
      <td className="status">{is_open ? "公開" : "非公開"}</td>
      <td className="create">{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
      <td className="post">{dateToString(posted_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
