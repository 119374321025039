// libs
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// component
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import ReviewCreate from "./ReviewCreate";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";
import { convertProductGroup } from "utils/product";

// action
import {
  getReviews,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/review";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const Review = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.review.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const reviewList = useSelector((state) => state.review.reviewList.list);
  const reviewCount = useSelector((state) => state.review.reviewList.count);
  const isProcessing = useSelector((state) => state.review.isProcessing);
  const filter = useSelector((state) => state.review.filter);
  const error = useSelector((state) => state.review.error);
  const currentPage = useSelector((state) => state.review.currentPage);

  const [isReviewCreate, setReviewCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const FIELDS = {
    name: "name",
    score: "score",
    isOpenBR: "is_open_br",
    isOpenLP: "is_open_lp",
    isChecked: "is_checked",
    sortBy: "sort_by",
    sortType: "sort_type",
    productGroup: "product_group",
  };

  const PUBLIC_STATUS = {
    default: "",
    public: "true",
    closed: "false",
  };

  const CHECK_STATUS = {
    default: "",
    checked: "true",
    unchecked: "false",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    search((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSearchFilter();
    dispatch(changePage(1));
    search(0);
  };

  const search = (offset) => {
    const data = {
      name: elements[FIELDS.name].value,
      score: elements[FIELDS.score].value,
      is_open: elements[FIELDS.isOpenBR].value,
      is_open_lp: elements[FIELDS.isOpenLP].value,
      is_checked: elements[FIELDS.isChecked].value,
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      product_group: elements[FIELDS.productGroup].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getReviews(token, data));
    }
  };

  const setSearchFilter = () => {
    const filterData = {
      name: elements[FIELDS.name].value,
      score: elements[FIELDS.score].value,
      isOpenBR: elements[FIELDS.isOpenBR].value,
      isOpenLP: elements[FIELDS.isOpenLP].value,
      productGroup: elements[FIELDS.productGroup].value,
      isChecked: elements[FIELDS.isChecked].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/review/" + id);
  };

  return (
    <>
      <div className="review_list list">
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>レビュー者名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.name}
                    type="text"
                    defaultValue={filter.name}
                  />
                </td>
              </tr>
              <tr>
                <th>スコア</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.score}
                      type="radio"
                      value="1"
                      defaultChecked={filter.score === "1"}
                    />
                    1
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.score}
                      type="radio"
                      value="2"
                      defaultChecked={filter.score === "2"}
                    />
                    2
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.score}
                      type="radio"
                      value="3"
                      defaultChecked={filter.score === "3"}
                    />
                    3
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.score}
                      type="radio"
                      value="4"
                      defaultChecked={filter.score === "4"}
                    />
                    4
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.score}
                      type="radio"
                      value="5"
                      defaultChecked={filter.score === "5"}
                    />
                    5
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.score}
                      type="radio"
                      value=""
                      defaultChecked={filter.score === ""}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>サイト公開状況</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isOpenBR}
                      type="radio"
                      value={PUBLIC_STATUS.public}
                      defaultChecked={filter.isOpenBR === PUBLIC_STATUS.public}
                    />
                    公開
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isOpenBR}
                      type="radio"
                      value={PUBLIC_STATUS.closed}
                      defaultChecked={filter.isOpenBR === PUBLIC_STATUS.closed}
                    />
                    非公開
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isOpenBR}
                      type="radio"
                      value={PUBLIC_STATUS.default}
                      defaultChecked={filter.isOpenBR === PUBLIC_STATUS.default}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>LP公開状況</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isOpenLP}
                      type="radio"
                      value={PUBLIC_STATUS.public}
                      defaultChecked={filter.isOpenLP === PUBLIC_STATUS.public}
                    />
                    公開
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isOpenLP}
                      type="radio"
                      value={PUBLIC_STATUS.closed}
                      defaultChecked={filter.isOpenLP === PUBLIC_STATUS.closed}
                    />
                    非公開
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isOpenLP}
                      type="radio"
                      value={PUBLIC_STATUS.default}
                      defaultChecked={filter.isOpenLP === PUBLIC_STATUS.default}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>商品グループ</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.productGroup}
                    defaultValue={filter.productGroup}
                  >
                    <option value="mattress_original">マットレス</option>
                    <option value="box_sheets">ボックスシーツ</option>
                    <option value="">全て</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>社員確認</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isChecked}
                      type="radio"
                      value={CHECK_STATUS.checked}
                      defaultChecked={filter.isChecked === CHECK_STATUS.checked}
                    />
                    実施済
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isChecked}
                      type="radio"
                      value={CHECK_STATUS.unchecked}
                      defaultChecked={
                        filter.isChecked === CHECK_STATUS.unchecked
                      }
                    />
                    未実施
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isChecked}
                      type="radio"
                      value={CHECK_STATUS.default}
                      defaultChecked={filter.isChecked === CHECK_STATUS.default}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={filter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="score">スコア</option>
                    <option value="created_at">レビュー日時</option>
                    <option value="">なし</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input className="design_button" type="submit" value="検索"></input>
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setReviewCreate(true)}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="title">タイトル</th>
                <th className="name">氏名</th>
                <th className="score">スコア</th>
                <th className="text">内容</th>
                <th className="status">公開状況</th>
                <th className="group">商品グループ</th>
                <th className="check">社員確認</th>
                <th className="create">レビュー日時</th>
              </tr>
            </thead>
            <tbody>
              {reviewList.map((review) => (
                <ReviewItem
                  review={review}
                  selectItem={selectItem}
                  key={review.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(reviewCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isReviewCreate && (
        <Modal close={() => setReviewCreate(false)}>
          <ReviewCreate close={() => setReviewCreate(false)} />
        </Modal>
      )}
    </>
  );
};

export default Review;

const ReviewItem = (props) => {
  const {
    id,
    title,
    score,
    name,
    text,
    product_group,
    created_at,
    is_open,
    is_open_lp,
    is_checked,
  } = props.review;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="title">
        <div className="inner">{title}</div>
      </td>
      <td className="name">{name}</td>
      <td className="score">{score}</td>
      <td className="text">
        <div className="inner">{text}</div>
      </td>
      <td className="status">
        {is_open ? "サイト公開" : "サイト非公開"}
        <br />
        {is_open_lp ? "LP公開" : "LP非公開"}
      </td>
      <td className="group">{convertProductGroup(product_group)}</td>
      <td className="status">{is_checked ? "実施済" : "未実施"}</td>
      <td className="create">{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
