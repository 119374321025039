// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import ReviewEdit from "./ReviewEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import {
  getReviewInfo,
  updateReview,
  clearError,
  clearStatus,
} from "redux/actions/review";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";
import { convertProductGroup } from "utils/product";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const ReviewDetail = () => {
  const { reviewId } = useParams();
  const applicationId = applicationList.review.id;

  const [isReviewEdit, setReviewEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const review = useSelector((state) => state.review.info);
  const isUpdateComplete = useSelector(
    (state) => state.review.isUpdateComplete
  );
  const error = useSelector((state) => state.review.error);
  const isProcessing = useSelector((state) => state.review.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const handleReviewCheck = () => {
    if (window.confirm("社員チェック済みに変更しますか？")) {
      dispatch(updateReview(token, { review_id: reviewId, is_checked: true }));
    }
  };

  const handleChangeOpen = () => {
    const message = review.is_open
      ? "サイトで非公開にしますか？"
      : "サイトで公開しますか？";
    if (window.confirm(message)) {
      dispatch(
        updateReview(token, { review_id: reviewId, is_open: !review.is_open })
      );
    }
  };

  const handleChangeOpenLP = () => {
    const message = review.is_open_lp
      ? "LPで非公開にしますか？"
      : "LPで公開しますか？";
    if (window.confirm(message)) {
      dispatch(
        updateReview(token, {
          review_id: reviewId,
          is_open_lp: !review.is_open_lp,
        })
      );
    }
  };

  const fetchReview = () => {
    if (token) {
      dispatch(getReviewInfo(token, reviewId));
    }
  };

  useEffect(() => {
    fetchReview();
  }, []);

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("更新しました");
      dispatch(clearStatus());
    }
  }, [isUpdateComplete]);

  return (
    <>
      <div className="review_detail detail">
        <div className="list_back_container">
          <Link to="/review">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>タイトル</th>
              <td>{review.title}</td>
            </tr>
            <tr>
              <th>レビュー者名</th>
              <td>{review.name}</td>
            </tr>
            <tr>
              <th>スコア</th>
              <td>{review.score}</td>
            </tr>
            <tr>
              <th>内容</th>
              <td>
                <div
                  style={{ marginTop: "2vh", marginBottom: "2vh" }}
                  dangerouslySetInnerHTML={{
                    __html: review.text.replace(/\n/g, "<br>"),
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>レビュー日時</th>
              <td>{dateToString(review.created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>公開状況</th>
              <td>
                {review.is_open ? "サイト公開" : "サイト非公開"}・
                {review.is_open_lp ? "LP公開" : "LP非公開"}
              </td>
            </tr>
            <tr>
              <th>社員確認</th>
              <td>{review.is_checked ? "実施済" : "未実施"}</td>
            </tr>
            <tr>
              <th>商品グループ</th>
              <td>{convertProductGroup(review.product_group)}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && !review.is_checked && (
            <button
              className="design_button"
              onClick={handleReviewCheck}
              disabled={isProcessing}
            >
              社員チェック
            </button>
          )}
          {writeIsAllowed && (
            <button
              className="design_button"
              onClick={handleChangeOpen}
              disabled={isProcessing}
            >
              {review.is_open ? "サイト非公開" : "サイト公開"}
            </button>
          )}
          {writeIsAllowed && (
            <button
              className="design_button"
              onClick={handleChangeOpenLP}
              disabled={isProcessing}
            >
              {review.is_open_lp ? "LP非公開" : "LP公開"}
            </button>
          )}
          {writeIsAllowed && (
            <button
              className="design_button"
              onClick={() => setReviewEdit(true)}
              disabled={isProcessing}
            >
              編集
            </button>
          )}
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isReviewEdit && (
        <Modal close={() => setReviewEdit(false)}>
          <ReviewEdit
            review={review}
            close={() => {
              setReviewEdit(false);
              fetchReview();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ReviewDetail;
