import * as types from "../types/notice";

const noticeInfoInitialState = {
	id: "",
	text: "",
	created_at: "",
	is_open: "",
};

const filterInitialState = {
	name: "",
	score: "",
	status: "default",
	sortBy: "created_at",
	sortType: "desc",
};

const initialState = {
	info: noticeInfoInitialState,
	noticeList: {
		list: [],
		count: 0,
	},
	isProcessing: false,
	filter: filterInitialState,
	isUpdateComplete: false,
	isCreateComplete: false,
	currentPage: 1,
	error: null,
};

const notice = (state = initialState, action) => {
	switch (action.type) {
		// お知らせ一覧取得
		case types.GET_NOTICES_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_NOTICES_SUCCESS:
			return {
				...state,
				isProcessing: false,
				noticeList: {
					list: action.data.notice_list,
					count: action.data.count,
				},
			};
		case types.GET_NOTICES_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// お知らせ情報取得
		case types.GET_NOTICE_INFO_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_NOTICE_INFO_SUCCESS:
			return {
				...state,
				isProcessing: false,
				info: action.data.notice,
			};
		case types.GET_NOTICE_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// お知らせ作成
		case types.CREATE_NOTICE_REQUEST:
			return { ...state, isProcessing: true };
		case types.CREATE_NOTICE_SUCCESS:
			return { ...state, isProcessing: false, isCreateComplete: true };
		case types.CREATE_NOTICE_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// お知らせ編集
		case types.UPDATE_NOTICE_REQUEST:
			return { ...state, isProcessing: true };
		case types.UPDATE_NOTICE_SUCCESS:
			return { ...state, isProcessing: false, isUpdateComplete: true };
		case types.UPDATE_NOTICE_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		case types.CLEAR_NOTICE_STATUS:
			return { ...state, isUpdateComplete: false, isCreateComplete: false };
		case types.SET_NOTICE_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_NOTICE_FILTER:
			return { ...state, filter: filterInitialState };
		case types.CLEAR_NOTICE_ERROR:
			return { ...state, error: null };
		case types.CHANGE_NOTICE_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default notice;
