// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// component
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import ToggleButton from "components/commons/ToggleButton";
import StockCreate from "./StockCreate";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";
import { convertStatus } from "utils/stock";

// action
import {
  getStocks,
  setFilter,
  clearError,
  clearStatus,
  changePage,
  updateStockActive,
} from "redux/actions/stock";

// constants
import { applicationList } from "constants/application";
import { productList, getProductByID } from "constants/products";

// style
import "./style.scss";

const Stock = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.stock.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const stockList = useSelector((state) => state.stock.stockList.list);
  const stockCount = useSelector((state) => state.stock.stockList.count);
  const isProcessing = useSelector((state) => state.stock.isProcessing);
  const filter = useSelector((state) => state.stock.filter);
  const error = useSelector((state) => state.stock.error);
  const currentPage = useSelector((state) => state.stock.currentPage);
  const isCreateComplete = useSelector((state) => state.stock.isCreateComplete);
  const isUpdateComplete = useSelector((state) => state.stock.isUpdateComplete);

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isStockCreate, setStockCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const stockDictionary = {
    enabled: true,
    disabled: false,
    all: null,
  };

  const FIELDS = {
    productID: "product_id",
    warehousingID: "warehousing_id",
    warehousingDateStart: "warehousing_date_start",
    warehousingDateEnd: "warehousing_date_end",
    sortBy: "sort_by",
    sortType: "sort_type",
    isActive: "is_active",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      product_id: elements[FIELDS.productID].value,
      warehousing_id: elements[FIELDS.warehousingID].value,
      warehousing_date_start: elements[FIELDS.warehousingDateStart].value,
      warehousing_date_end: elements[FIELDS.warehousingDateEnd].value,
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      is_active: elements[FIELDS.isActive].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getStocks(token, data));
    }

    const filterData = {
      productID: elements[FIELDS.productID].value,
      warehousingID: elements[FIELDS.warehousingID].value,
      warehousingDateStart: elements[FIELDS.warehousingDateStart].value,
      warehousingDateEnd: elements[FIELDS.warehousingDateEnd].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
      isActive: elements[FIELDS.isActive].value,
    };

    dispatch(setFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/stock/" + id);
  };

  const onChargeForActiveToggle = (id, is_active) => {
    const data = {
      stock_id: id,
      is_active: !is_active,
    };

    if (token) {
      dispatch(updateStockActive(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      search(pageOffset);
    }
  }, [isCreateComplete]);

  useEffect(() => {
    if (isUpdateComplete) {
      dispatch(clearStatus());
    }
  }, [isUpdateComplete]);

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="stock_list list">
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>商品名</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.productID}
                    defaultValue={filter.productID}
                  >
                    <option value="">指定なし</option>
                    {productList.map((mattress) => (
                      <option
                        value={mattress.productID}
                        key={mattress.productID}
                      >
                        {mattress.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>入庫ID</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.warehousingID}
                    type="text"
                    defaultValue={filter.warehousingID}
                  />
                </td>
              </tr>
              <tr>
                <th>入庫日</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.warehousingDateStart}
                    type="date"
                    defaultValue={filter.warehousingDateStart}
                    style={{ width: "170px", marginRight: "10px" }}
                  />
                  〜
                  <input
                    className="design_input"
                    name={FIELDS.warehousingDateEnd}
                    type="date"
                    defaultValue={filter.warehousingDateEnd}
                    style={{ width: "170px", marginLeft: "10px" }}
                  />
                </td>
              </tr>
              <tr>
                <th>有効・無効</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isActive}
                      type="radio"
                      value={true}
                      defaultChecked={filter.isActive === true}
                    />
                    有効
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isActive}
                      type="radio"
                      value={false}
                      defaultChecked={filter.isActive === false}
                    />
                    無効
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.isActive}
                      type="radio"
                      value=""
                      defaultChecked={filter.isActive === ""}
                    />
                    全て
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={filter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="">なし</option>
                    <option value="created_at">作成日</option>
                    <option value="warehousing_date">入庫日</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setStockCreate(true)}
                disabled={isProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="name">商品名</th>
                <th className="warehousing_id">入庫ID</th>
                <th className="warehousing_date">入庫日</th>
                <th className="stock">
                  在庫数
                  <br />
                  <span style={{ fontSize: "0.8rem" }}> (初期入庫数)</span>
                </th>
                <th className="is_active">ステータス</th>
                <th className="created_at">作成日</th>
                <th className="is_active">有効・無効</th>
              </tr>
            </thead>
            <tbody>
              {stockList.map((stock) => (
                <StockItem
                  stock={stock}
                  selectItem={selectItem}
                  onChargeForActiveToggle={onChargeForActiveToggle}
                  key={stock.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(stockCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isStockCreate && (
        <Modal close={() => setStockCreate(false)}>
          <StockCreate close={() => setStockCreate(false)} />
        </Modal>
      )}
    </>
  );
};

export default Stock;

const StockItem = (props) => {
  const {
    id,
    product_id,
    initial_quantity,
    quantity,
    warehousing_date,
    warehousing_id,
    arrival_status,
    created_at,
    is_active,
  } = props.stock;

  const { selectItem, onChargeForActiveToggle } = props;

  const isProcessing = useSelector((state) => state.stock.isProcessing);

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="name">{getProductByID(product_id).name}</td>
      <td className="warehousing_id">
        {warehousing_id ? warehousing_id : "-"}
      </td>
      <td className="warehousing_date">
        {warehousing_date && dateToString(warehousing_date, "yyyy/MM/dd")}
      </td>
      <td className="stock">
        {quantity}
        <br />
        <span style={{ fontSize: "0.8rem" }}>
          ({initial_quantity ? initial_quantity : "未設定"})
        </span>
      </td>
      <td className="arrival_status">{convertStatus(arrival_status)}</td>
      <td className="created_at">
        {created_at && dateToString(created_at, "yyyy/MM/dd HH:mm")}
      </td>
      <td className="control_col" onClick={(e) => e.stopPropagation()}>
        <div>{is_active ? "有効" : "無効"}</div>
        <ToggleButton
          onChange={() => onChargeForActiveToggle(id, is_active)}
          checked={is_active}
          disabled={isProcessing}
        />
      </td>
    </tr>
  );
};
