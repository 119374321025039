// libs
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// component
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";

// action
import {
  getBusinessContacts,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/businessContact";


// style
import "./style.scss";

const BusinessContact = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;

  const token = useSelector((state) => state.auth.token);
  const businessContactList = useSelector((state) => state.businessContact.businessContactList.list);
  const businessContactCount = useSelector((state) => state.businessContact.businessContactList.count);
  const isProcessing = useSelector((state) => state.businessContact.isProcessing);
  const filter = useSelector((state) => state.businessContact.filter);
  const error = useSelector((state) => state.businessContact.error);
  const currentPage = useSelector((state) => state.businessContact.currentPage);

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FIELDS = {
    mail: "mail",
    name: "name",
    corporation: "corporation",
    status: "status",
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const search = (offset) => {
    const data = {
      name: elements[FIELDS.name].value,
      mail: elements[FIELDS.mail].value,
      corporation: elements[FIELDS.corporation].value,
      status: elements[FIELDS.status].value,
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getBusinessContacts(token, data));
    }

    const filterData = {
      name: elements[FIELDS.name].value,
      mail: elements[FIELDS.mail].value,
      corporation: elements[FIELDS.corporation].value,
      status: elements[FIELDS.status].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFilter(filterData));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const selectItem = (businessContactId) => {
    navigate("/businesscontact/" + businessContactId);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="business_contact_list list">
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>担当者名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.name}
                    type="text"
                    defaultValue={filter.name}
                  />
                </td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.mail}
                    type="text"
                    defaultValue={filter.mail}
                  />
                </td>
              </tr>
              <tr>
                <th>会社名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.corporation}
                    type="text"
                    defaultValue={filter.corporation}
                  />
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="pending"
                      defaultChecked={filter.status === "pending"}
                    />
                    未対応
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="in_progress"
                      defaultChecked={filter.status === "in_progress"}
                    />
                    対応中
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="delivered"
                      defaultChecked={filter.status === "delivered"}
                    />
                    納品済み
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="no_transaction"
                      defaultChecked={filter.status === "no_transaction"}
                    />
                    取引なし
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value=""
                      defaultChecked={filter.status === ""}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={filter.sortBy}
                  >
                    <option value="created_at">作成日時</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="id">ID</th>
                <th className="name">担当者名</th>
                <th className="corporation">会社名</th>
                <th className="mail">メールアドレス</th>
                <th className="status">ステータス</th>
                <th className="create">作成日時</th>
              </tr>
            </thead>
            <tbody>
              {businessContactList.map((businesssContact) => (
                <BusinessContactItem
                  businesssContact={businesssContact}
                  selectItem={selectItem}
                  key={businesssContact.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(businessContactCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
    </>
  );
};

export default BusinessContact;

const BusinessContactItem = (props) => {
  const {
    id,
    name,
    corporation,
    mail,
    status,
    created_at,
  } = props.businesssContact;

  const { selectItem } = props;

  const BUSINESS_CONTACT_STATUS = {
    pending: "未対応",
    in_progress: "対応中",
    delivered: "納品済み",
    no_transaction: "取引なし"
  }

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="id">{id}</td>
      <td className="name">{name}</td>
      <td className="corporation">{corporation}</td>
      <td className="mail">{mail}</td>
      <td className="status">{BUSINESS_CONTACT_STATUS[status]}</td>
      <td className="create">{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
