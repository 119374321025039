import * as types from "../types/stock";

const stockInfoInitialState = {
  stock_id: "",
  product_id: "",
	initial_quantity: "",
  stock: "",
  warehousing: "",
  warehousing_id: "",
  is_active: "",
  created_at: "",
  warehouse: "",
};

const filterInitialState = {
  productID: "",
  warehousingID: "",
  warehousingDateStart: "",
  warehousingDateEnd: "",
  sortBy: "created_at",
  sortType: "desc",
  isActive: "",
};

const initialState = {
  filter: filterInitialState,
  info: stockInfoInitialState,
  currentPage: 1,
  stockList: {
    list: [],
    count: 0,
  },
  isProcessing: false,
  isUpdateComplete: false,
  isCreateComplete: false,
  isDeleteComplete: false,
  isOnlyIsActiveUpdate: false,
  error: null,
};

const stock = (state = initialState, action) => {
  switch (action.type) {
    // 在庫一覧取得
    case types.GET_STOCKS_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_STOCKS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        stockList: {
          list: action.data.stock_list,
          count: action.data.count,
        },
      };
    case types.GET_STOCKS_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 在庫情報取得
    case types.GET_STOCK_INFO_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_STOCK_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        info: action.data.stock,
      };
    case types.GET_STOCK_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 在庫情報作成
    case types.CREATE_STOCK_REQUEST:
      return { ...state, isProcessing: true };
    case types.CREATE_STOCK_SUCCESS:
      return { ...state, isProcessing: false, isCreateComplete: true };
    case types.CREATE_STOCK_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 在庫情報編集
    case types.UPDATE_STOCK_REQUEST:
      return { ...state, isProcessing: true };
    case types.UPDATE_STOCK_SUCCESS:
      return { ...state, isProcessing: false, isUpdateComplete: true }
    case types.UPDATE_STOCK_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 在庫情報編集（is_activeのみの編集）
    case types.UPDATE_STOCK_ACTIVE_REQUEST:
      return { ...state, isProcessing: true };
    case types.UPDATE_STOCK_ACTIVE_SUCCESS:
      const stockListList = state.stockList.list.map((stock) => {
        if (stock.id === action.data.id) {
          return {
            ...stock,
            is_active: action.data.is_active,
          };
        }
        return stock;
      });
      return { ...state, isProcessing: false, isUpdateComplete: true, stockList: { ...state.stockList, list: stockListList } }
    case types.UPDATE_STOCK_ACTIVE_FAILURE:
      return { ...state, isProcessing: false, error: action.error };
    
    // 在庫情報削除
    case types.DELETE_STOCK_REQUEST:
      return { ...state, isProcessing: true };
    case types.DELETE_STOCK_SUCCESS:
      return { ...state, isProcessing: false, isDeleteComplete: true };
    case types.DELETE_STOCK_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    case types.CLEAR_STOCK_STATUS:
      return { ...state, isUpdateComplete: false, isCreateComplete: false, isDeleteComplete: false, isOnlyIsActiveUpdate: false };
    case types.SET_STOCK_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_STOCK_FILTER:
      return { ...state, filter: filterInitialState };
    case types.CLEAR_STOCK_ERROR:
      return { ...state, error: null };
    case types.CHANGE_STOCK_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default stock;
