import axios from "../network";
import * as types from "../types/adminUser";
import { sha256 } from "../../utils/hash";

const getAdminUsersRequest = () => {
  return {
    type: types.GET_ADMIN_USERS_REQUEST,
  };
};

const getAdminUsersSuccess = (data) => {
  return {
    type: types.GET_ADMIN_USERS_SUCCESS,
    data,
  };
};

const getAdminUsersFailure = (error) => {
  return {
    type: types.GET_ADMIN_USERS_FAILURE,
    error,
  };
};

export const getAdminUsers = (token, data) => {
  return (dispatch) => {
    dispatch(getAdminUsersRequest());
    return axios
      .get("/admin/adminuser/list/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => {
        return dispatch(getAdminUsersSuccess(res.data));
      })
      .catch((err) => dispatch(getAdminUsersFailure(err)));
  };
};

const getAdminUserInfoRequest = () => {
  return {
    type: types.GET_ADMIN_USER_INFO_REQUEST,
  };
};

const getAdminUserInfoSuccess = (data) => {
  return {
    type: types.GET_ADMIN_USER_INFO_SUCCESS,
    data,
  };
};

const getAdminUserInfoFailure = (error) => {
  return {
    type: types.GET_ADMIN_USER_INFO_FAILURE,
    error,
  };
};

export const getAdminUserInfo = (token, adminUserId) => {
  return (dispatch) => {
    dispatch(getAdminUserInfoRequest());
    return axios
      .get("/admin/adminuser/", {
        headers: { token: token },
        params: { admin_user_id: adminUserId },
        data: {},
      })
      .then((res) => {
        return dispatch(getAdminUserInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getAdminUserInfoFailure(err)));
  };
};

const createAdminUserRequest = () => {
  return {
    type: types.CREATE_ADMIN_USER_REQUEST,
  };
};

const createAdminUserSuccess = (data) => {
  return {
    type: types.CREATE_ADMIN_USER_SUCCESS,
    data,
  };
};

const createAdminUserFailure = (error) => {
  return {
    type: types.CREATE_ADMIN_USER_FAILURE,
    error,
  };
};

export const createAdminUser = (token, data) => {
  return (dispatch) => {
    dispatch(createAdminUserRequest());
    return axios
      .post(
        "/admin/adminuser/",
        {
          admin_user_id: data.id,
          name: data.name,
          password: sha256(data.password),
          permission_group_id: data.permission_group_id,
        },
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        return dispatch(createAdminUserSuccess(res.data));
      })
      .catch((err) => dispatch(createAdminUserFailure(err)));
  };
};

const updateAdminUserRequest = () => {
  return {
    type: types.UPDATE_ADMIN_USER_REQUEST,
  };
};

const updateAdminUserSuccess = (data) => {
  return {
    type: types.UPDATE_ADMIN_USER_SUCCESS,
    data,
  };
};

const updateAdminUserFailure = (error) => {
  return {
    type: types.UPDATE_ADMIN_USER_FAILURE,
    error,
  };
};

export const updateAdminUser = (token, data) => {
  return (dispatch) => {
    dispatch(updateAdminUserRequest());
    return axios
      .put("/admin/adminuser/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(updateAdminUserSuccess(res.data));
      })
      .catch((err) => dispatch(updateAdminUserFailure(err)));
  };
};

const deleteAdminUserRequest = () => {
  return {
    type: types.DELETE_ADMIN_USER_REQUEST,
  };
};

const deleteAdminUserSuccess = (data) => {
  return {
    type: types.DELETE_ADMIN_USER_SUCCESS,
    data,
  };
};

const deleteAdminUserFailure = (error) => {
  return {
    type: types.DELETE_ADMIN_USER_FAILURE,
    error,
  };
};

export const deleteAdminUser = (token, data) => {
  return (dispatch) => {
    dispatch(deleteAdminUserRequest());
    return axios
      .post("/admin/adminuser/delete/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(deleteAdminUserSuccess(res.data));
      })
      .catch((err) => dispatch(deleteAdminUserFailure(err)));
  };
};

const lockoutCancelRequest = () => {
  return {
    type: types.LOCKOUT_CANCEL_REQUEST,
  };
};

const lockoutCancelSuccess = (data) => {
  return {
    type: types.LOCKOUT_CANCEL_SUCCESS,
    data,
  };
};

const lockoutCancelFailure = (error) => {
  return {
    type: types.LOCKOUT_CANCEL_FAILURE,
    error,
  };
};

export const lockoutCancel = (token, userID) => {
  return (dispatch) => {
    dispatch(lockoutCancelRequest());
    return axios
      .post(
        `/admin/adminuser/${userID}/unlock/`,
        {},
        {
          headers: { token: token },
        }
      )
      .then((res) => dispatch(lockoutCancelSuccess(res.data)))
      .catch((err) => dispatch(lockoutCancelFailure(err)));
  };
};

export const adminUserClearStatus = () => {
  return {
    type: types.CLEAR_ADMIN_USER_STATUS,
  };
};

export const adminUserSetFilter = (data) => {
  return {
    type: types.SET_ADMIN_USER_FILTER,
    data: data,
  };
};

export const adminUserClearFilter = () => {
  return {
    type: types.CLEAR_ADMIN_USER_FILTER,
  };
};

export const adminUserClearError = () => {
  return {
    type: types.CLEAR_ADMIN_USER_ERROR,
  };
};

export const adminUserChangePage = (page) => {
  return {
    type: types.CHANGE_ADMIN_USER_PAGE,
    page: page,
  };
};

const getPermissionGroupsRequest = () => {
  return {
    type: types.GET_PERMISSION_GROUPS_REQUEST,
  };
};

const getPermissionGroupsSuccess = (data) => {
  return {
    type: types.GET_PERMISSION_GROUPS_SUCCESS,
    data,
  };
};

const getPermissionGroupsFailure = (error) => {
  return {
    type: types.GET_PERMISSION_GROUPS_FAILURE,
    error,
  };
};

export const getPermissionGroups = (token, data) => {
  return (dispatch) => {
    dispatch(getPermissionGroupsRequest());
    return axios
      .get("/admin/adminuser/permissiongroup/list/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => {
        return dispatch(getPermissionGroupsSuccess(res.data));
      })
      .catch((err) => dispatch(getPermissionGroupsFailure(err)));
  };
};

const getPermissionGroupInfoRequest = () => {
  return {
    type: types.GET_PERMISSION_GROUP_INFO_REQUEST,
  };
};

const getPermissionGroupInfoSuccess = (data) => {
  return {
    type: types.GET_PERMISSION_GROUP_INFO_SUCCESS,
    data,
  };
};

const getPermissionGroupInfoFailure = (error) => {
  return {
    type: types.GET_PERMISSION_GROUP_INFO_FAILURE,
    error,
  };
};

export const getPermissionGroupInfo = (token, permissionGroupId) => {
  return (dispatch) => {
    dispatch(getPermissionGroupInfoRequest());
    return axios
      .get("/admin/adminuser/permissiongroup/", {
        headers: { token: token },
        params: { permission_group_id: permissionGroupId },
        data: {},
      })
      .then((res) => {
        return dispatch(getPermissionGroupInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getPermissionGroupInfoFailure(err)));
  };
};

const createPermissionGroupRequest = () => {
  return {
    type: types.CREATE_PERMISSION_GROUP_REQUEST,
  };
};

const createPermissionGroupSuccess = (data) => {
  return {
    type: types.CREATE_PERMISSION_GROUP_SUCCESS,
    data,
  };
};

const createPermissionGroupFailure = (error) => {
  return {
    type: types.CREATE_PERMISSION_GROUP_FAILURE,
    error,
  };
};

export const createPermissionGroup = (token, data) => {
  return (dispatch) => {
    dispatch(createPermissionGroupRequest());
    return axios
      .post(
        "/admin/adminuser/permissiongroup/",
        {
          name: data.name,
          permission_list: data.permission_list,
        },
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        return dispatch(createPermissionGroupSuccess(res.data));
      })
      .catch((err) => dispatch(createPermissionGroupFailure(err)));
  };
};

const updatePermissionGroupRequest = () => {
  return {
    type: types.UPDATE_PERMISSION_GROUP_REQUEST,
  };
};

const updatePermissionGroupSuccess = (data) => {
  return {
    type: types.UPDATE_PERMISSION_GROUP_SUCCESS,
    data,
  };
};

const updatePermissionGroupFailure = (error) => {
  return {
    type: types.UPDATE_PERMISSION_GROUP_FAILURE,
    error,
  };
};

export const updatePermissionGroup = (token, data) => {
  return (dispatch) => {
    dispatch(updatePermissionGroupRequest());
    return axios
      .put("/admin/adminuser/permissiongroup/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(updatePermissionGroupSuccess(res.data));
      })
      .catch((err) => dispatch(updatePermissionGroupFailure(err)));
  };
};

const deletePermissionGroupRequest = () => {
  return {
    type: types.DELETE_PERMISSION_GROUP_REQUEST,
  };
};

const deletePermissionGroupSuccess = (data) => {
  return {
    type: types.DELETE_PERMISSION_GROUP_SUCCESS,
    data,
  };
};

const deletePermissionGroupFailure = (error) => {
  return {
    type: types.DELETE_PERMISSION_GROUP_FAILURE,
    error,
  };
};

export const deletePermissionGroup = (token, data) => {
  return (dispatch) => {
    dispatch(deletePermissionGroupRequest());
    return axios
      .post("/admin/adminuser/permissiongroup/delete/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(deletePermissionGroupSuccess(res.data));
      })
      .catch((err) => dispatch(deletePermissionGroupFailure(err)));
  };
};

export const permissionGroupClearStatus = () => {
  return {
    type: types.CLEAR_PERMISSION_GROUP_STATUS,
  };
};

export const permissionGroupSetFilter = (data) => {
  return {
    type: types.SET_PERMISSION_GROUP_FILTER,
    data: data,
  };
};

export const permissionGroupClearFilter = () => {
  return {
    type: types.CLEAR_PERMISSION_GROUP_FILTER,
  };
};

export const permissionGroupClearError = () => {
  return {
    type: types.CLEAR_PERMISSION_GROUP_ERROR,
  };
};

export const permissionGroupChangePage = (page) => {
  return {
    type: types.CHANGE_PERMISSION_GROUP_PAGE,
    page: page,
  };
};
