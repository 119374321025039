import axios from "../network";
import * as types from "../types/coupon";

const getCouponsRequest = () => {
	return {
		type: types.GET_COUPONS_REQUEST,
	};
};

const getCouponsSuccess = (data) => {
	return {
		type: types.GET_COUPONS_SUCCESS,
		data,
	};
};

const getCouponsFailure = (error) => {
	return {
		type: types.GET_COUPONS_FAILURE,
		error,
	};
};

export const getCoupons = (token, data) => {
	return (dispatch) => {
		dispatch(getCouponsRequest());
		return axios
			.get("/admin/coupon/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getCouponsSuccess(res.data));
			})
			.catch((err) => dispatch(getCouponsFailure(err)));
	};
};

const getCouponInfoRequest = () => {
	return {
		type: types.GET_COUPON_INFO_REQUEST,
	};
};

const getCouponInfoSuccess = (data) => {
	return {
		type: types.GET_COUPON_INFO_SUCCESS,
		data,
	};
};

const getCouponInfoFailure = (error) => {
	return {
		type: types.GET_COUPON_INFO_FAILURE,
		error,
	};
};

export const getCouponInfo = (token, couponCode) => {
	return (dispatch) => {
		dispatch(getCouponInfoRequest());
		return axios
			.get("/admin/coupon/", {
				headers: { token: token },
				params: { coupon_code: couponCode },
				data: {},
			})
			.then((res) => {
				return dispatch(getCouponInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getCouponInfoFailure(err)));
	};
};

const getCouponOrdersRequest = () => {
	return {
		type: types.GET_COUPON_ORDERS_REQUEST,
	};
};

const getCouponOrdersSuccess = (data) => {
	return {
		type: types.GET_COUPON_ORDERS_SUCCESS,
		data,
	};
};

const getCouponOrdersFailure = (error) => {
	return {
		type: types.GET_COUPON_ORDERS_FAILURE,
		error,
	};
};

export const getCouponOrders = (token, couponCode, month) => {
	return (dispatch) => {
		dispatch(getCouponOrdersRequest());
		return axios
			.get("/admin/coupon/order/list/", {
				headers: { token: token },
				params: { coupon_code: couponCode, order_month: month },
				data: {},
			})
			.then((res) => {
				return dispatch(getCouponOrdersSuccess(res.data));
			})
			.catch((err) => dispatch(getCouponOrdersFailure(err)));
	};
};

const createCouponRequest = () => {
	return {
		type: types.CREATE_COUPON_REQUEST,
	};
};

const createCouponSuccess = (data) => {
	return {
		type: types.CREATE_COUPON_SUCCESS,
		data,
	};
};

const createCouponFailure = (error) => {
	return {
		type: types.CREATE_COUPON_FAILURE,
		error,
	};
};

export const createCoupon = (token, data) => {
	return (dispatch) => {
		dispatch(createCouponRequest());
		return axios
			.post("/admin/coupon/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createCouponSuccess(res.data));
			})
			.catch((err) => dispatch(createCouponFailure(err)));
	};
};

const updateCouponRequest = () => {
	return {
		type: types.UPDATE_COUPON_REQUEST,
	};
};

const updateCouponSuccess = (data) => {
	return {
		type: types.UPDATE_COUPON_SUCCESS,
		data,
	};
};

const updateCouponFailure = (error) => {
	return {
		type: types.UPDATE_COUPON_FAILURE,
		error,
	};
};

export const updateCoupon = (token, data) => {
	return (dispatch) => {
		dispatch(updateCouponRequest());
		return axios
			.put("/admin/coupon/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateCouponSuccess(res.data));
			})
			.catch((err) => dispatch(updateCouponFailure(err)));
	};
};

const adaptCouponRequest = () => {
	return {
		type: types.ADAPT_COUPON_REQUEST,
	};
};

const adaptCouponSuccess = (data) => {
	return {
		type: types.ADAPT_COUPON_SUCCESS,
		data,
	};
};

const adaptCouponFailure = (error) => {
	return {
		type: types.ADAPT_COUPON_FAILURE,
		error,
	};
};

export const adaptCoupon = (token, data) => {
	return (dispatch) => {
		dispatch(adaptCouponRequest());
		return axios
			.post("/admin/coupon/adapt/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(adaptCouponSuccess(res.data));
			})
			.catch((err) => dispatch(adaptCouponFailure(err)));
	};
};

const getCouponAdaptRequest = () => {
	return {
		type: types.GET_COUPON_ADAPT_REQUEST,
	};
};

const getCouponAdaptSuccess = (data) => {
	return {
		type: types.GET_COUPON_ADAPT_SUCCESS,
		data,
	};
};

const getCouponAdaptFailure = (error) => {
	return {
		type: types.GET_COUPON_ADAPT_FAILURE,
		error,
	};
};

export const getCouponAdapt = (token, data) => {
	return (dispatch) => {
		dispatch(getCouponAdaptRequest());
		return axios
			.get("/admin/coupon/adapt/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getCouponAdaptSuccess(res.data));
			})
			.catch((err) => dispatch(getCouponAdaptFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_COUPON_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_COUPON_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_COUPON_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_COUPN_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_COUPON_PAGE,
		page: page,
	};
};
