// libs
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import Edit from "./Edit";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import { clearError, getFaqProduct } from "redux/actions/faq";

// utils
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const FaqProductDetail = () => {
  const { faqProductId } = useParams();
  const applicationId = applicationList.faq.id;

  const [isProductEdit, setProductEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const product = useSelector((state) => state.faq.productInfo);
  const error = useSelector((state) => state.faq.error);
  const isProcessing = useSelector((state) => state.faq.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchFaqProduct = () => {
    dispatch(getFaqProduct(token, faqProductId));
  };

  useEffect(() => {
    fetchFaqProduct();
  }, []);

  return (
    <>
      <div className="faq_detail detail">
        <div className="list_back_container">
          <Link to="/faq/product">一覧に戻る</Link>
        </div>
        <div className="faq_flex">
          <div className="faq_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{product.manage_id ? product.manage_id : "未設定"}</td>
                </tr>
                <tr>
                  <th>商品グループ名</th>
                  <td>{product.name}</td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{product.is_open ? "表示" : "非表示"}</td>
                </tr>
                <tr>
                  <th>表示優先度</th>
                  <td>{product.priority ? product.priority : "未設定"}</td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <button
                  className="design_button"
                  onClick={() => setProductEdit(true)}
                  disabled={isProcessing}
                >
                  編集
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isProductEdit && (
        <Modal close={() => setProductEdit(false)}>
          <Edit
            product={product}
            close={() => {
              setProductEdit(false);
              fetchFaqProduct();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default FaqProductDetail;
