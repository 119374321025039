// styles
import "./style.scss";

const ToggleButton = ({ onChange, checked, disabled }) => {
  return (
    <input
      type="checkbox"
      className="toggle_button"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  );
};

export default ToggleButton;
