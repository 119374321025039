import * as types from "../types/order";

const orderInfoInitialState = {
  order_number: "",
  order_status: "",
  customer_name: "",
  customer_phone: "",
  postal_code: "",
  province: "",
  city: "",
  address_line: "",
  product_list: [],
  coupon_code: "",
  total: "",
  carriage: "",
  tax: "",
  discount: "",
  sub_total: "",
  method: "",
  user_id: "",
  order_at: "",
  history_list: [],
  similar_return_order_list: [],
};

const filterInitialState = {
  orderNumber: "",
  cartID: "",
  customerFullName: "",
  orderStatus: "",
  orderDateStart: "",
  orderDateEnd: "",
  okinawaIsland: false,
  sortBy: "order_at",
  sortType: "desc",
  product: "",
};

const initialState = {
  filter: filterInitialState,
  orderInfo: orderInfoInitialState,
  orderList: {
    list: [],
    count: 0,
  },
  isChangedOrder: false,
  isChangedCoupon: false,
  isCanceledOrder: false,
  isChangedProduct: false,
  isChangedShipment: false,
  isChangeStatus: false,
  isRefunded: false,
  isCanceledProduct: false,
  isSentMail: false,
  isProcessing: false,
  currentPage: 1,
  error: null,
};

const order = (state = initialState, action) => {
  switch (action.type) {
    // 注文一覧取得
    case types.GET_ORDERS_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        orderList: {
          list: action.data.order_list,
          count: action.data.count,
        },
      };
    case types.GET_ORDERS_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 注文情報取得
    case types.GET_ORDER_INFO_REQUEST:
      return {
        ...state,
        orderInfo: orderInfoInitialState,
        isProcessing: true,
      };
    case types.GET_ORDER_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        orderInfo: action.data.order,
      };
    case types.GET_ORDER_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 注文情報編集
    case types.CHANGE_ORDER_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case types.CHANGE_ORDER_SUCCESS:
      return { ...state, isProcessing: false, isChangedOrder: true };
    case types.CHANGE_ORDER_FAILURE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };

    // クーポン編集
    case types.CHANGE_COUPON_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case types.CHANGE_COUPON_SUCCESS:
      return { ...state, isProcessing: false, isChangedCoupon: true };
    case types.CHANGE_COUPON_FAILURE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };

    // 注文キャンセル
    case types.CANCEL_ORDER_REQUEST:
      return { ...state, isProcessing: true, error: null };
    case types.CANCEL_ORDER_SUCCESS:
      return { ...state, isProcessing: false, isCanceledOrder: true };
    case types.CANCEL_ORDER_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 注文ステータス変更
    case types.CHANGE_STATUS_ORDER_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case types.CHANGE_STATUS_ORDER_SUCCESS:
      return { ...state, isProcessing: false, isChangeStatus: true };
    case types.CHANGE_STATUS_ORDER_FAILURE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };

    // 返金
    case types.REFUND_ORDER_REQUEST:
      return { ...state, isProcessing: true };
    case types.REFUND_ORDER_SUCCESS:
      return { ...state, isProcessing: false, isRefunded: true };
    case types.REFUND_ORDER_FAILURE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };

    // 注文商品情報変更
    case types.CHANGE_ORDER_PRODUCT_REQUEST:
      return { ...state, isProcessing: true };
    case types.CHANGE_ORDER_PRODUCT_SUCCESS:
      return { ...state, isProcessing: false, isChangedProduct: true };
    case types.CHANGE_ORDER_PRODUCT_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 商品の配送日変更
    case types.CHANGE_ORDER_SHIPMENT_REQUEST:
      return { ...state, isProcessing: true };
    case types.CHANGE_ORDER_SHIPMENT_SUCCESS:
      return { ...state, isProcessing: false, isChangedShipment: true };
    case types.CHANGE_ORDER_SHIPMENT_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 注文確定メール送信
    case types.SEND_ORDER_MAIL_REQUEST:
      return { ...state, isProcessing: true };
    case types.SEND_ORDER_MAIL_SUCCESS:
      return { ...state, isProcessing: false, isSentMail: true };
    case types.SEND_ORDER_MAIL_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 商品ごとの返金
    case types.REFUND_PRODUCT_INFO_REQUEST:
      return { ...state, isProcessing: true };
    case types.REFUND_PRODUCT_INFO_SUCCESS:
      return { ...state, isProcessing: false, isRefunded: true };
    case types.REFUND_PRODUCT_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // 商品ごとのキャンセル
    case types.CANCEL_PRODUCT_REQUEST:
      return { ...state, isProcessing: true, error: null };
    case types.CANCEL_PRODUCT_SUCCESS:
      return { ...state, isProcessing: false, isCanceledProduct: true };
    case types.CANCEL_PRODUCT_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    case types.SET_ORDER_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_ORDER_FILTER:
      return { ...state, filter: filterInitialState };
    case types.CLEAR_ORDER_STATUS:
      return {
        ...state,
        isChangedOrder: false,
        isChangedCoupon: false,
        isCanceledOrder: false,
        isChangeStatus: false,
        isChangedProduct: false,
        isChangedShipment: false,
        isRefunded: false,
        isCanceledProduct: false,
        isSentMail: false,
      };
    case types.CLEAR_ORDER_ERROR:
      return { ...state, error: null };
    case types.CHANGE_ORDER_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default order;
