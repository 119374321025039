import axios from "../network";
import * as types from "../types/myAccount";


const getMyAccountInfoRequest = () => {
	return {
		type: types.GET_MY_ACCOUNT_INFO_REQUEST,
	};
};

const getMyAccountInfoSuccess = (data) => {
	return {
		type: types.GET_MY_ACCOUNT_INFO_SUCCESS,
		data,
	};
};

const getMyAccountInfoFailure = (error) => {
	return {
		type: types.GET_MY_ACCOUNT_INFO_FAILURE,
		error,
	};
};

export const getMyAccountInfo = (token) => {
	return (dispatch) => {
		dispatch(getMyAccountInfoRequest());
		return axios
			.get("/admin/myaccount/", {
				headers: { token: token },
				data: {},
			})
			.then((res) => {
				return dispatch(getMyAccountInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getMyAccountInfoFailure(err)));
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_MY_ACCOUNT_ERROR,
	};
};

export const clearInfo = () => {
	return {
		type: types.CLEAR_MY_ACCOUNT_INFO,
	};
};