// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createOfflineStore, clearStatus } from "redux/actions/offlineStore";

// style
import "./style.scss";

const OfflineStoreCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.offlineStore.isProcessing);
  const isCreateComplete = useSelector(
    (state) => state.offlineStore.isCreateComplete
  );
  const offlineStorePassword = useSelector(
    (state) => state.offlineStore.password
  );

  const dispatch = useDispatch();

  const FIELDS = {
    id: "id",
    name: "name",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      store_id: elements[FIELDS.id].value,
      store_name: elements[FIELDS.name].value,
    };

    if (token) {
      dispatch(createOfflineStore(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert(`作成しました。パスワード: ${offlineStorePassword}`);
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="offline_store_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <input type="text" id={FIELDS.id} className="design_input" />
              </td>
            </tr>
            <tr>
              <th>店舗名</th>
              <td>
                <input
                  type="text"
                  name={FIELDS.name}
                  className="design_input"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default OfflineStoreCreate;
