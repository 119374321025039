// libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// components
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import { login, clearError } from "redux/actions/auth";

// styles
import "./style.scss";

const Login = () => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  let elements = {};

  const FIELDS = {
    id: "id",
    password: "password",
  };

  const handleFormChange = () => {
    setSubmitEnabled();

    dispatch(clearError());
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    let id = elements[FIELDS.id].value;
    let password = elements[FIELDS.password].value;

    dispatch(login(id, password));
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    const redirectUri = query.get("redirect_uri");

    if (redirectUri) {
      navigate(redirectUri);
    } else {
      navigate("/order");
    }
  }, [token]);

  return (
    <div className="login">
      <div className="title">NELL 管理ポータル</div>
      <form
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table>
          <tbody>
            <tr>
              <th>
                <label htmlFor="login_id">ID</label>
              </th>
              <td>
                <input
                  className="design_input"
                  id="login_id"
                  type="text"
                  name={FIELDS.id}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label htmlFor="login_password">PASSWORD</label>
              </th>
              <td>
                <input
                  className="design_input"
                  id="login_password"
                  type="password"
                  name={FIELDS.password}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <input
          className="design_button"
          type="submit"
          value="ログイン"
          disabled={!isSubmitEnabled}
        />
      </form>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
    </div>
  );
};

export default Login;
