// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { getCouponAdapt } from "redux/actions/coupon";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const CouponAdaptProgress = () => {
  const token = useSelector((state) => state.auth.token);
  const couponAdapt = useSelector((state) => state.coupon.couponAdapt);

  const dispatch = useDispatch();

  const fetchCouponAdapt = () => {
    dispatch(getCouponAdapt(token));
  };

  useEffect(() => {
    fetchCouponAdapt();
  }, []);

  return (
    <div className="coupon_adapt_progress_container">
      <table className="detail_table">
        <tbody>
          <tr>
            <th>クーポンコード</th>
            <td>{couponAdapt.coupon}</td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>{couponAdapt.status}</td>
          </tr>
          <tr>
            <th>ターゲットカート数</th>
            <td>{couponAdapt.target_cart_count}</td>
          </tr>
          <tr>
            <th>適用済みカート数</th>
            <td>{couponAdapt.done_cart_count}</td>
          </tr>
          <tr>
            <th>完了予測日時</th>
            <td>
              {couponAdapt.expected_end_time &&
                dateToString(
                  couponAdapt.expected_end_time,
                  "yyyy/MM/dd HH:mm:ss"
                )}
            </td>
          </tr>
          <tr>
            <th>作業開始日時</th>
            <td>
              {couponAdapt.created_at &&
                dateToString(couponAdapt.created_at, "yyyy/MM/dd HH:mm:ss")}
            </td>
          </tr>
        </tbody>
      </table>
      <button className="design_button" onClick={() => fetchCouponAdapt()}>
        更新
      </button>
    </div>
  );
};

export default CouponAdaptProgress;
