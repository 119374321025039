import axios from "../network";
import * as types from "../types/serverStatus";

const getServerStatusRequest = () => {
	return {
		type: types.GET_SERVER_STATUS_REQUEST,
	};
};

const getServerStatusSuccess = (data) => {
	return {
		type: types.GET_SERVER_STATUS_SUCCESS,
		data,
	};
};

const getServerStatusFailure = (error) => {
	return {
		type: types.GET_SERVER_STATUS_FAILURE,
		error,
	};
};

export const getServerStatus = (token) => {
	return (dispatch) => {
		dispatch(getServerStatusRequest());
		return axios
			.get("/admin/serverstatus/", {
				headers: { token: token },
				data: {},
			})
			.then((res) => {
				return dispatch(getServerStatusSuccess(res.data));
			})
			.catch((err) => dispatch(getServerStatusFailure(err)));
	};
};

const changeServerStatusRequest = () => {
	return {
		type: types.CHANGE_SERVER_STATUS_REQUEST,
	};
};

const changeServerStatusSuccess = (data) => {
	return {
		type: types.CHANGE_SERVER_STATUS_SUCCESS,
		data,
	};
};

const changeServerStatusFailure = (error) => {
	return {
		type: types.CHANGE_SERVER_STATUS_FAILURE,
		error,
	};
};

export const changeServerStatus = (token, status, isEmergency) => {
	return (dispatch) => {
		dispatch(changeServerStatusRequest());
		return axios
			.post(
				"/admin/serverstatus/",
				{ 
					server_status: status,
					is_emergency: isEmergency
				},
				{
					headers: { token: token },
				}
			)
			.then((res) => {
				return dispatch(changeServerStatusSuccess(res.data));
			})
			.catch((err) => dispatch(changeServerStatusFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_SERVER_STATUS_STATUS,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_SERVER_STATUS_ERROR,
	};
};
