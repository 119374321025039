// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { changeOrder, clearStatus } from "redux/actions/order";

// utils
import { provinceList } from "constants/provinces";

// constants
import { ORDER_STATUS } from "constants/status";

// style
import "./style.scss";

const CustomerEdit = (props) => {
  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.order.isProcessing);
  const isChanged = useSelector((state) => state.order.isChangedOrder);
  const dispatch = useDispatch();

  // params
  const {
    order_number,
    customer_fullname,
    customer_mail,
    customer_phone,
    postal_code,
    province,
    city,
    address_line,
    route,
    order_status,
  } = props.order;

  const FIELDS = {
    customerFullName: "customer_fullname",
    customerMail: "customer_mail",
    customerPhone: "customer_phone",
    postalCode: "postal_code",
    province: "province",
    city: "city",
    addressLine: "address_line",
    route: "route",
  };

  let elements = {};

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      order_number: order_number,
      customer_fullname: elements[FIELDS.customerFullName].value,
      customer_mail: elements[FIELDS.customerMail].value,
      customer_phone: elements[FIELDS.customerPhone].value,
      postal_code: elements[FIELDS.postalCode].value,
      province: elements[FIELDS.province].value,
      city: elements[FIELDS.city].value,
      address_line: elements[FIELDS.addressLine].value,
      route: elements[FIELDS.route].value,
    };

    if (window.confirm("編集内容を確定しますか？")) {
      dispatch(changeOrder(token, data));
    }
  };

  useEffect(() => {
    if (isChanged) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isChanged]);

  return (
    <div className="order_customer_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table>
          <tbody>
            <tr>
              <th>氏名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.customerFullName}
                  defaultValue={customer_fullname}
                  placeholder="名"
                  disabled={order_status !== ORDER_STATUS.unshipped}
                />
              </td>
            </tr>
            <tr>
              <th>流入経路</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.route}
                  defaultValue={route}
                />
              </td>
            </tr>
            <tr>
              <th>メール</th>
              <td>
                <input
                  className="design_input"
                  type="email"
                  name={FIELDS.customerMail}
                  defaultValue={customer_mail}
                />
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <input
                  className="design_input"
                  type="tel"
                  name={FIELDS.customerPhone}
                  defaultValue={customer_phone}
                  disabled={order_status !== ORDER_STATUS.unshipped}
                />
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.postalCode}
                  defaultValue={postal_code}
                  disabled={order_status !== ORDER_STATUS.unshipped}
                />
              </td>
            </tr>
            <tr>
              <th>都道府県</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.province}
                  defaultValue={province}
                  disabled={order_status !== ORDER_STATUS.unshipped}
                >
                  {provinceList.map((name) => (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>市区町村/町名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.city}
                  defaultValue={city}
                  disabled={order_status !== ORDER_STATUS.unshipped}
                />
              </td>
            </tr>
            <tr>
              <th>以降の住所</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.addressLine}
                  defaultValue={address_line}
                  disabled={order_status !== ORDER_STATUS.unshipped}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default CustomerEdit;
