// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString, parseDeliveryTime } from "utils/date";

// action
import {
  getOfflineStoreOrders,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/offlineStoreOrder";

// constants
import { OFFLINE_STORE_ORDER_STATUS } from "constants/status";

// style
import "./style.scss";

const OfflineStoreOrder = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;

  const token = useSelector((state) => state.auth.token);
  const offlineStoreOrderList = useSelector(
    (state) => state.offlineStoreOrder.offlineStoreOrderList.list
  );
  const offlineStoreOrderCount = useSelector(
    (state) => state.offlineStoreOrder.offlineStoreOrderList.count
  );
  const isProcessing = useSelector(
    (state) => state.offlineStoreOrder.isProcessing
  );
  const filter = useSelector((state) => state.offlineStoreOrder.filter);
  const error = useSelector((state) => state.offlineStoreOrder.error);
  const currentPage = useSelector(
    (state) => state.offlineStoreOrder.currentPage
  );

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FIELDS = {
    slipNumber: "slip_number",
    orderNumber: "order_number",
    name: "name",
    storeName: "store_name",
    orderDateStart: "order_date_start",
    orderDateEnd: "order_date_end",
    status: "status",
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      slip_number: elements[FIELDS.slipNumber].value,
      order_number: elements[FIELDS.orderNumber].value,
      name: elements[FIELDS.name].value,
      store_name: elements[FIELDS.storeName].value,
      order_date_start: elements[FIELDS.orderDateStart].value,
      order_date_end: elements[FIELDS.orderDateEnd].value,
      status: elements[FIELDS.status].value,
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getOfflineStoreOrders(token, data));
    }

    const filterData = {
      slipNumber: elements[FIELDS.slipNumber].value,
      orderNumber: elements[FIELDS.orderNumber].value,
      name: elements[FIELDS.name].value,
      storeName: elements[FIELDS.name].value,
      orderDateStart: elements[FIELDS.orderDateStart].value,
      orderDateEnd: elements[FIELDS.orderDateEnd].value,
      status: elements[FIELDS.status].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/offline-store-order/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="offline_store_order_list list">
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>伝票番号</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.slipNumber}
                    type="text"
                    defaultValue={filter.slipNumber}
                  />
                </td>
              </tr>
              <tr>
                <th>注文番号</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.orderNumber}
                    type="text"
                    defaultValue={filter.orderNumber}
                  />
                </td>
              </tr>
              <tr>
                <th>注文者指名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.name}
                    type="text"
                    defaultValue={filter.name}
                  />
                </td>
              </tr>
              <tr>
                <th>購入店舗名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.storeName}
                    type="text"
                    defaultValue={filter.storeName}
                  />
                </td>
              </tr>
              <tr>
                <th>注文日時</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.orderDateStart}
                    type="date"
                    defaultValue={filter.orderDateStart}
                  />
                  〜
                  <input
                    className="design_input"
                    name={FIELDS.orderDateEnd}
                    type="date"
                    defaultValue={filter.orderDateEnd}
                  />
                </td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value={OFFLINE_STORE_ORDER_STATUS.include_no_shipping}
                      defaultChecked={
                        filter.status ===
                        OFFLINE_STORE_ORDER_STATUS.include_no_shipping
                      }
                    />
                    未発送含む
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value={OFFLINE_STORE_ORDER_STATUS.include_shipped}
                      defaultChecked={
                        filter.status ===
                        OFFLINE_STORE_ORDER_STATUS.include_shipped
                      }
                    />
                    発送済み含む
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value={OFFLINE_STORE_ORDER_STATUS.include_returning}
                      defaultChecked={
                        filter.status ===
                        OFFLINE_STORE_ORDER_STATUS.include_returning
                      }
                    />
                    返品対応中含む
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value={OFFLINE_STORE_ORDER_STATUS.include_return}
                      defaultChecked={
                        filter.status ===
                        OFFLINE_STORE_ORDER_STATUS.include_return
                      }
                    />
                    返金済み含む
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value={OFFLINE_STORE_ORDER_STATUS.include_cancel}
                      defaultChecked={
                        filter.status ===
                        OFFLINE_STORE_ORDER_STATUS.include_cancel
                      }
                    />
                    キャンセル済み含む
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value=""
                      defaultChecked={filter.status === "default"}
                    />
                    全て
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={filter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="created_at">作成日時</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="slip_number">伝票番号</th>
                <th className="order_number">注文番号</th>
                <th className="name">注文者氏名</th>
                <th className="store_name">購入店舗名</th>
                <th className="create">注文日時</th>
                <th className="shipment_date">配送希望日</th>
                <th className="shipment_time">配送希望時間帯</th>
                <th className="tracking_number">追跡番号</th>
                <th className="status">ステータス</th>
                <th className="total_amount">購入金額</th>
              </tr>
            </thead>
            <tbody>
              {offlineStoreOrderList.map((offlineStoreOrder) => (
                <OfflineStoreOrderItem
                  offlineStoreOrder={offlineStoreOrder}
                  selectItem={selectItem}
                  key={offlineStoreOrder.order_number}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(offlineStoreOrderCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
    </>
  );
};

export default OfflineStoreOrder;

const OfflineStoreOrderItem = (props) => {
  const {
    slip_number,
    order_number,
    name,
    store_name,
    created_at,
    status_list,
    total_amount,
    items,
  } = props.offlineStoreOrder;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(order_number)}>
      <td>{slip_number}</td>
      <td>{order_number}</td>
      <td>{name}</td>
      <td>{store_name}</td>
      <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
      <td>
        {items.map((item, index) => (
          <div key={index}>
            {item.delivery_date
              ? dateToString(item.delivery_date, "yyyy/MM/dd")
              : "指定なし"}
          </div>
        ))}
      </td>
      <td>
        {items.map((item, index) => (
          <div key={index}>
            {parseDeliveryTime(item.delivery_time, "指定なし")}
          </div>
        ))}
      </td>
      <td>
        {items.map((item, index) => (
          <div key={index}>{item.shipping_code || "未設定"}</div>
        ))}
      </td>
      <td>{status_list}</td>
      <td>{total_amount.toLocaleString()}</td>
    </tr>
  );
};
