// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createCoupon, clearStatus } from "redux/actions/coupon";

// style
import "./style.scss";

const CouponCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [expirationDateIsLimitNone, setExpirationDateIsLimitNone] =
    useState(false);
  const [startDateIsLimitNone, setStartDateIsLimitNone] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.coupon.isProcessing);
  const isCreateComplete = useSelector(
    (state) => state.coupon.isCreateComplete
  );

  const dispatch = useDispatch();

  const FIELDS = {
    code: "code",
    name: "name",
    discountRate: "discount_rate",
    expirationDate: "expiration_date",
    startDate: "start_date",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every((field) => {
      if (field === FIELDS.expirationDate) {
        return expirationDateIsLimitNone ? true : elements[field].value;
      }
      if (field === FIELDS.startDate) {
        return startDateIsLimitNone ? true : elements[field].value;
      }
      return elements[field].value;
    });

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      code: elements[FIELDS.code].value,
      name: elements[FIELDS.name].value,
      discount_rate: elements[FIELDS.discountRate].value,
      expiration_date: expirationDateIsLimitNone
        ? ""
        : elements[FIELDS.expirationDate].value + ":00",
      start_date: startDateIsLimitNone
        ? ""
        : elements[FIELDS.startDate].value + ":00",
    };

    if (token) {
      dispatch(createCoupon(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  useEffect(() => {
    setSubmitEnabled();
  }, [startDateIsLimitNone, expirationDateIsLimitNone]);

  return (
    <div className="coupon_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>クーポンコード</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.code}
                />
              </td>
            </tr>
            <tr>
              <th>クーポン名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                />
              </td>
            </tr>
            <tr>
              <th>割引率(%)</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.discountRate}
                  min="0"
                  max="100"
                />
              </td>
            </tr>
            <tr>
              <th>使用開始日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.startDate}
                  disabled={startDateIsLimitNone}
                />
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => setStartDateIsLimitNone(e.target.checked)}
                    style={{ marginTop: "1vh" }}
                  />
                  設定なし
                </label>
              </td>
            </tr>
            <tr>
              <th>使用期限</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.expirationDate}
                  disabled={expirationDateIsLimitNone}
                />
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setExpirationDateIsLimitNone(e.target.checked)
                    }
                    style={{ marginTop: "1vh" }}
                  />
                  期限なし
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default CouponCreate;
