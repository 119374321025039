import axios from "../network";
import * as types from "../types/stock";

const getStocksRequest = () => {
	return {
		type: types.GET_STOCKS_REQUEST,
	};
};

const getStocksSuccess = (data) => {
	return {
		type: types.GET_STOCKS_SUCCESS,
		data,
	};
};

const getStocksFailure = (error) => {
	return {
		type: types.GET_STOCKS_FAILURE,
		error,
	};
};

export const getStocks = (token, data) => {
	return (dispatch) => {
		dispatch(getStocksRequest());
		return axios
			.get("/admin/stock/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => dispatch(getStocksSuccess(res.data)))
			.catch((err) => dispatch(getStocksFailure(err)));
	};
};

const getStockInfoRequest = () => {
	return {
		type: types.GET_STOCK_INFO_REQUEST,
	};
};

const getStockInfoSuccess = (data) => {
	return {
		type: types.GET_STOCK_INFO_SUCCESS,
		data,
	};
};

const getStockInfoFailure = (error) => {
	return {
		type: types.GET_STOCK_INFO_FAILURE,
		error,
	};
};

export const getStockInfo = (token, stockID) => {
	return (dispatch) => {
		dispatch(getStockInfoRequest());
		return axios
			.get("/admin/stock/", {
				headers: { token: token },
				params: { stock_id: stockID },
				data: {},
			})
			.then((res) => dispatch(getStockInfoSuccess(res.data)))
			.catch((err) => dispatch(getStockInfoFailure(err)));
	};
};

const createStockRequest = () => {
	return {
		type: types.CREATE_STOCK_REQUEST,
	};
};

const createStockSuccess = (data) => {
	return {
		type: types.CREATE_STOCK_SUCCESS,
		data,
	};
};

const createStockFailure = (error) => {
	return {
		type: types.CREATE_STOCK_FAILURE,
		error,
	};
};

export const createStock = (token, data) => {
	return (dispatch) => {
		dispatch(createStockRequest());
		return axios
			.post("/admin/stock/", data, {
				headers: { token: token },
			})
			.then((res) => dispatch(createStockSuccess(res.data)))
			.catch((err) => dispatch(createStockFailure(err)));
	};
};

const updateStockRequest = () => {
	return {
		type: types.UPDATE_STOCK_REQUEST,
	};
};

const updateStockSuccess = (data) => {
	return {
		type: types.UPDATE_STOCK_SUCCESS,
		data,
	};
};

const updateStockFailure = (error) => {
	return {
		type: types.UPDATE_STOCK_FAILURE,
		error,
	};
};

export const updateStock = (token, data) => {
	return (dispatch) => {
		dispatch(updateStockRequest());
		return axios
			.put("/admin/stock/", data, {
				headers: { token: token },
			})
			.then((res) => dispatch(updateStockSuccess(res.data)))
			.catch((err) => dispatch(updateStockFailure(err)));
	};
};

const updateStockActiveRequest = () => {
	return {
		type: types.UPDATE_STOCK_ACTIVE_REQUEST,
	};
};

const updateStockActiveSuccess = (data) => {
	return {
		type: types.UPDATE_STOCK_ACTIVE_SUCCESS,
		data,
	};
};

const updateStockActiveFailure = (error) => {
	return {
		type: types.UPDATE_STOCK_ACTIVE_FAILURE,
		error,
	};
};

export const updateStockActive = (token, data) => {
	return (dispatch) => {
		dispatch(updateStockActiveRequest());
		return axios
			.put("/admin/stock/", data, {
				headers: { token: token },
			})
			.then((res) => dispatch(updateStockActiveSuccess(res.data)))
			.catch((err) => dispatch(updateStockActiveFailure(err)));
	};
}

const deleteStockRequest = () => {
	return {
		type: types.DELETE_STOCK_REQUEST,
	};
};

const deleteStockSuccess = (data) => {
	return {
		type: types.DELETE_STOCK_SUCCESS,
		data
	};
};

const deleteStockFailure = (error) => {
	return {
		type: types.DELETE_STOCK_FAILURE,
		error,
	};
};

export const deleteStock = (token, data) => {
	return (dispatch) => {
		dispatch(deleteStockRequest());
		return axios
			.post("/admin/stock/delete/", data, {
				headers: { token: token },
			})
			.then((res) => dispatch(deleteStockSuccess(res.data)))
			.catch((err) => dispatch(deleteStockFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_STOCK_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_STOCK_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_STOCK_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_STOCK_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_STOCK_PAGE,
		page: page,
	};
};
