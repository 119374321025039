// libs
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Pager from "components/commons/Pager";
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import OfflineStoreSaleCreate from "./OfflineStoreSaleCreate";
import ErrorMessage from "components/commons/ErrorMessage";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// action
import {
  getOfflineStoreSales,
  setFilter,
  clearError,
  changePage,
} from "redux/actions/offlineStoreSale";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const OfflineStoreSale = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.offline_store_sales.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const offlineStoreSaleList = useSelector(
    (state) => state.offlineStoreSale.offlineStoreSaleList.list
  );
  const offlineStoreSaleCount = useSelector(
    (state) => state.offlineStoreSale.offlineStoreSaleList.count
  );
  const isProcessing = useSelector(
    (state) => state.offlineStoreSale.isProcessing
  );
  const filter = useSelector((state) => state.offlineStoreSale.filter);
  const error = useSelector((state) => state.offlineStoreSale.error);
  const currentPage = useSelector(
    (state) => state.offlineStoreSale.currentPage
  );

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isOfflineStoreSaleCreate, setOfflineStoreSaleCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(filter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const FIELDS = {
    saleName: "sale_name",
    storeName: "store_name",
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      sale_name: elements[FIELDS.saleName].value,
      store_name: elements[FIELDS.storeName].value,
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getOfflineStoreSales(token, data));
    }

    const filterData = {
      saleName: elements[FIELDS.saleName].value,
      storeName: elements[FIELDS.storeName].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/offline-store-sale/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="offline_store_sale_list list">
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>セール名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.saleName}
                    type="text"
                    defaultValue={filter.saleName}
                  />
                </td>
              </tr>
              <tr>
                <th>対象店舗名</th>
                <td>
                  <input
                    className="design_input"
                    name={FIELDS.storeName}
                    type="text"
                    defaultValue={filter.storeName}
                  />
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={filter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="created_at">作成日時</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={filter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={filter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              disabled={isProcessing}
            />
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setOfflineStoreSaleCreate(true)}
                disabled={isProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="id">セール名</th>
                <th className="store_names">対象店舗</th>
                <th className="discount_rate">割引率</th>
                <th className="start_at">開始日時</th>
                <th className="end_at">終了日時</th>
              </tr>
            </thead>
            <tbody>
              {offlineStoreSaleList.map((offlineStoreSale) => (
                <OfflineStoreSaleItem
                  offlineStoreSale={offlineStoreSale}
                  selectItem={selectItem}
                  key={offlineStoreSale.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(offlineStoreSaleCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isOfflineStoreSaleCreate && (
        <Modal close={() => setOfflineStoreSaleCreate(false)}>
          <OfflineStoreSaleCreate
            close={() => setOfflineStoreSaleCreate(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default OfflineStoreSale;

const OfflineStoreSaleItem = (props) => {
  const { id, name, stores, discount_rate, start_at, end_at } =
    props.offlineStoreSale;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td className="name">{name}</td>
      <td className="stores">
        {stores.length > 0 && stores.map((store) => store.store_name).join(",")}
      </td>
      <td className="discount_rate">{discount_rate}%</td>
      <td className="start_at">{dateToString(start_at, "yyyy/MM/dd HH:mm")}</td>
      <td className="end_at">{dateToString(end_at, "yyyy/MM/dd HH:mm")}</td>
    </tr>
  );
};
