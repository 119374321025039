export const convertStatus = (status) => {
  switch (status) {
    case "waiting":
      return "入庫待ち";
    case "slight_delay":
      return "入庫待ち（バッファ期間）";
    case "stocked":
      return "入庫済み";
    case "serious_delay":
      return "入庫予定日超過";
    default:
      return "";
  }
};
