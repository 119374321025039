export const convertProductGroup = (productGroupId) => {
  switch (productGroupId) {
    case "mattress_original":
      return "マットレス";
    case "box_sheets":
      return "ボックスシーツ";
    default:
      return "";
  }
};
