// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import {
  deleteOfflineStore,
  resetPasswordOfflineStore,
  getOfflineStoreInfo,
  clearStatus,
  clearError,
} from "redux/actions/offlineStore";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const OfflineStoreDetail = () => {
  const { offlineStoreId } = useParams();
  const applicationId = applicationList.offline_store_stores.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const offlineStore = useSelector((state) => state.offlineStore.info);
  const error = useSelector((state) => state.offlineStore.error);
  const isProcessing = useSelector((state) => state.offlineStore.isProcessing);
  const isOfflineStoreIsDeleteComplete = useSelector(
    (state) => state.offlineStore.isOfflineStoreIsDeleteComplete
  );

  const offlineStorePassword = useSelector(
    (state) => state.offlineStore.password
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchOfflineStore = () => {
    dispatch(getOfflineStoreInfo(token, offlineStoreId));
  };

  const handleDeleteOfflineStore = () => {
    let confirmMessage =
      "オフライン店舗アカウントを削除しようとしています。よろしいですか？";

    if (window.confirm(confirmMessage)) {
      dispatch(deleteOfflineStore(token, offlineStoreId));
    }
  };

  const handleResetPasswordOfflineStore = () => {
    let confirmMessage =
      "オフライン店舗アカウントパスワードをリセットします。よろしいですか？";

    if (window.confirm(confirmMessage)) {
      dispatch(resetPasswordOfflineStore(token, offlineStoreId));
    }
  };

  useEffect(() => {
    if (isOfflineStoreIsDeleteComplete) {
      window.alert("削除しました");
      dispatch(clearStatus());
      navigate("/offline-store/");
    }
  }, [isOfflineStoreIsDeleteComplete]);

  useEffect(() => {
    if (offlineStorePassword) {
      window.alert(
        `パスワードをリセットしました。新しいパスワード: ${offlineStorePassword}`
      );
      dispatch(clearStatus());
    }
  }, [offlineStorePassword]);

  useEffect(() => {
    fetchOfflineStore();
  }, []);

  return (
    <>
      <div className="offline_store_detail detail">
        <div className="list_back_container">
          <Link to="/offline-store">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>{offlineStore.id}</td>
            </tr>
            <tr>
              <th>店舗名</th>
              <td>{offlineStore.name}</td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>
                {dateToString(offlineStore.created_at, "yyyy/MM/dd HH:mm")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <>
              <button
                className="design_button delete_button"
                onClick={() => handleDeleteOfflineStore()}
                disabled={isProcessing}
              >
                削除
              </button>
              <button
                className="design_button reset_password_button"
                onClick={() => handleResetPasswordOfflineStore()}
                disabled={isProcessing}
              >
                パスワードリセット
              </button>
            </>
          )}
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
    </>
  );
};

export default OfflineStoreDetail;
