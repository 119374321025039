import axios from "../network";
import * as types from "../types/news";

const getNewsListRequest = () => {
	return {
		type: types.GET_NEWS_LIST_REQUEST,
	};
};

const getNewsListSuccess = (data) => {
	return {
		type: types.GET_NEWS_LIST_SUCCESS,
		data,
	};
};

const getNewsListFailure = (error) => {
	return {
		type: types.GET_NEWS_LIST_FAILURE,
		error,
	};
};

export const getNewsList = (token, data) => {
	return (dispatch) => {
		dispatch(getNewsListRequest());
		return axios
			.get("/admin/news/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getNewsListSuccess(res.data));
			})
			.catch((err) => dispatch(getNewsListFailure(err)));
	};
};

const getNewsInfoRequest = () => {
	return {
		type: types.GET_NEWS_INFO_REQUEST,
	};
};

const getNewsInfoSuccess = (data) => {
	return {
		type: types.GET_NEWS_INFO_SUCCESS,
		data,
	};
};

const getNewsInfoFailure = (error) => {
	return {
		type: types.GET_NEWS_INFO_FAILURE,
		error,
	};
};

export const getNewsInfo = (token, id) => {
	return (dispatch) => {
		dispatch(getNewsInfoRequest());
		return axios
			.get("/admin/news/", {
				headers: { token: token },
				params: { news_id: id },
				data: {},
			})
			.then((res) => {
				return dispatch(getNewsInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getNewsInfoFailure(err)));
	};
};

const createNewsRequest = () => {
	return {
		type: types.CREATE_NEWS_REQUEST,
	};
};

const createNewsSuccess = (data) => {
	return {
		type: types.CREATE_NEWS_SUCCESS,
		data,
	};
};

const createNewsFailure = (error) => {
	return {
		type: types.CREATE_NEWS_FAILURE,
		error,
	};
};

export const createNews = (token, data) => {
	return (dispatch) => {
		dispatch(createNewsRequest());
		return axios
			.post("/admin/news/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createNewsSuccess(res.data));
			})
			.catch((err) => dispatch(createNewsFailure(err)));
	};
};

const updateNewsRequest = () => {
	return {
		type: types.UPDATE_NEWS_REQUEST,
	};
};

const updateNewsSuccess = (data) => {
	return {
		type: types.UPDATE_NEWS_SUCCESS,
		data,
	};
};

const updateNewsFailure = (error) => {
	return {
		type: types.UPDATE_NEWS_FAILURE,
		error,
	};
};

export const updateNews = (token, data) => {
	return (dispatch) => {
		dispatch(updateNewsRequest());
		return axios
			.put("/admin/news/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateNewsSuccess(res.data));
			})
			.catch((err) => dispatch(updateNewsFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_NEWS_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_NEWS_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_NEWS_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_NEWS_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_NEWS_PAGE,
		page: page,
	};
};
