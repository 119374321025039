import axios from "../network";
import * as types from "../types/review";

const getReviewsRequest = () => {
	return {
		type: types.GET_REVIEWS_REQUEST,
	};
};

const getReviewsSuccess = (data) => {
	return {
		type: types.GET_REVIEWS_SUCCESS,
		data,
	};
};

const getReviewsFailure = (error) => {
	return {
		type: types.GET_REVIEWS_FAILURE,
		error,
	};
};

export const getReviews = (token, data) => {
	return (dispatch) => {
		dispatch(getReviewsRequest());
		return axios
			.get("/admin/review/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getReviewsSuccess(res.data));
			})
			.catch((err) => dispatch(getReviewsFailure(err)));
	};
};

const getReviewInfoRequest = () => {
	return {
		type: types.GET_REVIEW_INFO_REQUEST,
	};
};

const getReviewInfoSuccess = (data) => {
	return {
		type: types.GET_REVIEW_INFO_SUCCESS,
		data,
	};
};

const getReviewInfoFailure = (error) => {
	return {
		type: types.GET_REVIEW_INFO_FAILURE,
		error,
	};
};

export const getReviewInfo = (token, reviewID) => {
	return (dispatch) => {
		dispatch(getReviewInfoRequest());
		return axios
			.get("/admin/review/", {
				headers: { token: token },
				params: { review_id: reviewID },
				data: {},
			})
			.then((res) => {
				return dispatch(getReviewInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getReviewInfoFailure(err)));
	};
};

const createReviewRequest = () => {
	return {
		type: types.CREATE_REVIEW_REQUEST,
	};
};

const createReviewSuccess = (data) => {
	return {
		type: types.CREATE_REVIEW_SUCCESS,
		data,
	};
};

const createReviewFailure = (error) => {
	return {
		type: types.CREATE_REVIEW_FAILURE,
		error,
	};
};

export const createReview = (token, data) => {
	return (dispatch) => {
		dispatch(createReviewRequest());
		return axios
			.post("/admin/review/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createReviewSuccess(res.data));
			})
			.catch((err) => dispatch(createReviewFailure(err)));
	};
};

const updateReviewRequest = () => {
	return {
		type: types.UPDATE_REVIEW_REQUEST,
	};
};

const updateReviewSuccess = (data) => {
	return {
		type: types.UPDATE_REVIEW_SUCCESS,
		data,
	};
};

const updateReviewFailure = (error) => {
	return {
		type: types.UPDATE_REVIEW_FAILURE,
		error,
	};
};

export const updateReview = (token, data) => {
	return (dispatch) => {
		dispatch(updateReviewRequest());
		return axios
			.put("/admin/review/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateReviewSuccess(res.data));
			})
			.catch((err) => dispatch(updateReviewFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_REVIEW_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_REVIEW_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_REVIEW_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_REVIEW_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_REVIEW_PAGE,
		page: page,
	};
};
