import axios from "../network";
import * as types from "../types/offlineStoreSale";

const getOfflineStoreSalesRequest = () => {
  return {
    type: types.GET_OFFLINE_STORE_SALES_REQUEST,
  };
};

const getOfflineStoreSalesSuccess = (data) => {
  return {
    type: types.GET_OFFLINE_STORE_SALES_SUCCESS,
    data,
  };
};

const getOfflineStoreSalesFailure = (error) => {
  return {
    type: types.GET_OFFLINE_STORE_SALES_FAILURE,
    error,
  };
};

export const getOfflineStoreSales = (token, data) => {
  return (dispatch) => {
    dispatch(getOfflineStoreSalesRequest());
    return axios
      .get("/admin/offline-store/sales/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => {
        return dispatch(getOfflineStoreSalesSuccess(res.data));
      })
      .catch((err) => dispatch(getOfflineStoreSalesFailure(err)));
  };
};

const getOfflineStoreSaleInfoRequest = () => {
  return {
    type: types.GET_OFFLINE_STORE_SALE_INFO_REQUEST,
  };
};

const getOfflineStoreSaleInfoSuccess = (data) => {
  return {
    type: types.GET_OFFLINE_STORE_SALE_INFO_SUCCESS,
    data,
  };
};

const getOfflineStoreSaleInfoFailure = (error) => {
  return {
    type: types.GET_OFFLINE_STORE_SALE_INFO_FAILURE,
    error,
  };
};

export const getOfflineStoreSaleInfo = (token, saleId) => {
  return (dispatch) => {
    dispatch(getOfflineStoreSaleInfoRequest());
    return axios
      .get(`/admin/offline-store/sales/${saleId}/`, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(getOfflineStoreSaleInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getOfflineStoreSaleInfoFailure(err)));
  };
};

const createOfflineStoreSaleRequest = () => {
  return {
    type: types.CREATE_OFFLINE_STORE_SALE_REQUEST,
  };
};

const createOfflineStoreSaleSuccess = (data) => {
  return {
    type: types.CREATE_OFFLINE_STORE_SALE_SUCCESS,
    data,
  };
};

const createOfflineStoreSaleFailure = (error) => {
  return {
    type: types.CREATE_OFFLINE_STORE_SALE_FAILURE,
    error,
  };
};

export const createOfflineStoreSale = (token, data) => {
  return (dispatch) => {
    dispatch(createOfflineStoreSaleRequest());
    return axios
      .post("/admin/offline-store/sales/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(createOfflineStoreSaleSuccess(res.data));
      })
      .catch((err) => dispatch(createOfflineStoreSaleFailure(err)));
  };
};

const updateOfflineStoreSaleRequest = () => {
  return {
    type: types.UPDATE_OFFLINE_STORE_SALE_REQUEST,
  };
};

const updateOfflineStoreSaleSuccess = (data) => {
  return {
    type: types.UPDATE_OFFLINE_STORE_SALE_SUCCESS,
    data,
  };
};

const updateOfflineStoreSaleFailure = (error) => {
  return {
    type: types.UPDATE_OFFLINE_STORE_SALE_FAILURE,
    error,
  };
};

export const updateOfflineStoreSale = (token, saleId, data) => {
  return (dispatch) => {
    dispatch(updateOfflineStoreSaleRequest());
    return axios
      .put(`/admin/offline-store/sales/${saleId}/`, data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(updateOfflineStoreSaleSuccess(res.data));
      })
      .catch((err) => dispatch(updateOfflineStoreSaleFailure(err)));
  };
};

export const clearStatus = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_SALE_STATUS,
  };
};

export const setFilter = (data) => {
  return {
    type: types.SET_OFFLINE_STORE_SALE_FILTER,
    data: data,
  };
};

export const clearFilter = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_SALE_FILTER,
  };
};

export const clearError = () => {
  return {
    type: types.CLEAR_OFFLINE_STORE_SALE_ERROR,
  };
};

export const changePage = (page) => {
  return {
    type: types.CHANGE_OFFLINE_STORE_SALE_PAGE,
    page: page,
  };
};
