// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  createPermissionGroup,
  permissionGroupClearStatus,
} from "redux/actions/adminUser";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const AdminUserPermissionGroupCreate = ({ close }) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const permissionGroupIsProcessing = useSelector(
    (state) => state.adminUser.permissionGroupIsProcessing
  );
  const permissionGroupIsCreateComplete = useSelector(
    (state) => state.adminUser.permissionGroupIsCreateComplete
  );
  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    permission: "permission",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every((field) => {
      if (field === "permission") {
        return true;
      }
      return elements[field].value;
    });

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      name: elements[FIELDS.name].value,
      permission_list: [],
    };

    Object.keys(applicationList).forEach((applicationId) => {
      if (applicationList[applicationId].id) {
        data.permission_list.push({
          application_id: applicationList[applicationId].id,
          permission_type:
            elements[
              FIELDS.permission + "_" + applicationList[applicationId].id
            ].value,
        });
      }
    });

    if (token) {
      dispatch(createPermissionGroup(token, data));
    }
  };

  useEffect(() => {
    if (permissionGroupIsCreateComplete) {
      window.alert("作成しました");
      dispatch(permissionGroupClearStatus());
      close();
    }
  }, [permissionGroupIsCreateComplete]);

  return (
    <div className="notice_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>権限グループ名</th>
              <td>
                <input
                  className="design_input"
                  name={FIELDS.name}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <th>権限</th>
              <td>
                <table>
                  <tbody>
                    {Object.keys(applicationList).map(
                      (applicationId, index) =>
                        applicationList[applicationId].id && (
                          <ApplicationPermissionItem
                            applicationName={
                              applicationList[applicationId].applicationName
                            }
                            id={applicationId}
                            FIELDS={FIELDS}
                            key={index}
                          />
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || permissionGroupIsProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default AdminUserPermissionGroupCreate;

const ApplicationPermissionItem = ({ id, applicationName, FIELDS }) => {
  return (
    <tr className="radio_button_container">
      <th>{applicationName}</th>
      <td>
        <label className="radio_button">
          <input
            name={FIELDS.permission + "_" + id}
            type="radio"
            value=""
            defaultChecked={true}
          />
          なし
        </label>
      </td>
      <td>
        <label className="radio_button">
          <input
            name={FIELDS.permission + "_" + id}
            type="radio"
            value="read"
          />
          読み込み
        </label>
      </td>
      <td>
        <label className="radio_button">
          <input
            name={FIELDS.permission + "_" + id}
            type="radio"
            value="write"
          />
          読み込み・書き込み
        </label>
      </td>
    </tr>
  );
};
