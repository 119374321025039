export const GET_OFFLINE_STORE_ORDERS_REQUEST =
  "GET_OFFLINE_STORE_ORDERS_REQUEST";
export const GET_OFFLINE_STORE_ORDERS_SUCCESS =
  "GET_OFFLINE_STORE_ORDERS_SUCCESS";
export const GET_OFFLINE_STORE_ORDERS_FAILURE =
  "GET_OFFLINE_STORE_ORDERS_FAILURE";

export const GET_OFFLINE_STORE_ORDER_INFO_REQUEST =
  "GET_OFFLINE_STORE_ORDER_INFO_REQUEST";
export const GET_OFFLINE_STORE_ORDER_INFO_SUCCESS =
  "GET_OFFLINE_STORE_ORDER_INFO_SUCCESS";
export const GET_OFFLINE_STORE_ORDER_INFO_FAILURE =
  "GET_OFFLINE_STORE_ORDER_INFO_FAILURE";

export const CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_REQUEST =
  "CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_REQUEST";
export const CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_SUCCESS =
  "CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_SUCCESS";
export const CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_FAILURE =
  "CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_FAILURE";

export const CLEAR_OFFLINE_STORE_ORDER_STATUS =
  "CLEAR_OFFLINE_STORE_ORDER_STATUS";

export const SET_OFFLINE_STORE_ORDER_FILTER = "SET_OFFLINE_STORE_ORDER_FILTER";
export const CLEAR_OFFLINE_STORE_ORDER_FILTER =
  "CLEAR_OFFLINE_STORE_ORDER_FILTER";

export const CLEAR_OFFLINE_STORE_ORDER_ERROR =
  "CLEAR_OFFLINE_STORE_ORDER_ERROR";
export const CHANGE_OFFLINE_STORE_ORDER_PAGE =
  "CHANGE_OFFLINE_STORE_ORDER_PAGE";
