export const GET_ADMIN_USERS_REQUEST = "GET_ADMIN_USERS_REQUEST";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAILURE = "GET_ADMIN_USERS_FAILURE";

export const GET_ADMIN_USER_INFO_REQUEST = "GET_ADMIN_USER_INFO_REQUEST";
export const GET_ADMIN_USER_INFO_SUCCESS = "GET_ADMIN_USER_INFO_SUCCESS";
export const GET_ADMIN_USER_INFO_FAILURE = "GET_ADMIN_USER_INFO_FAILURE";

export const CREATE_ADMIN_USER_REQUEST = "CREATE_ADMIN_USER_REQUEST";
export const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS";
export const CREATE_ADMIN_USER_FAILURE = "CREATE_ADMIN_USER_FAILURE";

export const UPDATE_ADMIN_USER_REQUEST = "UPDATE_ADMIN_USER_REQUEST";
export const UPDATE_ADMIN_USER_SUCCESS = "UPDATE_ADMIN_USER_SUCCESS";
export const UPDATE_ADMIN_USER_FAILURE = "UPDATE_ADMIN_USER_FAILURE";

export const DELETE_ADMIN_USER_REQUEST = "DELETE_ADMIN_USER_REQUEST";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_FAILURE = "DELETE_ADMIN_USER_FAILURE";

export const LOCKOUT_CANCEL_REQUEST = "LOCKOUT_CANCEL_REQUEST";
export const LOCKOUT_CANCEL_SUCCESS = "LOCKOUT_CANCEL_SUCCESS";
export const LOCKOUT_CANCEL_FAILURE = "LOCKOUT_CANCEL_FAILURE";

export const CLEAR_ADMIN_USER_STATUS = "CLEAR_ADMIN_USER_STATUS";

export const SET_ADMIN_USER_FILTER = "SET_ADMIN_USER_FILTER";
export const CLEAR_ADMIN_USER_FILTER = "CLEAR_ADMIN_USER_FILTER";

export const CLEAR_ADMIN_USER_ERROR = "CLEAR_ADMIN_USER_ERROR";
export const CHANGE_ADMIN_USER_PAGE = "CHANGE_ADMIN_USER_PAGE";

export const GET_PERMISSION_GROUPS_REQUEST = "GET_PERMISSION_GROUPS_REQUEST";
export const GET_PERMISSION_GROUPS_SUCCESS = "GET_PERMISSION_GROUPS_SUCCESS";
export const GET_PERMISSION_GROUPS_FAILURE = "GET_PERMISSION_GROUPS_FAILURE";

export const GET_PERMISSION_GROUP_INFO_REQUEST =
  "GET_PERMISSION_GROUP_INFO_REQUEST";
export const GET_PERMISSION_GROUP_INFO_SUCCESS =
  "GET_PERMISSION_GROUP_INFO_SUCCESS";
export const GET_PERMISSION_GROUP_INFO_FAILURE =
  "GET_PERMISSION_GROUP_INFO_FAILURE";

export const CREATE_PERMISSION_GROUP_REQUEST =
  "CREATE_PERMISSION_GROUP_REQUEST";
export const CREATE_PERMISSION_GROUP_SUCCESS =
  "CREATE_PERMISSION_GROUP_SUCCESS";
export const CREATE_PERMISSION_GROUP_FAILURE =
  "CREATE_PERMISSION_GROUP_FAILURE";

export const UPDATE_PERMISSION_GROUP_REQUEST =
  "UPDATE_PERMISSION_GROUP_REQUEST";
export const UPDATE_PERMISSION_GROUP_SUCCESS =
  "UPDATE_PERMISSION_GROUP_SUCCESS";
export const UPDATE_PERMISSION_GROUP_FAILURE =
  "UPDATE_PERMISSION_GROUP_FAILURE";

export const DELETE_PERMISSION_GROUP_REQUEST =
  "DELETE_PERMISSION_GROUP_REQUEST";
export const DELETE_PERMISSION_GROUP_SUCCESS =
  "DELETE_PERMISSION_GROUP_SUCCESS";
export const DELETE_PERMISSION_GROUP_FAILURE =
  "DELETE_PERMISSION_GROUP_FAILURE";

export const CLEAR_PERMISSION_GROUP_STATUS = "CLEAR_PERMISSION_GROUP_STATUS";

export const SET_PERMISSION_GROUP_FILTER = "SET_PERMISSION_GROUP_FILTER";
export const CLEAR_PERMISSION_GROUP_FILTER = "CLEAR_PERMISSION_GROUP_FILTER";

export const CLEAR_PERMISSION_GROUP_ERROR = "CLEAR_PERMISSION_GROUP_ERROR";
export const CHANGE_PERMISSION_GROUP_PAGE = "CHANGE_PERMISSION_GROUP_PAGE";
