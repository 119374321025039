// 本番環境倉庫一覧
const NGO2 = {
    place: "所沢",
    id: "NGO2"
};

const TRN3 = {
    place: "綾瀬",
    id: "TRN3"
};

const TRN1 = {
    place: "藤沢",
    id: "TRN1"
};

const YBY2 = {
    place: "薮吉",
    id: "YBY2"
};

// 開発環境倉庫一覧
const ZZZ25 = {
    place: "関東倉庫メイン",
    id: "ZZZ25"
};

const YBY1 = {
    place: "関西倉庫メイン",
    id: "YBY1"
};

const BLG1 = {
    place: "使ってない倉庫",
    id: "BLG1"
};

const YHG1 = {
    place: "使ってない倉庫",
    id: "YHG1"
};

const NBZ = {
    place: "使ってない倉庫",
    id: "NBZ"
};

const initialWarehouseInfo = {
    place: "倉庫が見つかりません",
};

const warehouseList = [
    ZZZ25,
    YBY1,
    BLG1,
    YHG1,
    NBZ,
    NGO2,
    TRN1,
    TRN3,
    YBY2
]

export const getWarehouseByID = (id) => {
    return warehouseList.find((warehouse) => warehouse.id === id) || initialWarehouseInfo;
};
