// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import Edit from "./Edit";
import MediaSelect from "./MediaSelect";
import MediaEdit from "./MediaEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import { getUgcInfo, clearError } from "redux/actions/ugc";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const UgcDetail = () => {
  const { ugcId } = useParams();
  const applicationId = applicationList.ugc.id;

  const [isEdit, setEdit] = useState(false);
  const [isMediaSelect, setMediaSelect] = useState(null);
  const [isMediaEdit, setMediaEdit] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const ugc = useSelector((state) => state.ugc.info);
  const error = useSelector((state) => state.ugc.error);
  const isProcessing = useSelector((state) => state.ugc.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const registTypeToString = (type) => {
    switch (type) {
      case "auto":
        return "自動";
      case "manual":
        return "手動";
      default:
        return "";
    }
  };

  const fetchUgc = () => {
    dispatch(getUgcInfo(token, ugcId));
  };

  const selectMedia = (media) => {
    if (ugc.register_type === "auto") {
      setMediaSelect(media);
    } else {
      setMediaEdit(media);
    }
  };

  useEffect(() => {
    fetchUgc();
  }, []);

  return (
    <>
      <div className="ugc_detail detail">
        <div className="list_back_container">
          <Link to="/ugc">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>投稿者</th>
              <td>{ugc.name}</td>
            </tr>
            <tr>
              <th>内容</th>
              <td>{ugc.caption}</td>
            </tr>
            <tr>
              <th>リンク</th>
              <td>
                <a href={ugc.url} target="_blank" rel="noopener noreferrer">
                  {ugc.url}
                </a>
              </td>
            </tr>
            <tr>
              <th>投稿日</th>
              <td>{dateToString(ugc.posted_at, "yyyy/MM/dd")}</td>
            </tr>
            <tr>
              <th>登録方法</th>
              <td>{registTypeToString(ugc.register_type)}</td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>{ugc.is_open ? "公開" : "非公開"}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <button
              className="design_button"
              onClick={() => setEdit(true)}
              disabled={isProcessing}
            >
              編集
            </button>
          )}
        </div>
        <div className="media_list_container">
          <div>画像・動画一覧</div>
          <div className="media_item_list">
            {ugc.media_list.map((media, index) => (
              <MediaItem
                media={media}
                key={index}
                click={selectMedia}
                writeIsAllowed={writeIsAllowed}
              />
            ))}
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isEdit && (
        <Modal close={() => setEdit(false)}>
          <Edit
            ugc={ugc}
            close={() => {
              setEdit(false);
              fetchUgc();
            }}
          />
        </Modal>
      )}
      {isMediaSelect && (
        <Modal close={() => setMediaSelect(null)}>
          <MediaSelect
            ucgID={ugc.id}
            media={isMediaSelect}
            close={() => {
              setMediaSelect(false);
              fetchUgc();
            }}
          />
        </Modal>
      )}
      {isMediaEdit && (
        <Modal close={() => setMediaEdit(null)}>
          <MediaEdit
            ucgID={ugc.id}
            media={isMediaEdit}
            close={() => {
              setMediaEdit(false);
              fetchUgc();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default UgcDetail;

const MediaItem = ({ media, click, writeIsAllowed }) => {
  return writeIsAllowed ? (
    <div
      className={`media_item ${media.is_main ? "selected" : ""}`}
      onClick={() => click(media)}
    >
      <div className="image_container">
        {media.is_video ? (
          <video muted loop playsInline autoPlay>
            <source src={media.video_url} type="video/mp4" />
          </video>
        ) : (
          <img src={media.image_url} alt="instagram" />
        )}
      </div>
    </div>
  ) : (
    <div className={`media_item ${media.is_main ? "selected" : ""}`}>
      <div className="image_container">
        {media.is_video ? (
          <video muted loop playsInline autoPlay>
            <source src={media.video_url} type="video/mp4" />
          </video>
        ) : (
          <img src={media.image_url} alt="instagram" />
        )}
      </div>
    </div>
  );
};
