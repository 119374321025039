// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createNews, clearStatus } from "redux/actions/news";

// style
import "./style.scss";

const CouponCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.news.isProcessing);
  const isCreateComplete = useSelector((state) => state.news.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    title: "title",
    description: "description",
    url: "url",
    tags: "tags",
    postedAt: "posted_at",
    isOpen: "is_open",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      title: elements[FIELDS.title].value,
      description: elements[FIELDS.description].value,
      url: elements[FIELDS.url].value,
      tags: elements[FIELDS.tags].value.split(","),
      posted_at: elements[FIELDS.postedAt].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    if (token) {
      dispatch(createNews(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="news_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>タイトル</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.title}
                />
              </td>
            </tr>
            <tr>
              <th>説明</th>
              <td>
                <textarea
                  className="design_input"
                  name={FIELDS.description}
                  style={{ resize: "none", height: "30vh" }}
                />
              </td>
            </tr>
            <tr>
              <th>リンク</th>
              <td>
                <input className="design_input" type="text" name={FIELDS.url} />
              </td>
            </tr>
            <tr>
              <th>タグ(カンマ区切り)</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.tags}
                />
              </td>
            </tr>
            <tr>
              <th>掲載日時</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={FIELDS.postedAt}
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <label className="radio_button">
                  <input name={FIELDS.isOpen} type="radio" value={true} />
                  公開
                </label>
                <label className="radio_button">
                  <input name={FIELDS.isOpen} type="radio" value={false} />
                  非公開
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default CouponCreate;
