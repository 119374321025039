// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateNotice, clearStatus } from "redux/actions/notice";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const NoticeEdit = (props) => {
  // params
  const { id, text, is_open, created_at } = props.notice;

  const FIELDS = {
    text: "text",
    isOpen: "is_open",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.notice.isProcessing);
  const isUpdateComplete = useSelector(
    (state) => state.notice.isUpdateComplete
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeNotice();
    }
  };

  const changeNotice = () => {
    const data = {
      notice_id: id,
      text: elements[FIELDS.text].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    if (token) {
      dispatch(updateNotice(token, data));
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="notice_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="notice_item">
          <tbody>
            <tr>
              <th>お知らせ内容</th>
              <td>
                <textarea
                  className="design_input"
                  name={FIELDS.text}
                  style={{ resize: "none", height: "30vh" }}
                  defaultValue={text}
                />
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.isOpen}
                  defaultValue={is_open}
                >
                  <option value={true}>公開</option>
                  <option value={false}>非公開</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default NoticeEdit;
