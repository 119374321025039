import axios from "axios";
import baseURL from "../../configs/baseURL";

axios.defaults.baseURL = baseURL;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

axios.interceptors.request.use((request) => {
	console.log("Starting Request: ", request);
	return request;
});

axios.interceptors.response.use((response) => {
	console.log("Response: ", response);
	return response;
});

export default axios;
