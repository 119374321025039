import * as types from "../types/product";

const productInfoInitialState = {
	id: "",
	code: "",
	name: "",
	stock: "",
	price: "",
};

const filterInifialState = {
	productID: "",
	productName: "",
	productCode: "",
	sortBy: "id",
	sortType: "asc",
};

const initialState = {
	filter: filterInifialState,
	productInfo: productInfoInitialState,
	productList: {
		list: [],
		count: 0,
	},
	isProcessing: false,
	isUpdateComplete: false,
	isCreateComplete: false,
	isDeleteComplete: false,
	currentPage: 1,
	error: null,
};

const product = (state = initialState, action) => {
	switch (action.type) {
		// 商品一覧取得
		case types.GET_PRODUCTS_REQUEST:
			return { ...state, isProcessing: true, error: null };
		case types.GET_PRODUCTS_SUCCESS:
			return {
				...state,
				isProcessing: false,
				productList: {
					list: action.data.openlogi_product_list,
					count: action.data.count,
				},
			};
		case types.GET_PRODUCTS_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// 商品情報取得
		case types.GET_PRODUCT_INFO_REQUEST:
			return {
				...state,
				isProcessing: true,
				productInfo: productInfoInitialState,
				isUpdateComplete: false,
				error: null,
			};
		case types.GET_PRODUCT_INFO_SUCCESS:
			return {
				...state,
				isProcessing: false,
				productInfo: action.data.openlogi_product,
			};
		case types.GET_PRODUCT_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };

		// 商品情報編集
		case types.UPDATE_PRODUCT_INFO_REQUEST:
			return {
				...state,
				isUpdateComplete: false,
				isProcessing: true,
				error: null,
			};
		case types.UPDATE_PRODUCT_INFO_SUCCESS:
			return { ...state, isUpdateComplete: true, isProcessing: false };
		case types.UPDATE_PRODUCT_INFO_FAILURE:
			return {
				...state,
				isUpdateComplete: false,
				isProcessing: false,
				error: action.error,
			};

		// 商品情報作成
		case types.CREATE_PRODUCT_INFO_REQUEST:
			return {
				...state,
				isCreateComplete: false,
				isProcessing: true,
				error: null,
			};
		case types.CREATE_PRODUCT_INFO_SUCCESS:
			return { ...state, isCreateComplete: true, isProcessing: false };
		case types.CREATE_PRODUCT_INFO_FAILURE:
			return {
				...state,
				isCreateComplete: false,
				isProcessing: false,
				error: action.error,
			};

		// 商品情報削除
		case types.DELETE_PRODUCT_INFO_REQUEST:
			return {
				...state,
				isDeleteComplete: false,
				isProcessing: true,
				error: null,
			};
		case types.DELETE_PRODUCT_INFO_SUCCESS:
			return { ...state, isDeleteComplete: true, isProcessing: false };
		case types.DELETE_PRODUCT_INFO_FAILURE:
			return {
				...state,
				isDeleteComplete: false,
				isProcessing: false,
				error: action.error,
			};

		case types.CLEAR_PRODUCT_STATUS:
			return {
				...state,
				isUpdateComplete: false,
				isCreateComplete: false,
				isDeleteComplete: false,
				error: null,
			};
		case types.SET_PRODUCT_FILTER:
			return { ...state, filter: action.data };
		case types.CLEAR_PRODUCT_FILTER:
			return { ...state, filter: filterInifialState };
		case types.CLEAR_PRODUCT_ERROR:
			return { ...state, error: null };
		case types.CHANGE_PRODUCT_PAGE:
			return { ...state, currentPage: action.page };
		default:
			return state;
	}
};

export default product;
