import axios from "../network";
import * as types from "../types/auth";
import { sha256 } from "../../utils/hash";

const loginSuccess = (data) => {
	return {
		type: types.LOGIN_SUCCESS,
		data,
	};
};

const loginFailure = (error) => {
	return {
		type: types.LOGIN_FAILURE,
		error,
	};
};

export const login = (id, password) => {
	return (dispatch) => {
		return axios
			.post("/admin/login/", {
				admin_user_id: id,
				password: sha256(password),
			})
			.then((res) => {
				return dispatch(loginSuccess(res.data));
			})
			.catch((err) => dispatch(loginFailure(err)));
	};
};

const logoutSuccess = (data) => {
	sessionStorage.removeItem("token");
	return {
		type: types.LOGOUT_SUCCESS,
		data,
	};
};

const logoutFailure = (error) => {
	return {
		type: types.LOGOUT_FAILURE,
		error,
	};
};

export const logout = (token) => {
	return (dispatch) => {
		return axios
			.post(
				"/admin/logout/",
				{},
				{
					headers: { token: token },
				}
			)
			.then((res) => {
				return dispatch(logoutSuccess(res.data));
			})
			.catch((err) => dispatch(logoutFailure(err)));
	};
};

const changePasswordSuccess = (data) => {
	return {
		type: types.CHANGE_PASSWORD_SUCCESS,
		data,
	};
};

const changePasswordFailure = (error) => {
	return {
		type: types.CHANGE_PASSWORD_FAILURE,
		error,
	};
};

export const changePassword = (token, password, newPassword) => {
	return (dispatch) => {
		return axios
			.put(
				"/admin/myaccount/",
				{
					password: sha256(password),
					new_password: sha256(newPassword),
				},
				{
					headers: { token: token },
				}
			)
			.then((res) => {
				return dispatch(changePasswordSuccess(res.data));
			})
			.catch((err) => dispatch(changePasswordFailure(err)));
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_AUTH_ERROR,
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_AUTH_STATUS,
	};
};

export const clearToken = () => {
	return {
		type: types.CLEAR_AUTH_TOKEN,
	};	
}
