export const GET_GIFTS_REQUEST = "GET_GIFTS_REQUEST";
export const GET_GIFTS_SUCCESS = "GET_GIFTS_SUCCESS";
export const GET_GIFTS_FAILURE = "GET_GIFTS_FAILURE";

export const GET_GIFT_LABELS_REQUEST = "GET_GIFT_LABELS_REQUEST";
export const GET_GIFT_LABELS_SUCCESS = "GET_GIFT_LABELS_SUCCESS";
export const GET_GIFT_LABELS_FAILURE = "GET_GIFT_LABELS_FAILURE";

export const GET_GIFT_INFO_REQUEST = "GET_GIFT_INFO_REQUEST";
export const GET_GIFT_INFO_SUCCESS = "GET_GIFT_INFO_SUCCESS";
export const GET_GIFT_INFO_FAILURE = "GET_GIFT_INFO_FAILURE";

export const GET_GIFT_PRODUCT_DELIVERY_DATE_REQUEST = "GET_GIFT_PRODUCT_DELIVERY_DATE_REQUEST";
export const GET_GIFT_PRODUCT_DELIVERY_DATE_SUCCESS = "GET_GIFT_PRODUCT_DELIVERY_DATE_SUCCESS";
export const GET_GIFT_PRODUCT_DELIVERY_DATE_FAILURE = "GET_GIFT_PRODUCT_DELIVERY_DATE_FAILURE";

export const CREATE_GIFT_REQUEST = "CREATE_GIFT_REQUEST";
export const CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS";
export const CREATE_GIFT_FAILURE = "CREATE_GIFT_FAILURE";

export const UPDATE_GIFT_REQUEST = "UPDATE_GIFT_REQUEST";
export const UPDATE_GIFT_SUCCESS = "UPDATE_GIFT_SUCCESS";
export const UPDATE_GIFT_FAILURE = "UPDATE_GIFT_FAILURE";

export const CREATE_GIFT_PRODUCT_REQUEST = "CREATE_GIFT_PRODUCT_REQUEST";
export const CREATE_GIFT_PRODUCT_SUCCESS = "CREATE_GIFT_PRODUCT_SUCCESS";
export const CREATE_GIFT_PRODUCT_FAILURE = "CREATE_GIFT_PRODUCT_FAILURE";

export const UPDATE_GIFT_PRODUCT_REQUEST = "UPDATE_GIFT_PRODUCT_REQUEST";
export const UPDATE_GIFT_PRODUCT_SUCCESS = "UPDATE_GIFT_PRODUCT_SUCCESS";
export const UPDATE_GIFT_PRODUCT_FAILURE = "UPDATE_GIFT_PRODUCT_FAILURE";

export const CANCEL_GIFT_PRODUCT_REQUEST = "CANCEL_GIFT_PRODUCT_REQUEST";
export const CANCEL_GIFT_PRODUCT_SUCCESS = "CANCEL_GIFT_PRODUCT_SUCCESS";
export const CANCEL_GIFT_PRODUCT_FAILURE = "CANCEL_GIFT_PRODUCT_FAILURE";


export const CLEAR_GIFT_STATUS = "CLEAR_GIFT_STATUS";

export const SET_GIFT_FILTER = "SET_GIFT_FILTER";
export const CLEAR_GIFT_FILTER = "CLEAR_GIFT_FILTER";

export const CLEAR_GIFT_ERROR = "CLEAR_GIFT_ERROR";
export const CHANGE_GIFT_PAGE = "CHANGE_GIFT_PAGE";
