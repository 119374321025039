export const GET_NEWS_LIST_REQUEST = "GET_NEWS_LIST_REQUEST";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_FAILURE = "GET_NEWS_LIST_FAILURE";

export const GET_NEWS_INFO_REQUEST = "GET_NEWS_INFO_REQUEST";
export const GET_NEWS_INFO_SUCCESS = "GET_NEWS_INFO_SUCCESS";
export const GET_NEWS_INFO_FAILURE = "GET_NEWS_INFO_FAILURE";

export const CREATE_NEWS_REQUEST = "CREATE_NEWS_REQUEST";
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS";
export const CREATE_NEWS_FAILURE = "CREATE_NEWS_FAILURE";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAILURE = "UPDATE_NEWS_FAILURE";

export const CLEAR_NEWS_STATUS = "CLEAR_NEWS_STATUS";

export const SET_NEWS_FILTER = "SET_NEWS_FILTER";
export const CLEAR_NEWS_FILTER = "CLEAR_NEWS_FILTER";

export const CLEAR_NEWS_ERROR = "CLEAR_NEWS_ERROR";
export const CHANGE_NEWS_PAGE = "CHANGE_NEWS_PAGE";
