// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import BusinessContactEdit from "./BusinessContactEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import {
  getBusinessContactInfo,
  clearError,
} from "redux/actions/businessContact";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const BusinessContactDetail = () => {
  const { businessContactId } = useParams();
  const applicationId = applicationList.businesscontact.id;

  const [isBusinessContactEdit, setBusinessContactEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const businessContact = useSelector((state) => state.businessContact.info);
  const error = useSelector((state) => state.businessContact.error);
  const isProcessing = useSelector((state) => state.businessContact.isProcessing);

	const BUSINESS_CONTACT_STATUS = {
    pending: "未対応",
    in_progress: "対応中",
    delivered: "納品済み",
    no_transaction: "取引なし"
  }

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchBusinessContact = () => {
    dispatch(getBusinessContactInfo(token, businessContactId));
  };

  useEffect(() => {
    fetchBusinessContact();
  }, []);

  return (
    <>
      <div className="business_contact_detail detail">
        <div className="list_back_container">
          <Link to="/businessContact">一覧に戻る</Link>
        </div>
        <div className="business_contact_flex">
          <div className="business_contact_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{businessContactId}</td>
                </tr>
                <tr>
                  <th>担当者名</th>
                  <td>{businessContact.name}</td>
                </tr>
                <tr>
                  <th>会社名</th>
                  <td>{businessContact.corporation}</td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td>{businessContact.mail}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>{businessContact.phone}</td>
                </tr>
                <tr>
                  <th>問い合わせ内容</th>
                  <td>{businessContact.text}</td>
                </tr>
                <tr>
                  <th>お知りになった経緯</th>
                  <td>{businessContact.route}</td>
                </tr>
                <tr>
                  <th>カタログダウンロード済み</th>
                  <td>{businessContact.is_catalog_downloaded ? "◯" : "-" }</td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{BUSINESS_CONTACT_STATUS[businessContact.status]}</td>
                </tr>
                <tr>
                  <th>メモ</th>
                  <td>{businessContact.memo}</td>
                </tr>
                <tr>
                  <th>作成日時</th>
                  <td>
                    {businessContact.created_at &&
                      dateToString(businessContact.created_at, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <>
                  <button
                    className="design_button"
                    onClick={() => setBusinessContactEdit(true)}
                    disabled={isProcessing}
                  >
                    編集
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isBusinessContactEdit && (
        <Modal close={() => setBusinessContactEdit(false)}>
          <BusinessContactEdit
            businessContact={businessContact}
            close={() => {
              setBusinessContactEdit(false);
              fetchBusinessContact();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default BusinessContactDetail;
