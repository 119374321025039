// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import SaleEdit from "./SaleEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import { getSaleInfo, clearError } from "redux/actions/sale";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const SaleDetail = () => {
  const { saleId } = useParams();
  const applicationId = applicationList.sale.id;

  const [isSaleEdit, setSaleEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const sale = useSelector((state) => state.sale.info);
  const error = useSelector((state) => state.sale.error);
  const isProcessing = useSelector((state) => state.sale.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchCounpon = () => {
    dispatch(getSaleInfo(token, saleId));
  };

  useEffect(() => {
    fetchCounpon();
  }, []);

  return (
    <>
      <div className="sale_detail detail">
        <div className="list_back_container">
          <Link to="/sale">一覧に戻る</Link>
        </div>
        <div className="sale_flex">
          <div className="sale_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>セール名</th>
                  <td>{sale.name}</td>
                </tr>
                <tr>
                  <th>クーポンコード</th>
                  <td>{sale.coupon.code}</td>
                </tr>
                <tr>
                  <th>開始日時</th>
                  <td>
                    {sale.start_at &&
                      dateToString(sale.start_at, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
                <tr>
                  <th>終了日時</th>
                  <td>
                    {sale.end_at &&
                      dateToString(sale.end_at, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{sale.is_open ? "公開" : "非公開"}</td>
                </tr>
                <tr>
                  <th>作成日時</th>
                  <td>
                    {sale.created_at &&
                      dateToString(sale.created_at, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <button
                  className="design_button"
                  onClick={() => setSaleEdit(true)}
                  disabled={isProcessing}
                >
                  編集
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isSaleEdit && (
        <Modal close={() => setSaleEdit(false)}>
          <SaleEdit
            sale={sale}
            close={() => {
              setSaleEdit(false);
              fetchCounpon();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default SaleDetail;
