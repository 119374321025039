// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import {
  getOfflineStoreOrderInfo,
  changeOfflineStoreOrderItemStatus,
  clearError,
  clearStatus,
} from "redux/actions/offlineStoreOrder";

// utils
import { dateToString, parseDeliveryTime } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// styles
import "./style.scss";

const OfflineStoreOrderDetail = () => {
  const { offlineStoreOrderOrderNumber } = useParams();
  const applicationId = applicationList.offline_store_orders.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const offlineStoreOrder = useSelector(
    (state) => state.offlineStoreOrder.info
  );
  const error = useSelector((state) => state.offlineStoreOrder.error);
  const isProcessing = useSelector(
    (state) => state.offlineStoreOrder.isProcessing
  );

  const [isWriteAllowed, setWriteAllowed] = useState(false);

  const dispatch = useDispatch();

  const handleChangeStatus = (itemId, status) => {
    let message = "";

    switch (status) {
      case "returning":
        message = "注文ステータスを返品対応中に変更しますか？";
        break;
      case "shipped":
        message = "返品対応を中止し、注文ステータスを配送完了に戻しますか？";
        break;
      case "return":
        message = "返品済み状態に変更しますか？";
        break;
      default:
    }

    if (!message) return;

    if (window.confirm(message)) {
      dispatch(changeOfflineStoreOrderItemStatus(token, itemId, status));
    }
  };

  const fetchOrder = () => {
    if (token) {
      dispatch(getOfflineStoreOrderInfo(token, offlineStoreOrderOrderNumber));
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    setWriteAllowed(checkPermission(myAccount, applicationId, "write"));
  }, [myAccount]);

  return (
    <>
      <div className="offline_sore_order_detail detail">
        <div className="list_back_container">
          <Link to="/offline-store-order/">一覧に戻る</Link>
        </div>
        {offlineStoreOrder.is_similar_order && (
          <div className="return_order_list_container">
            <p>過去返品実績のある注文と類似しています</p>
          </div>
        )}
        <div className="offline_store_order_flex">
          <div className="offline_store_order_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>伝票番号</th>
                  <td>{offlineStoreOrder.slip_number}</td>
                </tr>
                <tr>
                  <th>注文番号</th>
                  <td>{offlineStoreOrder.order_number}</td>
                </tr>
                <tr>
                  <th>注文日時</th>
                  <td>
                    {offlineStoreOrder.created_at &&
                      dateToString(
                        offlineStoreOrder.created_at,
                        "yyyy/MM/dd HH:mm"
                      )}
                  </td>
                </tr>
                <tr>
                  <th>購入店舗</th>
                  <td>{offlineStoreOrder.store_name}</td>
                </tr>
                <tr>
                  <th>氏名</th>
                  <td>{offlineStoreOrder.name}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>{offlineStoreOrder.phone_number}</td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>{offlineStoreOrder.postal_code}</td>
                </tr>
                <tr>
                  <th>都道府県</th>
                  <td>{offlineStoreOrder.province}</td>
                </tr>
                <tr>
                  <th>市区町村/町名</th>
                  <td>{offlineStoreOrder.city}</td>
                </tr>
                <tr>
                  <th>番地以降の住所</th>
                  <td>{offlineStoreOrder.address_line}</td>
                </tr>
                <tr>
                  <th>小計</th>
                  <td>
                    &yen;{offlineStoreOrder.sub_total_amount.toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <th>セール</th>
                  <td>
                    {offlineStoreOrder.sale_info && (
                      <>
                        {offlineStoreOrder.sale_info.name} （
                        {offlineStoreOrder.sale_info.discount_rate}%OFF）
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>割引額</th>
                  <td>
                    &yen;{offlineStoreOrder.discount_amount.toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <th>消費税</th>
                  <td>&yen;{offlineStoreOrder.tax_amount.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>送料</th>
                  <td>&yen;{offlineStoreOrder.carriage.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>合計</th>
                  <td>
                    &yen;{offlineStoreOrder.total_amount.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="offline_store_order_item_container">
            {offlineStoreOrder.items.map((item, index) => (
              <Item
                item={item}
                index={index + 1}
                itemCount={offlineStoreOrder.items.length}
                key={index}
                isWriteAllowed={isWriteAllowed}
                handleChangeStatus={handleChangeStatus}
              />
            ))}
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
    </>
  );
};

export default OfflineStoreOrderDetail;

const Item = (props) => {
  const { index, itemCount, isWriteAllowed, item, handleChangeStatus } = props;

  const {
    id,
    name,
    status,
    price,
    carriage,
    delivery_date,
    delivery_time,
    shipping_code,
  } = item;

  return (
    <div className="item">
      <div className="item_count">
        {index} / {itemCount}
      </div>
      <table>
        <tbody>
          <tr>
            <th>商品名</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>商品定価</th>
            <td>&yen;{price.toLocaleString()}</td>
          </tr>
          <tr>
            <th>送料</th>
            <td>&yen;{carriage.toLocaleString()}</td>
          </tr>
          <tr>
            <th>配送希望日</th>
            <td>
              {delivery_date || "希望なし"} {parseDeliveryTime(delivery_time)}
            </td>
          </tr>
          <tr>
            <th>配送状況</th>
            <td>{status}</td>
          </tr>
          <tr>
            <th>追跡番号</th>
            <td>{shipping_code || "未設定"}</td>
          </tr>
        </tbody>
      </table>
      {isWriteAllowed && (
        <div className="item_button_container">
          {status === "発送済" && (
            <button
              className="design_button"
              onClick={() => handleChangeStatus(id, "returning")}
            >
              返品対応中へ変更
            </button>
          )}

          {status === "返品対応中" && (
            <button
              className="design_button"
              onClick={() => handleChangeStatus(id, "shipped")}
            >
              返品対応中解除
            </button>
          )}

          {status === "返品対応中" && (
            <button
              className="design_button"
              onClick={() => handleChangeStatus(id, "return")}
            >
              返品済み
            </button>
          )}
        </div>
      )}
    </div>
  );
};
