import * as types from "../types/serverStatus";

const initialState = {
	status: "",
	isLoading: false,
	isCompleted: false,
	error: null,
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_SERVER_STATUS_REQUEST:
			return { ...state, isLoading: true, status: null };
		case types.GET_SERVER_STATUS_SUCCESS:
			return { ...state, isLoading: false, status: action.data.server_status };
		case types.GET_SERVER_STATUS_FAILURE:
			return { ...state, isLoading: false, status: null, error: action.error };
		case types.CHANGE_SERVER_STATUS_REQUEST:
			return { ...state, isLoading: true, isCompleted: false };
		case types.CHANGE_SERVER_STATUS_SUCCESS:
			return { ...state, isLoading: false, isCompleted: true };
		case types.CHANGE_SERVER_STATUS_FAILURE:
			return {
				...state,
				isLoading: false,
				isCompleted: false,
				error: action.error,
			};
		case types.CLEAR_SERVER_STATUS_STATUS:
			return initialState;
		case types.CLEAR_SERVER_STATUS_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
};

export default auth;
