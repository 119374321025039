export const GET_UGC_LIST_REQUEST = "GET_UGC_LIST_REQUEST";
export const GET_UGC_LIST_SUCCESS = "GET_UGC_LIST_SUCCESS";
export const GET_UGC_LIST_FAILURE = "GET_UGC_LIST_FAILURE";

export const GET_UGC_INFO_REQUEST = "GET_UGC_INFO_REQUEST";
export const GET_UGC_INFO_SUCCESS = "GET_UGC_INFO_SUCCESS";
export const GET_UGC_INFO_FAILURE = "GET_UGC_INFO_FAILURE";

export const CREATE_UGC_REQUEST = "CREATE_UGC_REQUEST";
export const CREATE_UGC_SUCCESS = "CREATE_UGC_SUCCESS";
export const CREATE_UGC_FAILURE = "CREATE_UGC_FAILURE";

export const UPDATE_UGC_REQUEST = "UPDATE_UGC_REQUEST";
export const UPDATE_UGC_SUCCESS = "UPDATE_UGC_SUCCESS";
export const UPDATE_UGC_FAILURE = "UPDATE_UGC_FAILURE";

export const CLEAR_UGC_STATUS = "CLEAR_UGC_STATUS";

export const SET_UGC_FILTER = "SET_UGC_FILTER";
export const CLEAR_UGC_FILTER = "CLEAR_UGC_FILTER";

export const CLEAR_UGC_ERROR = "CLEAR_UGC_ERROR";
export const CHANGE_UGC_PAGE = "CHANGE_UGC_PAGE";
