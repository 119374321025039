// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// component
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import CouponEdit from "./CouponEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import {
  getCouponInfo,
  clearError,
  adaptCoupon,
  clearStatus,
} from "redux/actions/coupon";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const CouponDetail = () => {
  const { couponCode } = useParams();
  const applicationId = applicationList.coupon.id;

  const [isCouponEdit, setCouponEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const coupon = useSelector((state) => state.coupon.info);
  const error = useSelector((state) => state.coupon.error);
  const isProcessing = useSelector((state) => state.coupon.isProcessing);
  const isAdaptComplete = useSelector((state) => state.coupon.isAdaptComplete);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const couponAdapt = () => {
    if (
      window.confirm(
        "3ヶ月以内に作成されたカートにクーポンを適用します。よろしいですか？"
      )
    ) {
      const data = {
        coupon_code: couponCode,
      };
      dispatch(adaptCoupon(token, data));
    }
  };

  const fetchCounpon = () => {
    dispatch(getCouponInfo(token, couponCode));
  };

  useEffect(() => {
    fetchCounpon();
  }, []);

  useEffect(() => {
    if (isAdaptComplete) {
      window.alert("クーポン適用リクエストが完了しました");
    }
    dispatch(clearStatus());
  }, [isAdaptComplete]);

  return (
    <>
      <div className="coupon_detail detail">
        <div className="list_back_container">
          <Link to="/coupon">一覧に戻る</Link>
        </div>
        <div className="coupon_flex">
          <div className="coupon_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>クーポンコード</th>
                  <td>{couponCode}</td>
                </tr>
                <tr>
                  <th>クーポン名</th>
                  <td>{coupon.name}</td>
                </tr>
                <tr>
                  <th>割引率</th>
                  <td>{coupon.discount_rate}%OFF</td>
                </tr>
                <tr>
                  <th>ステータス</th>
                  <td>{coupon.status ? "使用可能" : "使用不可"}</td>
                </tr>
                <tr>
                  <th>使用開始日時</th>
                  <td>
                    {coupon.start_date &&
                      dateToString(coupon.start_date, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
                <tr>
                  <th>使用期限</th>
                  <td>
                    {coupon.expiration_date &&
                      dateToString(coupon.expiration_date, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <>
                  <button
                    className="design_button"
                    onClick={() => setCouponEdit(true)}
                    disabled={isProcessing}
                  >
                    編集
                  </button>
                  <button
                    className="design_button"
                    onClick={() => couponAdapt()}
                    disabled={isProcessing}
                  >
                    買い物中のカートに適用する
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isCouponEdit && (
        <Modal close={() => setCouponEdit(false)}>
          <CouponEdit
            coupon={coupon}
            close={() => {
              setCouponEdit(false);
              fetchCounpon();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default CouponDetail;
