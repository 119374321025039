import * as types from "../types/businessContact";

const businessContactInfoInitialState = {
  id: "",
  name: "",
  corporation: "",
  phone: "",
  status: "",
  mail: "",
  text: "",
  route: "",
  is_catalog_downloaded: false,
  memo: "",
  created_at: "",
};

const filterInitialState = {
  name: "",
  mail: "",
  corporation: "",
  status: "",
  sortBy: "created_at",
  sortType: "desc",
};

const initialState = {
  info: businessContactInfoInitialState,
  businessContactList: {
    list: [],
    count: 0,
  },
  isUpdateComplete: false,
  currentPage: 1,
  isProcessing: false,
  filter: filterInitialState,
  error: null,
};

const businessContact = (state = initialState, action) => {
  switch (action.type) {
    // toB問い合わせ一覧取得
    case types.GET_BUSINESS_CONTACTS_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_BUSINESS_CONTACTS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        businessContactList: {
          list: action.data.business_contact_list,
          count: action.data.count,
        },
      };
    case types.GET_BUSINESS_CONTACTS_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // toB問い合わせ情報取得
    case types.GET_BUSINESS_CONTACT_INFO_REQUEST:
      return {
        ...state,
        isProcessing: true,
        info: businessContactInfoInitialState,
      };
    case types.GET_BUSINESS_CONTACT_INFO_SUCCESS:
      return { ...state, isProcessing: false, info: action.data.business_contact };
    case types.GET_BUSINESS_CONTACT_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // toB問い合わせ編集
    case types.UPDATE_BUSINESS_CONTACT_REQUEST:
      return {
        ...state,
        isProcessing: true,
        isUpdateComplete: false,
        error: null,
      };
    case types.UPDATE_BUSINESS_CONTACT_SUCCESS:
      return { ...state, isProcessing: false, isUpdateComplete: true };
    case types.UPDATE_BUSINESS_CONTACT_FAILURE:
      return {
        ...state,
        isProcessing: false,
        isUpdateComplete: false,
        error: action.error,
      };

    case types.CLEAR_BUSINESS_CONTACT_STATUS:
      return { ...state, isUpdateComplete: false, isCreateComplete: false, isAdaptComplete: false };
    case types.SET_BUSINESS_CONTACT_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_BUSINESS_CONTACT_ERROR:
      return { ...state, error: null };
    case types.CHANGE_BUSINESS_CONTACT_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default businessContact;
