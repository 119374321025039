// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateUgc, clearStatus } from "redux/actions/ugc";

// style
import "./style.scss";

const UgcMediaEdit = ({ ucgID, media, close }) => {
  const { is_video, video_url, image_url } = media;

  const [preview, setPreview] = useState({
    isVideo: is_video,
    url: is_video ? video_url : image_url,
  });
  const [previewErrorMessage, setPreviewErrorMessage] = useState(null);
  const [isCheck, setIsCheck] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.ugc.isProcessing);
  const isUpdateComplete = useSelector((state) => state.ugc.isUpdateComplete);
  const dispatch = useDispatch();

  const FIELDS = {
    mediaURL: "media_url",
  };

  let elements = {};

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("このコンテンツを設定しますか？")) {
      changeUgc();
    }
  };

  const changeUgc = () => {
    const data = {
      ugc_id: ucgID,
      main_media_url: elements[FIELDS.mediaURL].value,
    };

    if (token) {
      dispatch(updateUgc(token, data));
    }
  };

  const updatePreview = () => {
    setIsCheck(true);

    const url = elements[FIELDS.mediaURL].value;

    if (/\.jpeg|\.png|\.jpg/.test(url)) {
      setPreview({
        isVideo: false,
        url: url,
      });
      setPreviewErrorMessage(null);
    } else if (/\.mp4/.test(url)) {
      setPreview({
        isVideo: true,
        url: url,
      });
      setPreviewErrorMessage(null);
    } else {
      setPreviewErrorMessage("不正な形式");
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="ugc_media_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="ugc_item">
          <tbody>
            <tr>
              <th>画像または動画URL</th>
              <td>
                <input
                  type="text"
                  className="design_input"
                  name={FIELDS.mediaURL}
                  defaultValue={preview.url}
                  onChange={() => setIsCheck(false)}
                />
              </td>
            </tr>
            <tr>
              <th>
                プレビュー
                <button type="button" onClick={updatePreview}>
                  更新
                </button>
              </th>
              <td>
                {previewErrorMessage && <div>{previewErrorMessage}</div>}
                <div className="image_container">
                  {preview.isVideo ? (
                    <video
                      muted
                      loop
                      playsInline
                      autoPlay
                      onError={() =>
                        setPreviewErrorMessage("動画の読み込みに失敗しました")
                      }
                    >
                      <source src={preview.url} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={preview.url}
                      alt=""
                      onError={(e) =>
                        setPreviewErrorMessage("画像の読み込みに失敗しました")
                      }
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing || previewErrorMessage || !isCheck}
          />
          <div style={{ marginTop: "10px" }}>
            ※プレビューが正しく表示されることを確認してください
          </div>
        </div>
      </form>
    </div>
  );
};

export default UgcMediaEdit;
