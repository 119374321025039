export const applicationFields = {
  order: "order",
  stock: "stock",
  coupon: "coupon",
  sale: "sale",
  review: "review",
  news: "news",
  notice: "notice",
  faq: "faq",
  ugc: "ugc",
  gift: "gift",
  businesscontact: "businesscontact",
  serverstatus: "serverstatus",
  offlineStore: "offline_store",
  offlineStoreOrder: "offline_store_order",
  offlineStoreSale: "offline_store_sale",
  adminuser: "adminuser",
  setting: "setting",
};

export const applicationList = {
  order: {
    path: "/order",
    applicationName: "注文",
    id: "order",
  },
  stock: {
    path: "/stock",
    applicationName: "在庫",
    id: "stock",
  },
  coupon: {
    path: "/coupon",
    applicationName: "クーポン",
    id: "coupon",
  },
  sale: {
    path: "/sale",
    applicationName: "セール",
    id: "sale",
  },
  review: {
    path: "/review",
    applicationName: "レビュー",
    id: "review",
  },
  news: {
    path: "/news",
    applicationName: "ニュース",
    id: "news",
  },
  notice: {
    path: "/notice",
    applicationName: "お知らせ",
    id: "notice",
  },
  faq: {
    path: "/faq",
    applicationName: "FAQ",
    id: "faq",
  },
  ugc: {
    path: "/ugc",
    applicationName: "UGC",
    id: "ugc",
  },
  gift: {
    path: "/gift",
    applicationName: "ギフティング",
    id: "gift",
  },
  businesscontact: {
    path: "/businesscontact",
    applicationName: "toB問い合わせ",
    id: "businesscontact",
  },
  offline_store_stores: {
    path: "/offline-store",
    applicationName: "オフライン販売用店舗設定",
    id: "offline_store_stores",
  },
  offline_store_orders: {
    path: "/offline-store-order",
    applicationName: "オフライン注文",
    id: "offline_store_orders",
  },
  offline_store_sales: {
    path: "/offline-store-sale",
    applicationName: "オフライン販売用セール",
    id: "offline_store_sales",
  },
  serverstatus: {
    path: "/serverstatus",
    applicationName: "メンテナンス",
    id: "serverstatus",
  },
  adminuser: {
    path: "/adminuser",
    applicationName: "管理ユーザー",
    id: "adminuser",
  },
  setting: {
    path: "/setting",
    applicationName: "アカウント設定",
    id: null,
  },
};
