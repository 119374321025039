import * as types from "../types/offlineStore";

const offlineStoreInfoInitialState = {
  id: "",
  name: "",
  created_at: "",
};

const filterInitialState = {
  accountId: "",
  storeName: "",
  status: "default",
  sortBy: "created_at",
  sortType: "desc",
};

const initialState = {
  info: offlineStoreInfoInitialState,
  offlineStoreList: {
    list: [],
    count: 0,
  },
  password: null,
  isProcessing: false,
  filter: filterInitialState,
  isUpdateComplete: false,
  isCreateComplete: false,
  isOfflineStoreIsDeleteComplete: false,
  currentPage: 1,
  error: null,
};

const offlineStore = (state = initialState, action) => {
  switch (action.type) {
    // オフライン店舗一覧取得
    case types.GET_OFFLINE_STORES_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_OFFLINE_STORES_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        offlineStoreList: {
          list: action.data.stores,
          count: action.data.count,
        },
      };
    case types.GET_OFFLINE_STORES_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗情報取得
    case types.GET_OFFLINE_STORE_INFO_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_OFFLINE_STORE_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        info: action.data,
      };
    case types.GET_OFFLINE_STORE_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗作成
    case types.CREATE_OFFLINE_STORE_REQUEST:
      return { ...state, isProcessing: true };
    case types.CREATE_OFFLINE_STORE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        password: action.data.password,
        isCreateComplete: true,
      };
    case types.CREATE_OFFLINE_STORE_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗削除
    case types.DELETE_OFFLINE_STORE_REQUEST:
      return { ...state, isProcessing: true };
    case types.DELETE_OFFLINE_STORE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isOfflineStoreIsDeleteComplete: true,
      };
    case types.DELETE_OFFLINE_STORE_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン店舗パスワードリセット
    case types.RESET_PASSWORD_OFFLINE_STORE_REQUEST:
      return { ...state, isProcessing: true };
    case types.RESET_PASSWORD_OFFLINE_STORE_SUCCESS:
      return { ...state, isProcessing: false, password: action.data.password };
    case types.RESET_PASSWORD_OFFLINE_STORE_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    case types.CLEAR_OFFLINE_STORE_STATUS:
      return {
        ...state,
        isUpdateComplete: false,
        isCreateComplete: false,
        isOfflineStoreIsDeleteComplete: false,
        password: null,
      };
    case types.SET_OFFLINE_STORE_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_OFFLINE_STORE_FILTER:
      return { ...state, filter: filterInitialState };
    case types.CLEAR_OFFLINE_STORE_ERROR:
      return { ...state, error: null };
    case types.CHANGE_OFFLINE_STORE_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default offlineStore;
