import * as types from "../types/auth";

const initialState = {
	token: sessionStorage.getItem("token"),
	error: null,
	changePasswordComplete: false,
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case types.LOGIN_SUCCESS:
			const token = action.data.token;
			sessionStorage.setItem("token", token);
			return { ...state, token: token };
		case types.LOGIN_FAILURE:
			return { ...state, error: action.error };
		case types.LOGOUT_SUCCESS:
			return { ...state, token: null };
		case types.LOGOUT_FAILURE:
			return { ...state, error: action.error };
		case types.CHANGE_PASSWORD_SUCCESS:
			return { ...state, changePasswordComplete: true };
		case types.CHANGE_PASSWORD_FAILURE:
			return { ...state, error: action.error };
		case types.CLEAR_AUTH_STATUS:
			return { ...state, changePasswordComplete: false };
		case types.CLEAR_AUTH_ERROR:
			return { ...state, error: null };
		case types.CLEAR_AUTH_TOKEN:
			return { ...state, token: null };
		default:
			return state;
	}
};

export default auth;
