import * as types from "../types/offlineStoreOrder";

const offlineStoreOrderInfoInitialState = {
  slip_number: "",
  order_number: "",
  created_at: "",
  store_name: "",
  name: "",
  phone_number: "",
  postal_code: "",
  province: "",
  city: "",
  address_line: "",
  sub_total_amount: "",
  sale: null,
  discount_amount: "",
  carriage: "",
  tax_amount: "",
  total_amount: "",
  items: [],
  status_list: [],
  is_similar_order: false,
  is_can_change: null,
};

const filterInitialState = {
  slipNumber: "",
  orderNumber: "",
  name: "",
  storeName: "",
  orderDateStart: "",
  orderDateEnd: "",
  status: "",
  sortBy: "created_at",
  sortType: "desc",
};

const initialState = {
  info: offlineStoreOrderInfoInitialState,
  offlineStoreOrderList: {
    list: [],
    count: 0,
  },
  isProcessing: false,
  filter: filterInitialState,
  isUpdateComplete: false,
  isCreateComplete: false,
  currentPage: 1,
  error: null,
};

const offlineStoreOrder = (state = initialState, action) => {
  switch (action.type) {
    // オフライン注文一覧取得
    case types.GET_OFFLINE_STORE_ORDERS_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_OFFLINE_STORE_ORDERS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        offlineStoreOrderList: {
          list: action.data.orders,
          count: action.data.count,
        },
      };
    case types.GET_OFFLINE_STORE_ORDERS_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン注文情報取得
    case types.GET_OFFLINE_STORE_ORDER_INFO_REQUEST:
      return { ...state, isProcessing: true };
    case types.GET_OFFLINE_STORE_ORDER_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        info: action.data,
      };
    case types.GET_OFFLINE_STORE_ORDER_INFO_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    // オフライン注文ステータス変更
    case types.CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_REQUEST:
      return { ...state, isProcessing: true };
    case types.CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        info: action.data,
      };
    case types.CHANGE_OFFLINE_STORE_ORDER_ITEM_STATUS_FAILURE:
      return { ...state, isProcessing: false, error: action.error };

    case types.CLEAR_OFFLINE_STORE_ORDER_STATUS:
      return {
        ...state,
        isUpdateComplete: false,
        isCreateComplete: false,
      };
    case types.SET_OFFLINE_STORE_ORDER_FILTER:
      return { ...state, filter: action.data };
    case types.CLEAR_OFFLINE_STORE_ORDER_FILTER:
      return { ...state, filter: filterInitialState };
    case types.CLEAR_OFFLINE_STORE_ORDER_ERROR:
      return { ...state, error: null };
    case types.CHANGE_OFFLINE_STORE_ORDER_PAGE:
      return { ...state, currentPage: action.page };
    default:
      return state;
  }
};

export default offlineStoreOrder;
