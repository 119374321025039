export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const SET_USER_FILTER = "SET_USER_FILTER";
export const CLEAR_USER_FILTER = "CLEAR_USER_FILTER";

export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const CHANGE_USER_PAGE = "CHANGE_USER_PAGE";

export const CLEAR_USER_STATUS = "CLEAR_USER_STATUS";
