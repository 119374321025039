// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateSale, clearStatus } from "redux/actions/sale";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const ProductEdit = ({ sale, close }) => {
  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.sale.isProcessing);
  const isUpdateComplete = useSelector((state) => state.sale.isUpdateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    coupon: "coupon",
    startAt: "start_at",
    endAt: "end_at",
    status: "status",
  };

  let elements = {};

  const changeSale = () => {
    const data = {
      sale_id: sale.id,
      name: elements[FIELDS.name].value,
      coupon: elements[FIELDS.coupon].value,
      start_at: elements[FIELDS.startAt].value.slice(0, 16),
      end_at: elements[FIELDS.endAt].value.slice(0, 16),
      is_open: elements[FIELDS.status].value,
    };

    if (token) {
      dispatch(updateSale(token, data));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeSale();
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="sale_edit edit">
      <form
        className="sale_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>セール名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  defaultValue={sale.name}
                />
              </td>
            </tr>
            <tr>
              <th>クーポンコード</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.coupon}
                  defaultValue={sale.coupon.code}
                />
              </td>
            </tr>
            <tr>
              <th>開始日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.startAt}
                  defaultValue={
                    sale.start_at &&
                    dateToString(sale.start_at, "yyyy-MM-ddTHH:mm")
                  }
                />
              </td>
            </tr>
            <tr>
              <th>終了日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.endAt}
                  defaultValue={
                    sale.end_at && dateToString(sale.end_at, "yyyy-MM-ddTHH:mm")
                  }
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.status}
                  defaultValue={sale.is_open}
                >
                  <option value={true}>公開</option>
                  <option value={false}>非公開</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>
                {sale.created_at &&
                  dateToString(sale.created_at, "yyyy年MM月dd日 HH時mm分")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
