import * as types from "../types/myAccount";

const myAccountInfoInitialState = {
	id: "",
	name: "",
	is_super: false,
	permission_group: null,
	created_at: "",
};

const initialState = {
	info: myAccountInfoInitialState,
	isProcessing: false,
	error: null,
};

const myAccount = (state = initialState, action) => {
	switch (action.type) {

		// 管理ユーザ情報取得
		case types.GET_MY_ACCOUNT_INFO_REQUEST:
			return { ...state, isProcessing: true };
		case types.GET_MY_ACCOUNT_INFO_SUCCESS:
			return {
				...state,
				isProcessing: false,
				info: action.data.admin_user,
			};
		case types.GET_MY_ACCOUNT_INFO_FAILURE:
			return { ...state, isProcessing: false, error: action.error };
		case types.CLEAR_MY_ACCOUNT_ERROR:
			return { ...state, error: null };
		case types.CLEAR_MY_ACCOUNT_INFO:
			return { ...state, info: myAccountInfoInitialState };
		default:
			return state;
	}
};

export default myAccount;
