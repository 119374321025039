export const checkPermission = (myAccount, applicationId, type) => {
	if (myAccount.is_super) {
		return true;
	} else {
		if (applicationId) {
			if (myAccount.permission_group) {
				const targetPermission =
					myAccount.permission_group.permission_list.find(
						(permission) =>
							permission.application_id === applicationId
					);
				if (targetPermission) {
					switch (type) {
						case "read":
							return (
								targetPermission.type === "read" ||
								targetPermission.type === "write"
							);
						case "write":
							return targetPermission.type === "write";
						default:
							return false;
					}
				}
				return false;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
