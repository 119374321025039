// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { changeCoupon, clearStatus } from "redux/actions/order";

// style
import "./style.scss";

const CouponEdit = (props) => {
  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.order.isProcessing);
  const isChanged = useSelector((state) => state.order.isChangedCoupon);
  const dispatch = useDispatch();

  // params
  const { orderNumber, couponCode } = props;

  const FIELDS = {
    couponCode: "coupon_code",
  };

  let elements = {};

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      order_number: orderNumber,
      coupon_code: elements[FIELDS.couponCode].value,
    };

    if (window.confirm("編集内容を確定しますか？")) {
      dispatch(changeCoupon(token, data));
    }
  };

  useEffect(() => {
    if (isChanged) {
      window.alert(
        "クーポンの変更及び、割引分差額の返金リクエストが完了しました（差額がない場合は返金されません）"
      );
      dispatch(clearStatus());
      props.close();
    }
  }, [isChanged]);

  return (
    <div className="order_customer_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table>
          <tbody>
            <tr>
              <th>クーポンコード</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.couponCode}
                  defaultValue={couponCode}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default CouponEdit;
