// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import {
  updateFaq,
  clearStatus,
  getFaqCategories,
  getFaqProducts,
} from "redux/actions/faq";

// style
import "./style.scss";

const FaqEdit = (props) => {
  // params
  const { id, manage_id, question, answer, category, priority, is_open } =
    props.faq;

  const FIELDS = {
    manageID: "manage_id",
    question: "question",
    answer: "answer",
    categoryID: "category_id",
    faqProductID: "faq_product_id",
    isOpen: "is_open",
    priority: "priority",
  };

  let elements = {};

  const token = useSelector((state) => state.auth.token);
  const categoryList = useSelector((state) => state.faq.categoryList.list);
  const productList = useSelector((state) => state.faq.productList.list);
  const isProcessing = useSelector((state) => state.faq.isProcessing);
  const isUpdateComplete = useSelector((state) => state.faq.isUpdateComplete);

  const [inputAnswer, setInputAnswer] = useState(answer);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeFaq();
    }
  };

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const changeFaq = () => {
    const data = {
      faq_id: id,
      manage_id: elements[FIELDS.manageID].value,
      question: elements[FIELDS.question].value,
      answer: elements[FIELDS.answer].value,
      category_id: elements[FIELDS.categoryID].value,
      is_open: elements[FIELDS.isOpen].value,
      priority: elements[FIELDS.priority].value,
    };

    if (token) {
      dispatch(updateFaq(token, data));
    }
  };

  const fetchCategories = () => {
    const data = {
      faq_product_id: elements[FIELDS.faqProductID].value,
      offset: 0,
      limit: 100,
    };
    dispatch(getFaqCategories(token, data));
    elements[FIELDS.categoryID].value = "";
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  useEffect(() => {
    dispatch(getFaqProducts(token, { offset: 0, limit: 100 }));
  }, []);

  return (
    <div className="faq_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="faq_item">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.manageID}
                  defaultValue={manage_id}
                />
              </td>
            </tr>
            <tr>
              <th>質問</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.question}
                  defaultValue={question}
                />
              </td>
            </tr>
            <tr>
              <th>回答</th>
              <td>
                <div className="faq_answer_container">
                  <textarea
                    className="design_input"
                    name={FIELDS.answer}
                    defaultValue={answer}
                    onChange={(e) => setInputAnswer(e.target.value)}
                  />
                  <div
                    className="faq_detail_anwer_preview"
                    dangerouslySetInnerHTML={{ __html: inputAnswer }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>商品グループ</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.faqProductID}
                  onChange={fetchCategories}
                  defaultValue={category.faq_product.id}
                >
                  {productList.map((product) => (
                    <option value={product.id} key={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>カテゴリ</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.categoryID}
                  defaultValue={category.id}
                >
                  {categoryList.map((category) => (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  ))}
                  <option value={""}>-</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>優先度</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.priority}
                  defaultValue={priority}
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.isOpen}
                  defaultValue={is_open}
                >
                  <option value={true}>表示</option>
                  <option value={false}>非表示</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default FaqEdit;
