// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createUgc, clearStatus } from "redux/actions/ugc";

// style
import "./style.scss";

const UgcCreate = ({ close }) => {
  const [isAuto, setIsAuto] = useState(true);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.ugc.isProcessing);
  const isCreateComplete = useSelector((state) => state.ugc.isCreateComplete);
  const dispatch = useDispatch();

  const create = (data) => {
    if (window.confirm("作成しますか？")) {
      if (token) {
        dispatch(createUgc(token, data));
      }
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      close();
    }
  }, [isCreateComplete]);

  return (
    <>
      <div className="ugc_create create">
        <div>
          <label className="radio_button">
            <input
              name="is_auto"
              type="radio"
              checked={isAuto}
              onChange={() => setIsAuto(true)}
            />
            自動取得
          </label>
          <label className="radio_button">
            <input
              name="is_auto"
              type="radio"
              checked={!isAuto}
              onChange={() => setIsAuto(false)}
            />
            手動入力
          </label>
        </div>
        {isAuto ? (
          <AutoForm create={create} isProcessing={isProcessing} />
        ) : (
          <ManualForm create={create} isProcessing={isProcessing} />
        )}
      </div>
    </>
  );
};

export default UgcCreate;

const AutoForm = ({ create, isProcessing }) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const FIELDS = {
    isOpen: "is_open",
    url: "url",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      is_open: elements[FIELDS.isOpen].value,
      register_mode: "auto",
      url: elements[FIELDS.url].value,
    };

    create(data);
  };

  return (
    <form
      className="create_form"
      onSubmit={handleSubmit}
      onChange={handleChange}
      ref={(el) => (elements = el && el.elements)}
    >
      <table className="product_item">
        <tbody>
          <tr>
            <th>instagramURL</th>
            <td>
              <input className="design_input" name={FIELDS.url} type="text" />
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              <label className="radio_button">
                <input name={FIELDS.isOpen} type="radio" value={true} />
                公開
              </label>
              <label className="radio_button">
                <input name={FIELDS.isOpen} type="radio" value={false} />
                非公開
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="button_container">
        <input
          className="design_button"
          type="submit"
          value="作成"
          disabled={!isSubmitEnabled || isProcessing}
        />
      </div>
    </form>
  );
};

const ManualForm = ({ create, isProcessing }) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const FIELDS = {
    isOpen: "is_open",
    url: "url",
    name: "name",
    caption: "caption",
    postedAt: "posted_at",
    mediaUrl: "media_url",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      is_open: elements[FIELDS.isOpen].value,
      register_mode: "manual",
      url: elements[FIELDS.url].value,
      name: elements[FIELDS.name].value,
      caption: elements[FIELDS.caption].value,
      posted_at: elements[FIELDS.postedAt].value,
      media_url: elements[FIELDS.mediaUrl].value,
    };

    create(data);
  };

  return (
    <form
      className="create_form"
      onSubmit={handleSubmit}
      onChange={handleChange}
      ref={(el) => (elements = el && el.elements)}
    >
      <table className="product_item">
        <tbody>
          <tr>
            <th>instagramURL</th>
            <td>
              <input className="design_input" name={FIELDS.url} type="text" />
            </td>
          </tr>
          <tr>
            <th>投稿者名</th>
            <td>
              <input className="design_input" name={FIELDS.name} type="text" />
            </td>
          </tr>
          <tr>
            <th>内容</th>
            <td>
              <textarea
                className="design_input"
                name={FIELDS.caption}
                style={{ resize: "none", height: "30vh" }}
              />
            </td>
          </tr>
          <tr>
            <th>投稿日</th>
            <td>
              <input
                className="design_input"
                name={FIELDS.postedAt}
                type="date"
              />
            </td>
          </tr>
          <tr>
            <th>画像・動画URL</th>
            <td>
              <input
                className="design_input"
                name={FIELDS.mediaUrl}
                type="text"
              />
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              <label className="radio_button">
                <input name={FIELDS.isOpen} type="radio" value={true} />
                公開
              </label>
              <label className="radio_button">
                <input name={FIELDS.isOpen} type="radio" value={false} />
                非公開
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="button_container">
        <input
          className="design_button"
          type="submit"
          value="作成"
          disabled={!isSubmitEnabled || isProcessing}
        />
      </div>
    </form>
  );
};
