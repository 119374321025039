// libs
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import FaqProductCreate from "./Create";
import Pager from "components/commons/Pager";
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import {
  getFaqProducts,
  setFaqProductFilter,
  clearError,
  changePage,
} from "redux/actions/faq";

// utils
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const FaqProduct = () => {
  // 1ページに表示する注文の数
  const pageLimit = 20;
  const applicationId = applicationList.faq.id;

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const productList = useSelector((state) => state.faq.productList.list);
  const productCount = useSelector((state) => state.faq.productList.count);
  const isProcessing = useSelector((state) => state.faq.isProcessing);
  const productFilter = useSelector((state) => state.faq.productFilter);
  const error = useSelector((state) => state.faq.error);
  const currentPage = useSelector((state) => state.faq.currentPage);

  const [pageOffset, setPageOffset] = useState((currentPage - 1) * pageLimit);
  const [isCreate, setCreate] = useState(false);
  const [isEnabledSort, setEnabledSort] = useState(productFilter.sortBy);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const statusDictionary = {
    default: null,
    public: true,
    closed: false,
  };

  const FIELDS = {
    status: "status",
    sortBy: "sort_by",
    sortType: "sort_type",
  };

  let elements = {};

  const selectPage = (page) => {
    dispatch(changePage(page));
    setPageOffset((page - 1) * pageLimit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(changePage(1));

    search(0);
  };

  const search = (offset) => {
    const data = {
      is_open: statusDictionary[elements[FIELDS.status].value],
      sort_by: elements[FIELDS.sortBy].value,
      sort_type: elements[FIELDS.sortType].value,
      limit: pageLimit,
      offset: offset,
    };

    if (token) {
      dispatch(getFaqProducts(token, data));
    }

    const filterData = {
      status: elements[FIELDS.status].value,
      sortBy: elements[FIELDS.sortBy].value,
      sortType: elements[FIELDS.sortType].value,
    };

    dispatch(setFaqProductFilter(filterData));
  };

  const selectItem = (id) => {
    navigate("/faq/product/" + id);
  };

  useEffect(() => {
    search(pageOffset);
  }, [pageOffset]);

  return (
    <>
      <div className="faq_product_list list">
        <div>
          <Link to="/faq">FAQ一覧へ</Link>
          <br />
          <Link to="/faq/category">カテゴリ一覧へ</Link>
          <br />
          <div>商品グループ一覧</div>
        </div>
        <form
          className="filter_form"
          onSubmit={handleSubmit}
          ref={(el) => (elements = el && el.elements)}
        >
          <table>
            <tbody>
              <tr>
                <th>ステータス</th>
                <td>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="public"
                      defaultChecked={productFilter.status === "public"}
                    />
                    表示
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="closed"
                      defaultChecked={productFilter.status === "closed"}
                    />
                    非表示
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.status}
                      type="radio"
                      value="default"
                      defaultChecked={productFilter.status === "default"}
                    />
                    フィルタなし
                  </label>
                </td>
              </tr>
              <tr>
                <th>表示順</th>
                <td>
                  <select
                    className="design_select"
                    name={FIELDS.sortBy}
                    defaultValue={productFilter.sortBy}
                    onChange={(e) => setEnabledSort(e.target.value)}
                  >
                    <option value="priority">優先度</option>
                    <option value="created_at">作成日</option>
                    <option value="">なし</option>
                  </select>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="asc"
                      defaultChecked={productFilter.sortType === "asc"}
                      disabled={!isEnabledSort}
                    />
                    昇順
                  </label>
                  <label className="radio_button">
                    <input
                      name={FIELDS.sortType}
                      type="radio"
                      value="desc"
                      defaultChecked={productFilter.sortType === "desc"}
                      disabled={!isEnabledSort}
                    />
                    降順
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="search_button_container">
            <input
              className="design_button"
              type="submit"
              value="検索"
              style={{ marginTop: "3vh" }}
              disabled={isProcessing}
            ></input>
            {writeIsAllowed && (
              <button
                className="design_button create_button"
                onClick={() => setCreate(true)}
                disabled={isProcessing}
              >
                作成
              </button>
            )}
          </div>
        </form>
        <div className="list_table_container">
          <table className="list_table">
            <thead>
              <tr>
                <th className="manage_id">ID</th>
                <th className="name">商品グループ名</th>
                <th className="is_open">表示 / 非表示</th>
                <th className="priority">表示優先度</th>
              </tr>
            </thead>
            <tbody>
              {productList.map((product) => (
                <FaqProductItem
                  product={product}
                  selectItem={selectItem}
                  key={product.id}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pager_container">
          <Pager
            pageCount={Math.ceil(productCount / pageLimit)}
            currentIndex={currentPage - 1}
            changeIndex={(index) => selectPage(index + 1)}
          />
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isCreate && (
        <Modal close={() => setCreate(false)}>
          <FaqProductCreate close={() => setCreate(false)} />
        </Modal>
      )}
    </>
  );
};

export default FaqProduct;

const FaqProductItem = (props) => {
  const { id, manage_id, name, is_open, priority } = props.product;

  const { selectItem } = props;

  return (
    <tr className="list_row" onClick={() => selectItem(id)}>
      <td>{manage_id ? manage_id : "未設定"}</td>
      <td>{name}</td>
      <td>{is_open ? "表示" : "非表示"}</td>
      <td>{priority ? priority : "未設定"}</td>
    </tr>
  );
};
