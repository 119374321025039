// libs
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// utils
import { checkPermission } from "utils/checkPermission";

// consts
import { applicationList } from "constants/application";

// styles
import "./style.scss";

const Layout = (props) => {
  const myAccount = useSelector((state) => state.myAccount.info);
  const location = useLocation();
  const [selectedApp, setSelectedApp] = useState(
    location.pathname.split("/").length >= 2
      ? location.pathname.split("/")[1]
      : ""
  );
  const navigate = useNavigate();

  const handleClick = (key) => {
    sessionStorage.setItem("menu_index", key);
    navigate(applicationList[key].path);
    setSelectedApp(key);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedApp]);

  const readIsAllowed = (applicationId) => {
    return checkPermission(myAccount, applicationId, "read");
  };

  return (
    <div className="layout">
      <header className="header">
        <div className="title_container">
          <a
            href={
              process.env.REACT_APP_MODE === "development"
                ? "https://dev.nell.life/"
                : "https://nell.life/"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/nell-logo-white.svg`}
              alt="logo"
              width={100}
              height={13}
            />
          </a>
          <span>
            管理ポータル
            {process.env.REACT_APP_MODE === "development" && (
              <span>（開発環境）</span>
            )}
          </span>
        </div>
        <div className="user_container">
          <div className="name">ログインユーザ名</div>
          <a href="/setting">{myAccount.name}</a>
        </div>
      </header>
      <div className="menu">
        <nav>
          <ul>
            {Object.keys(applicationList).map(
              (key, index) =>
                readIsAllowed(applicationList[key].id) && (
                  <NavListItem
                    name={applicationList[key].applicationName}
                    isSelected={key === selectedApp}
                    click={() => handleClick(key)}
                    key={index}
                  />
                )
            )}
          </ul>
        </nav>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;

const NavListItem = (props) => {
  const { name, isSelected, click } = props;
  return (
    <li className={isSelected ? "selected" : ""} onClick={() => click()}>
      {name}
    </li>
  );
};
