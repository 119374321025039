// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateUgc, clearStatus } from "redux/actions/ugc";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const UgcEdit = ({ ugc, close }) => {
  const isAuto = ugc.register_type === "auto";

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.ugc.isProcessing);
  const isUpdateComplete = useSelector((state) => state.ugc.isUpdateComplete);
  const dispatch = useDispatch();

  const changeUgc = (data) => {
    if (window.confirm("編集を確定しますか？")) {
      if (token) {
        dispatch(updateUgc(token, data));
      }
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="ugc_edit edit">
      {isAuto ? (
        <AutoForm ugc={ugc} change={changeUgc} isProcessing={isProcessing} />
      ) : (
        <ManualForm ugc={ugc} change={changeUgc} isProcessing={isProcessing} />
      )}
    </div>
  );
};

export default UgcEdit;

const AutoForm = ({ ugc, change, isProcessing }) => {
  const FIELDS = {
    caption: "caption",
    isOpen: "is_open",
  };

  let elements = {};

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ugc_id: ugc.id,
      caption: elements[FIELDS.caption].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    change(data);
  };

  return (
    <form
      className="edit_form"
      onSubmit={handleSubmit}
      ref={(el) => (elements = el && el.elements)}
    >
      <table className="ugc_item">
        <tbody>
          <tr>
            <th>内容</th>
            <td>
              <textarea
                className="design_input"
                name={FIELDS.caption}
                style={{ resize: "none", height: "30vh" }}
                defaultValue={ugc.caption}
              />
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              <select
                className="design_select"
                name={FIELDS.isOpen}
                defaultValue={ugc.is_open}
              >
                <option value={true}>公開</option>
                <option value={false}>非公開</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="button_container">
        <input
          className="design_button"
          type="submit"
          value="編集内容を確定"
          disabled={isProcessing}
        />
      </div>
      <div className="edit_caution">
        このコンテンツは自動取得のため、内容・ステータス以外は変更できません
      </div>
    </form>
  );
};

const ManualForm = ({ ugc, change, isProcessing }) => {
  const FIELDS = {
    url: "url",
    name: "name",
    caption: "caption",
    postedAt: "posted_at",
    isOpen: "is_open",
  };

  let elements = {};

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ugc_id: ugc.id,
      url: elements[FIELDS.url].value,
      name: elements[FIELDS.name].value,
      caption: elements[FIELDS.caption].value,
      posted_at: elements[FIELDS.postedAt].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    change(data);
  };

  return (
    <form
      className="edit_form"
      onSubmit={handleSubmit}
      ref={(el) => (elements = el && el.elements)}
    >
      <table className="ugc_item">
        <tbody>
          <tr>
            <th>投稿者</th>
            <td>
              <input
                type="text"
                className="design_input"
                name={FIELDS.name}
                defaultValue={ugc.name}
              />
            </td>
          </tr>
          <tr>
            <th>内容</th>
            <td>
              <textarea
                className="design_input"
                name={FIELDS.caption}
                style={{ resize: "none", height: "30vh" }}
                defaultValue={ugc.caption}
              />
            </td>
          </tr>
          <tr>
            <th>instagramURL</th>
            <td>
              <input
                type="text"
                className="design_input"
                name={FIELDS.url}
                defaultValue={ugc.url}
              />
            </td>
          </tr>
          <tr>
            <th>投稿日</th>
            <td>
              <input
                className="design_input"
                type="date"
                name={FIELDS.postedAt}
                defaultValue={
                  ugc.posted_at && dateToString(ugc.posted_at, "yyyy-MM-dd")
                }
              />
            </td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td>
              <select
                className="design_select"
                name={FIELDS.isOpen}
                defaultValue={ugc.is_open}
              >
                <option value={true}>公開</option>
                <option value={false}>非公開</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="button_container">
        <input
          className="design_button"
          type="submit"
          value="編集内容を確定"
          disabled={isProcessing}
        />
      </div>
    </form>
  );
};
