// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  createGift,
  clearStatus,
  getGiftProductDeliveryDate,
} from "redux/actions/gift";

// constants
import { productList, pillow } from "constants/products";
import { provinceList } from "constants/provinces";

// component
import GiftProductFormItem from "./GiftProductFormItem";

// style
import "./style.scss";

const GiftCreate = ({ giftLabelList, close }) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isShipEnabled, setIsShipEnabled] = useState(false);
  const [isShip, setIsShip] = useState(false);
  const [currentPostalCode, setCurrentPostalCode] = useState("");
  const [isShowNewLabelForm, setIsShowNewLabelForm] = useState(false);
  const [targetProductList, setTargetProductList] = useState([]);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isCreateComplete = useSelector((state) => state.gift.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    postalCode: "postal_code",
    province: "province",
    cityAddressLine: "city_address_line",
    phoneNumber: "phone_number",
    label: "label",
    memo: "memo",
    isShowNewLabelForm: "is_show_new_label_form",
    ship: "ship",
  };

  const PRODUCT_FIELDS = {
    quantity: "quantity",
    shipmentID: "shipment_id",
    trackingCode: "tracking_code",
    deliveryDate: "delivery_date",
    deliveryTime: "delivery_time",
  };

  let elements = {};

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyAddressFields = Object.values(FIELDS).every((field) => {
      switch (field) {
        case FIELDS.name:
        case FIELDS.postalCode:
        case FIELDS.province:
        case FIELDS.cityAddressLine:
        case FIELDS.phoneNumber:
          return elements[field].value;
        case FIELDS.label:
        case FIELDS.memo:
        case FIELDS.isShowNewLabelForm:
          return true;
        default:
          return true;
      }
    });

    setIsShipEnabled(isNoEmptyAddressFields);

    let isNoEmptyProductFields = true;

    if (elements[FIELDS.ship].checked) {
      const isAllEmpty = productList.every((productInfo) => {
        const field = productInfo.productID + "_" + PRODUCT_FIELDS.quantity;
        return field in elements ? parseInt(elements[field].value) === 0 : true;
      });
      isNoEmptyProductFields = !isAllEmpty;
    }

    setIsSubmitEnabled(isNoEmptyAddressFields && isNoEmptyProductFields);
  };

  const handleChangeShip = (check) => {
    setTargetProductList([]);
    setIsShip(check);
  };

  const handleChangeQuantity = (productID, quantity) => {
    setTargetProductList((prevList) => {
      const index = prevList.findIndex(
        (target) => target.product_id === productID
      );

      if (index !== -1) {
        const updateList = [...prevList];
        updateList[index].quantity = quantity;
        return updateList;
      } else {
        return [...prevList, { product_id: productID, quantity }];
      }
    });
  };

  useEffect(() => {
    if (token && targetProductList.length !== 0) {
      const data = {
        product_list: targetProductList,
        postal_code: currentPostalCode,
      };
      dispatch(getGiftProductDeliveryDate(token, data));
    }
  }, [token, targetProductList, currentPostalCode]);

  const handleChange = () => {
    setSubmitEnabled();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const convertProductData = (list) => {
    return list
      .filter((target) => target.quantity !== 0)
      .map((target) => {
        const productID = target.product_id;
        const quantity = target.quantity;

        const deliveryDateField = productID + "_" + PRODUCT_FIELDS.deliveryDate;
        const deliveryDate = elements[deliveryDateField]
          ? elements[deliveryDateField].value
          : "";

        const deliveryTimeField = productID + "_" + PRODUCT_FIELDS.deliveryTime;
        const deliveryTime = elements[deliveryTimeField]
          ? elements[deliveryTimeField].value
          : "";

        return {
          product_id: productID,
          quantity,
          delivery_date: deliveryDate,
          delivery_time: deliveryTime,
        };
      });
  };

  const create = () => {
    const data = {
      name: elements[FIELDS.name].value,
      postal_code: elements[FIELDS.postalCode].value,
      province: elements[FIELDS.province].value,
      city_address_line: elements[FIELDS.cityAddressLine].value,
      phone_number: elements[FIELDS.phoneNumber].value,
      label: elements[FIELDS.label].value,
      memo: elements[FIELDS.memo].value,
      product_list: convertProductData(targetProductList),
    };

    if (token) {
      dispatch(createGift(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      close();
    }
  }, [isCreateComplete]);

  return (
    <div className="gift_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>氏名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  disabled={isShip}
                />
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.postalCode}
                  onChange={(e) => setCurrentPostalCode(e.target.value)}
                  disabled={isShip}
                />
              </td>
            </tr>
            <tr>
              <th>都道府県</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.province}
                  disabled={isShip}
                >
                  {provinceList.map((province) => (
                    <option value={province} key={province}>
                      {province}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>都道府県以降の住所</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.cityAddressLine}
                  disabled={isShip}
                />
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.phoneNumber}
                  disabled={isShip}
                />
              </td>
            </tr>
            <tr>
              <th>ラベル</th>
              <td>
                <input
                  className=""
                  name={FIELDS.is_show_new_label_form}
                  type="checkbox"
                  onChange={(e) => setIsShowNewLabelForm(e.target.checked)}
                />
                新規ラベルを登録
                <div className="label_form">
                  {isShowNewLabelForm ? (
                    <input
                      className="design_input"
                      type="text"
                      name={FIELDS.label}
                    />
                  ) : (
                    <select
                      className="design_select"
                      name={FIELDS.label}
                      defaultValue=""
                    >
                      <option value="">-</option>
                      {giftLabelList.map((label, index) => (
                        <option value={label} key={index}>
                          {label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <th>メモ</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.memo}
                  disabled={isShip}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <label>
          <input
            type="checkbox"
            name={FIELDS.ship}
            onChange={(e) => handleChangeShip(e.target.checked)}
            disabled={!isShipEnabled}
          />
          登録と同時に配送
        </label>
        {isShip && (
          <>
            {productList
              .filter((product) => product.productID !== pillow.productID)
              .map((productInfo, index) => (
                <GiftProductFormItem
                  productInfo={productInfo}
                  changeQuantity={handleChangeQuantity}
                  key={index}
                />
              ))}
          </>
        )}
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default GiftCreate;
