export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAILURE = "GET_COUPONS_FAILURE";

export const GET_COUPON_INFO_REQUEST = "GET_COUPON_INFO_REQUEST";
export const GET_COUPON_INFO_SUCCESS = "GET_COUPON_INFO_SUCCESS";
export const GET_COUPON_INFO_FAILURE = "GET_COUPON_INFO_FAILURE";

export const GET_COUPON_ORDERS_REQUEST = "GET_COUPON_ORDERS_REQUEST";
export const GET_COUPON_ORDERS_SUCCESS = "GET_COUPON_ORDERS_SUCCESS";
export const GET_COUPON_ORDERS_FAILURE = "GET_COUPON_ORDERS_FAILURE";

export const GET_COUPON_ADAPT_REQUEST = "GET_COUPON_ADAPT_REQUEST";
export const GET_COUPON_ADAPT_SUCCESS = "GET_COUPON_ADAPT_SUCCESS";
export const GET_COUPON_ADAPT_FAILURE = "GET_COUPON_ADAPT_FAILURE";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILURE = "CREATE_COUPON_FAILURE";

export const UPDATE_COUPON_REQUEST = "UPDATE_COUPON_REQUEST";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILURE = "UPDATE_COUPON_FAILURE";

export const ADAPT_COUPON_REQUEST = "ADAPT_COUPON_REQUEST";
export const ADAPT_COUPON_SUCCESS = "ADAPT_COUPON_SUCCESS";
export const ADAPT_COUPON_FAILURE = "ADAPT_COUPON_FAILURE";

export const CLEAR_COUPON_STATUS = "CLEAR_COUPON_STATUS";

export const SET_COUPON_FILTER = "SET_COUPON_FILTER";
export const CLEAR_COUPON_FILTER = "CLEAR_COUPON_FILTER";

export const CLEAR_COUPN_ERROR = "CLEAR_COUPN_ERROR";
export const CHANGE_COUPON_PAGE = "CHANGE_COUPON_PAGE";
