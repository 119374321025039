// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import AdminUserPermissionGroupEdit from "./AdminUserPermissionGroupEdit";

// action
import {
  getPermissionGroupInfo,
  permissionGroupClearError,
  deletePermissionGroup,
  permissionGroupClearStatus,
} from "redux/actions/adminUser";
import { getMyAccountInfo } from "redux/actions/myAccount";
import { logout } from "redux/actions/auth";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const AdminUserPermissionGroupDetail = () => {
  const { adminUserPermissionGroupId } = useParams();
  const applicationId = applicationList.adminuser.id;

  const [isAdminUserPermissionGroupEdit, setAdminUserPermissionGroupEdit] =
    useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const permissionGroup = useSelector(
    (state) => state.adminUser.permissionGroupInfo
  );
  const permissionGroupError = useSelector(
    (state) => state.adminUser.permissionGroupError
  );
  const permissionGroupIsProcessing = useSelector(
    (state) => state.adminUser.permissionGroupIsProcessing
  );
  const permissionGroupIsDeleteComplete = useSelector(
    (state) => state.adminUser.permissionGroupIsDeleteComplete
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const getPermissionType = (applicationId) => {
    if (permissionGroup) {
      const targetPermission = permissionGroup.permission_list.find(
        (permission) => permission.application_id === applicationId
      );
      const permissionType = targetPermission ? targetPermission.type : "";
      return permissionType;
    } else {
      return "";
    }
  };

  const deletePermission = () => {
    const data = {
      permission_group_id: adminUserPermissionGroupId,
    };

    let confirmMessage =
      "権限グループを削除しようとしています。よろしいですか？";
    if (myAccount.permission_group) {
      if (
        myAccount.permission_group.id === parseInt(adminUserPermissionGroupId)
      ) {
        confirmMessage =
          "ご利用中のアカウントで使用している権限グループを削除しようとしています。よろしいですか？";
      }
    }
    if (window.confirm(confirmMessage)) {
      dispatch(deletePermissionGroup(token, data));
    }
  };

  const fetchAdminUserPermissionGroup = () => {
    dispatch(getPermissionGroupInfo(token, adminUserPermissionGroupId));
  };

  useEffect(() => {
    if (permissionGroupIsDeleteComplete) {
      window.alert("削除しました");
      dispatch(permissionGroupClearStatus());
      if (myAccount.permission_group) {
        if (myAccount.permission_group.id === adminUserPermissionGroupId) {
          dispatch(getMyAccountInfo(token));
        }
      }

      navigate("/adminuser/permissiongroup/");
    }
  }, [permissionGroupIsDeleteComplete]);

  useEffect(() => {
    fetchAdminUserPermissionGroup();
  }, []);

  useEffect(() => {
    if (permissionGroupError) {
      switch (permissionGroupError.response.data.code) {
        case 1000:
          window.alert("再度ログインしてください");
          dispatch(logout(token));
          break;
        case 1003:
          window.alert("権限がありません");
          break;
        case 1005:
          window.alert("権限リストが不正です");
          break;
        case 1006:
          window.alert("不正な権限グループIDです");
          break;
        case 1007:
          window.alert("不正な名前です");
          break;
        default:
          window.alert("予期せぬエラーが発生しました");
          break;
      }
      dispatch(permissionGroupClearError());
    }
  }, [permissionGroupError]);

  return (
    <>
      <div className="admin_user_detail detail">
        <div className="list_back_container">
          <Link to="/adminuser/permissiongroup/">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>名前</th>
              <td>{permissionGroup.name}</td>
            </tr>
            <tr>
              <th>権限</th>
              <td className="permission_list_container">
                <table>
                  <tbody>
                    {Object.keys(applicationList).map(
                      (applicationId, index) =>
                        applicationList[applicationId].id && (
                          <tr key={index} className="permission_container">
                            <th>
                              {applicationList[applicationId].applicationName}
                            </th>
                            {getPermissionType(applicationId) === "" && (
                              <td className="no_permission permission_type">
                                なし
                              </td>
                            )}
                            {getPermissionType(applicationId) === "read" && (
                              <td className="read_permission permission_type">
                                読み込み
                              </td>
                            )}
                            {getPermissionType(applicationId) === "write" && (
                              <td className="write_permission permission_type">
                                読み込み・書き込み
                              </td>
                            )}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>
                {dateToString(permissionGroup.created_at, "yyyy/MM/dd HH:mm")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <>
              <button
                className="design_button"
                onClick={() => setAdminUserPermissionGroupEdit(true)}
                disabled={permissionGroupIsProcessing}
              >
                編集
              </button>
              <button
                className="design_button delete_button"
                onClick={() => deletePermission()}
                disabled={permissionGroupIsProcessing}
              >
                削除
              </button>
            </>
          )}
        </div>
      </div>
      {permissionGroupIsProcessing && <Loader />}
      {isAdminUserPermissionGroupEdit && (
        <Modal close={() => setAdminUserPermissionGroupEdit(false)}>
          <AdminUserPermissionGroupEdit
            permissionGroup={permissionGroup}
            close={() => {
              setAdminUserPermissionGroupEdit(false);
              fetchAdminUserPermissionGroup();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default AdminUserPermissionGroupDetail;
