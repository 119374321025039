// libs
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// components
import Auth from "components/Auth";
import Layout from "components/pages/Layout";
import Login from "components/pages/Login";
import Order from "components/pages/Order";
import OrderDetail from "components/pages/OrderDetail";
import Coupon from "components/pages/Coupon";
import CouponDetail from "components/pages/CouponDetail";
import Review from "components/pages/Review";
import ReviewDetail from "components/pages/ReviewDetail";
import News from "components/pages/News";
import NewsDetail from "components/pages/NewsDetail";
import Stock from "components/pages/Stock";
import StockDetail from "components/pages/StockDetail";
import Notice from "components/pages/Notice";
import NoticeDetail from "components/pages/NoticeDetail";
import Faq from "components/pages/Faq";
import FaqDetail from "components/pages/FaqDetail";
import FaqCategory from "components/pages/FaqCategory";
import FaqCategoryDetail from "components/pages/FaqCategoryDetail";
import FaqProduct from "components/pages/FaqProduct";
import FaqProductDetail from "components/pages/FaqProductDetail";
import Sale from "components/pages/Sale";
import SaleDetail from "components/pages/SaleDetail";
import Ugc from "components/pages/Ugc";
import UgcDetail from "components/pages/UgcDetail";
import Gift from "components/pages/Gift";
import GiftDetail from "components/pages/GiftDetail";
import BusinessContact from "components/pages/BusinessContact";
import BusinessContactDetail from "components/pages/BusinessContactDetail";
import OfflineStore from "components/pages/OfflineStore";
import OfflineStoreDetail from "components/pages/OfflineStoreDetail";
import OfflineStoreOrder from "components/pages/OfflineStoreOrder";
import OfflineStoreOrderDetail from "components/pages/OfflineStoreOrderDetail";
import OfflineStoreSale from "components/pages/OfflineStoreSale";
import OfflineStoreSaleDetail from "components/pages/OfflineStoreSaleDetail";
import Setting from "components/pages/Setting";
import ServerStatus from "components/pages/ServerStatus";
import AdminUser from "components/pages/AdminUser";
import AdminUserDetail from "components/pages/AdminUserDetail";
import AdminUserPermissionGroup from "components/pages/AdminUserPermissionGroup";
import AdminUserPermissionGroupDetail from "components/pages/AdminUserPermissionGroupDetail";

// styles
import "./style.scss";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path="/*"
          element={
            <Auth>
              <Layout>
                <Routes>
                  <Route path="order" element={<Order />} />
                  <Route path="order/:orderId" element={<OrderDetail />} />
                  <Route path="coupon" element={<Coupon />} />
                  <Route path="coupon/:couponCode" element={<CouponDetail />} />
                  <Route path="review" element={<Review />} />
                  <Route path="review/:reviewId" element={<ReviewDetail />} />
                  <Route path="news" element={<News />} />
                  <Route path="news/:newsId" element={<NewsDetail />} />
                  <Route path="stock" element={<Stock />} />
                  <Route path="stock/:stockId" element={<StockDetail />} />
                  <Route path="notice" element={<Notice />} />
                  <Route path="notice/:noticeId" element={<NoticeDetail />} />
                  <Route path="ugc" element={<Ugc />} />
                  <Route path="ugc/:ugcId" element={<UgcDetail />} />
                  <Route path="faq" element={<Faq />} />
                  <Route path="faq/:faqId" element={<FaqDetail />} />
                  <Route path="faq/product" element={<FaqProduct />} />
                  <Route
                    path="faq/product/:faqProductId"
                    element={<FaqProductDetail />}
                  />
                  <Route path="faq/category" element={<FaqCategory />} />
                  <Route
                    path="faq/category/:faqCategoryId"
                    element={<FaqCategoryDetail />}
                  />
                  <Route path="sale" element={<Sale />} />
                  <Route path="sale/:saleId" element={<SaleDetail />} />
                  <Route path="gift" element={<Gift />} />
                  <Route path="gift/:giftId" element={<GiftDetail />} />
                  <Route path="businesscontact" element={<BusinessContact />} />
                  <Route
                    path="businesscontact/:businessContactId"
                    element={<BusinessContactDetail />}
                  />
                  <Route path="offline-store" element={<OfflineStore />} />
                  <Route
                    path="offline-store/:offlineStoreId"
                    element={<OfflineStoreDetail />}
                  />
                  <Route
                    path="offline-store-order"
                    element={<OfflineStoreOrder />}
                  />
                  <Route
                    path="offline-store-order/:offlineStoreOrderOrderNumber"
                    element={<OfflineStoreOrderDetail />}
                  />
                  <Route
                    path="offline-store-sale"
                    element={<OfflineStoreSale />}
                  />
                  <Route
                    path="offline-store-sale/:offlineStoreSaleId"
                    element={<OfflineStoreSaleDetail />}
                  />

                  <Route path="adminuser" element={<AdminUser />} />
                  <Route
                    path="adminuser/:adminUserId"
                    element={<AdminUserDetail />}
                  />
                  <Route
                    path="adminuser/permissiongroup"
                    element={<AdminUserPermissionGroup />}
                  />
                  <Route
                    path="adminuser/permissiongroup/:adminUserPermissionGroupId"
                    element={<AdminUserPermissionGroupDetail />}
                  />
                  <Route path="setting" element={<Setting />} />
                  <Route path="serverstatus" element={<ServerStatus />} />
                </Routes>
              </Layout>
            </Auth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
