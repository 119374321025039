// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import Edit from "./Edit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import { getNewsInfo, clearError } from "redux/actions/news";

// utils
import { dateToString } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const NewsDetail = () => {
  const { newsId } = useParams();
  const applicationId = applicationList.news.id;

  const [isNewsEdit, setNewsEdit] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const news = useSelector((state) => state.news.info);
  const error = useSelector((state) => state.news.error);
  const isProcessing = useSelector((state) => state.news.isProcessing);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchNewsInfo = () => {
    dispatch(getNewsInfo(token, newsId));
  };

  useEffect(() => {
    fetchNewsInfo();
  }, []);

  return (
    <>
      <div className="news_detail detail">
        <div className="list_back_container">
          <Link to="/news">一覧に戻る</Link>
        </div>
        <table className="detail_table">
          <tbody>
            <tr>
              <th>タイトル</th>
              <td>{news.title}</td>
            </tr>
            <tr>
              <th>説明</th>
              <td>{news.description}</td>
            </tr>
            <tr>
              <th>記事URL</th>
              <td>{news.url}</td>
            </tr>
            <tr>
              <th>タグ</th>
              <td>{news.tags.join(",")}</td>
            </tr>
            <tr>
              <th>掲載日時</th>
              <td>{dateToString(news.posted_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(news.created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>{news.is_open ? "公開" : "非公開"}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          {writeIsAllowed && (
            <button className="design_button" onClick={() => setNewsEdit(true)}>
              編集
            </button>
          )}
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isNewsEdit && (
        <Modal close={() => setNewsEdit(false)}>
          <Edit
            news={news}
            close={() => {
              setNewsEdit(false);
              fetchNewsInfo();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default NewsDetail;
