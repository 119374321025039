// libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import ErrorMessage from "components/commons/ErrorMessage";

// actions
import {
  changePassword,
  logout,
  clearError,
  clearStatus,
} from "redux/actions/auth";
import { clearInfo } from "redux/actions/myAccount";

// utils
import { passwordRegex, createPassword } from "utils/password";

// styles
import "./style.scss";

const Setting = () => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const changePasswordComplete = useSelector(
    (state) => state.auth.changePasswordComplete
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let elements = {};

  const FIELDS = {
    password: "password",
    newPassword: "new_password",
    confirmationPassword: "confirmation_password",
  };

  const handleFormChange = () => {
    setSubmitEnabled();

    dispatch(clearError());
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    let password = elements[FIELDS.password].value;
    let newPassword = elements[FIELDS.newPassword].value;
    let confirmationPassword = elements[FIELDS.confirmationPassword].value;

    if (newPassword !== confirmationPassword) {
      window.alert("新しいパスワードと確認用パスワードが一致しません");
      return;
    }

    if (!passwordRegex.test(newPassword)) {
      window.alert(
        "パスワードは12文字以上24文字以内で、英大文字、小文字と数字のいずれも1つ以上必須です"
      );
      return;
    }

    dispatch(changePassword(token, password, newPassword));
  };

  const getPassword = () => {
    const createdPassword = createPassword();
    const result = window.confirm(
      `パスワードが生成されました\n【 ${createdPassword} 】\n\nOKボタンを押すとクリップボードにコピーされます\n気に食わない場合に再度パスワード生成ボタンをクリックしてください`
    );

    if (result) {
      navigator.clipboard
        .writeText(createdPassword)
        .then(() => window.alert("クリップボードにコピーしました"))
        .catch(() => window.alert("クリップボードへのコピーに失敗しました"));
    }
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleLogout = () => {
    dispatch(logout(token));
    dispatch(clearInfo());
  };

  useEffect(() => {
    if (changePasswordComplete) {
      window.alert("パスワードの変更が完了しました");
      elements[FIELDS.password].value = "";
      elements[FIELDS.newPassword].value = "";
      elements[FIELDS.confirmationPassword].value = "";
      dispatch(clearStatus());
    }
  }, [changePasswordComplete]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  return (
    <div className="setting">
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      <div className="random_password_button_container">
        <button className="design_button" type="button" onClick={getPassword}>
          パスワード生成
        </button>
      </div>
      <div className="title">アカウント設定</div>
      <form
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table>
          <tbody>
            <tr>
              <th>
                <label htmlFor="current_password">現在のパスワード</label>
              </th>
              <td>
                <input
                  className="design_input"
                  id="current_password"
                  type="password"
                  name={FIELDS.password}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label htmlFor="new_password">新しいパスワード</label>
              </th>
              <td>
                <input
                  className="design_input"
                  id="new_password"
                  type="password"
                  name={FIELDS.newPassword}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label htmlFor="confirmation_password">
                  新しいパスワード（確認用）
                </label>
              </th>
              <td>
                <input
                  className="design_input"
                  id="confirmation_password"
                  type="password"
                  name={FIELDS.confirmationPassword}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className={"password_attention"}>
          パスワードは12文字以上24文字以内で、英大文字、小文字と数字のいずれも1つ以上必須です。
        </div>
        <input
          className="design_button"
          type="submit"
          value="パスワード変更"
          disabled={!isSubmitEnabled}
        />
      </form>
      <div className="logout_button_container">
        <button className="design_button delete_button" onClick={handleLogout}>
          ログアウト
        </button>
      </div>
    </div>
  );
};

export default Setting;
