export const createPassword = () => {
  const passwordLength = 12;
  const pattern =
    "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

  while (true) {
    let password = "";

    for (let i = 0; i < passwordLength; i++) {
      password += pattern.charAt(Math.floor(Math.random() * pattern.length));
    }

    if (passwordRegex.test(password)) return password;
  }
};

// 大文字英字、小文字英字、数字が最低1つ以上入っていて12文字以上24文字以内であること
export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9.?/-]{12,24}$/;
