// libs
import React from "react";

// styles
import "./style.scss";

const Modal = ({ close, children }) => {
  return (
    <div className="edit_modal">
      <div className="overlay"></div>
      <div className="container">
        <i className="close_button fas fa-times fa-lg" onClick={close} />
        <div className="contents_container">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
