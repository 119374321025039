export const GET_SERVER_STATUS_REQUEST = "GET_SERVER_STATUS_REQUEST";
export const GET_SERVER_STATUS_SUCCESS = "GET_SERVER_STATUS_SUCCESS";
export const GET_SERVER_STATUS_FAILURE = "GET_SERVER_STATUS_FAILURE";

export const CHANGE_SERVER_STATUS_REQUEST = "CHANGE_SERVER_STATUS_REQUEST";
export const CHANGE_SERVER_STATUS_SUCCESS = "CHANGE_SERVER_STATUS_SUCCESS";
export const CHANGE_SERVER_STATUS_FAILURE = "CHANGE_SERVER_STATUS_FAILURE";

export const CLEAR_SERVER_STATUS_STATUS = "CLEAR_SERVER_STATUS_STATUS";
export const CLEAR_SERVER_STATUS_ERROR = "CLEAR_SERVER_STATUS_ERROR";
