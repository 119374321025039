// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { updateStock, getStockInfo, clearStatus } from "redux/actions/stock";

// utils
import { dateToString } from "utils/date";

// constants
import { productList } from "constants/products";

// style
import "./style.scss";

const ProductEdit = (props) => {
  const {
    id,
    product_id,
    initial_quantity,
    warehousing_date,
    warehousing_id,
    is_active,
  } = props.stock;

  let elements = {};

  const FIELDS = {
    productID: "product_id",
    initialQuantity: "initial_quantity",
    warehousingDate: "warehousing_date",
    warehousingID: "warehousing_id",
    isActive: "is_active",
  };

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.stock.isProcessing);
  const isUpdateComplete = useSelector((state) => state.stock.isUpdateComplete);

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);

  const dispatch = useDispatch();

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeStock();
    }
  };

  const changeStock = () => {
    const data = {
      stock_id: id,
      product_id: elements[FIELDS.productID].value,
      initial_quantity: elements[FIELDS.initialQuantity].value,
      warehousing_date: elements[FIELDS.warehousingDate].value,
      warehousing_id: elements[FIELDS.warehousingID].value,
      is_active: elements[FIELDS.isActive].value,
    };

    if (token) {
      dispatch(updateStock(token, data));
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(getStockInfo(token, id));
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="stock_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        onChange={setSubmitEnabled}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="stock_item">
          <tbody>
            <tr>
              <th>商品ID</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.productID}
                  defaultValue={product_id}
                  style={{ width: "250px" }}
                >
                  {productList.map((mattress) => (
                    <option value={mattress.productID} key={mattress.productID}>
                      {mattress.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>入庫ID</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.warehousingID}
                  defaultValue={warehousing_id}
                  style={{ width: "250px" }}
                />
              </td>
            </tr>
            <tr>
              <th>入庫日</th>
              <td>
                <input
                  className="design_input"
                  type="date"
                  name={FIELDS.warehousingDate}
                  defaultValue={
                    warehousing_date &&
                    dateToString(warehousing_date, "yyyy-MM-dd")
                  }
                  style={{ width: "170px" }}
                />
              </td>
            </tr>
            <tr>
              <th>入庫数</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.initialQuantity}
                  defaultValue={initial_quantity}
                  style={{ width: "150px" }}
                />
              </td>
            </tr>
            <tr>
              <th>有効・無効</th>
              <td>
                <label className="radio_button">
                  <input
                    name={FIELDS.isActive}
                    type="radio"
                    value={true}
                    defaultChecked={is_active === true}
                  />
                  有効
                </label>
                <label className="radio_button">
                  <input
                    name={FIELDS.isActive}
                    type="radio"
                    value={false}
                    defaultChecked={is_active === false}
                  />
                  無効
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;
