// libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateBusinessContact, clearStatus } from "redux/actions/businessContact";

// utils
import { dateToString } from "utils/date";

// style
import "./style.scss";

const BusinessContactEdit = (props) => {
  const {
    id,
    name,
    corporation,
    mail,
    phone,
    text,
    route,
    is_catalog_downloaded,
    status,
    memo,
    created_at,
  } = props.businessContact;

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.businessContact.isProcessing);
  const isUpdateComplete = useSelector(
    (state) => state.businessContact.isUpdateComplete
  );

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const dispatch = useDispatch();

  const FIELDS = {
    memo: "memo",
    status: "status",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every((field) => {
      if (field === FIELDS.memo) {
        return true;
      }
      return elements[field].value;
    });

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const changeBusinessContact = () => {
    const data = {
			business_contact_id: id,
      memo: elements[FIELDS.memo].value,
      status: elements[FIELDS.status].value,
    };

    if (token) {
      dispatch(updateBusinessContact(token, data));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeBusinessContact();
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="business_contact_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>ID</th>
              <td>{id}</td>
            </tr>
            <tr>
              <th>担当者名</th>
              <td>{name}</td>
            </tr>
            <tr>
              <th>会社名</th>
              <td>{corporation}</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>{mail}</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>{phone}</td>
            </tr>
            <tr>
              <th>問い合わせ内容</th>
              <td>{text}</td>
            </tr>
            <tr>
              <th>お知りになった経緯</th>
              <td>{route}</td>
            </tr>
						<tr>
              <th>カタログダウンロード済み</th>
              <td>{is_catalog_downloaded ? "◯" : "-" }</td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.status}
                  defaultValue={status}
                >
                  <option value={"pending"}>未対応</option>
                  <option value={"in_progress"}>対応中</option>
                  <option value={"delivered"}>納品済み</option>
                  <option value={"no_transaction"}>取引なし</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>メモ</th>
              <td>
								<input
										className="design_input"
										type="text"
										name={FIELDS.memo}
										defaultValue={memo}
								/>
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing || !isSubmitEnabled}
          />
        </div>
      </form>
    </div>
  );
};

export default BusinessContactEdit;
