export const GET_STOCKS_REQUEST = "GET_STOCKS_REQUEST";
export const GET_STOCKS_SUCCESS = "GET_STOCKS_SUCCESS";
export const GET_STOCKS_FAILURE = "GET_STOCKS_FAILURE";

export const GET_STOCK_INFO_REQUEST = "GET_STOCK_INFO_REQUEST";
export const GET_STOCK_INFO_SUCCESS = "GET_STOCK_INFO_SUCCESS";
export const GET_STOCK_INFO_FAILURE = "GET_STOCK_INFO_FAILURE";

export const CREATE_STOCK_REQUEST = "CREATE_STOCK_REQUEST";
export const CREATE_STOCK_SUCCESS = "CREATE_STOCK_SUCCESS";
export const CREATE_STOCK_FAILURE = "CREATE_STOCK_FAILURE";

export const UPDATE_STOCK_REQUEST = "UPDATE_STOCK_REQUEST";
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS";
export const UPDATE_STOCK_FAILURE = "UPDATE_STOCK_FAILURE";

export const UPDATE_STOCK_ACTIVE_REQUEST = "UPDATE_STOCK_ACTIVE_REQUEST";
export const UPDATE_STOCK_ACTIVE_SUCCESS = "UPDATE_STOCK_ACTIVE_SUCCESS";
export const UPDATE_STOCK_ACTIVE_FAILURE = "UPDATE_STOCK_ACTIVE_FAILURE";

export const DELETE_STOCK_REQUEST = "DELETE_STOCK_REQUEST";
export const DELETE_STOCK_SUCCESS = "DELETE_STOCK_SUCCESS";
export const DELETE_STOCK_FAILURE = "DELETE_STOCK_FAILURE";

export const CLEAR_STOCK_STATUS = "CLEAR_STOCK_STATUS";

export const SET_STOCK_FILTER = "SET_STOCK_FILTER";
export const CLEAR_STOCK_FILTER = "CLEAR_STOCK_FILTER";

export const CLEAR_STOCK_ERROR = "CLEAR_STOCK_ERROR";
export const CHANGE_STOCK_PAGE = "CHANGE_STOCK_PAGE";
