import axios from "../network";
import * as types from "../types/faq";

// FAQの商品グループ一覧取得
const getFaqProductsRequest = () => {
	return {
		type: types.GET_FAQ_PRODUCTS_REQUEST,
	};
};

const getFaqProductsSuccess = (data) => {
	return {
		type: types.GET_FAQ_PRODUCTS_SUCCESS,
		data,
	};
};

const getFaqProductsFailure = (error) => {
	return {
		type: types.GET_FAQ_PRODUCTS_FAILURE,
		error,
	};
};

/*
params

data: {
	is_open(string)
	offset(int)
	limit(int)
	sort_by(string)
	sort_type(string)
}
*/
export const getFaqProducts = (token, data) => {
	return (dispatch) => {
		dispatch(getFaqProductsRequest());
		return axios
			.get("/admin/faq/product/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getFaqProductsSuccess(res.data));
			})
			.catch((err) => dispatch(getFaqProductsFailure(err)));
	};
};

// FAQの商品グループ作成
const createFaqProductRequest = () => {
	return {
		type: types.CREATE_FAQ_PRODUCT_REQUEST,
	};
};

const createFaqProductSuccess = () => {
	return {
		type: types.CREATE_FAQ_PRODUCT_SUCCESS,
	};
};

const createFaqProductFailure = (error) => {
	return {
		type: types.CREATE_FAQ_PRODUCT_FAILURE,
		error,
	};
};

/*
params

data: {
	is_open(string)
	name(string)*
}
*/
export const createFaqProduct = (token, data) => {
	return (dispatch) => {
		dispatch(createFaqProductRequest());
		return axios
			.post("/admin/faq/product/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createFaqProductSuccess());
			})
			.catch((err) => dispatch(createFaqProductFailure(err)));
	};
};

// FAQの商品グループ編集
const updateFaqProductRequest = () => {
	return {
		type: types.UPDATE_FAQ_PRODUCT_REQUEST,
	};
};

const updateFaqProductSuccess = () => {
	return {
		type: types.UPDATE_FAQ_PRODUCT_SUCCESS,
	};
};

const updateFaqProductFailure = (error) => {
	return {
		type: types.UPDATE_FAQ_PRODUCT_FAILURE,
		error,
	};
};

/*
params

data: {
	faq_product_id(int)*
	name(string)*
	is_open(bool)
}
*/
export const updateFaqProduct = (token, data) => {
	return (dispatch) => {
		dispatch(updateFaqProductRequest());
		return axios
			.put("/admin/faq/product/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateFaqProductSuccess());
			})
			.catch((err) => dispatch(updateFaqProductFailure(err)));
	};
};

// FAQの商品グループ取得
const getFaqProductRequest = () => {
	return {
		type: types.GET_FAQ_PRODUCT_REQUEST,
	};
};

const getFaqProductSuccess = (data) => {
	return {
		type: types.GET_FAQ_PRODUCT_SUCCESS,
		data,
	};
};

const getFaqProductFailure = (error) => {
	return {
		type: types.GET_FAQ_PRODUCT_FAILURE,
		error,
	};
};

/*
params

data: {
	faq_product_id(int)
}
*/
export const getFaqProduct = (token, id) => {
	return (dispatch) => {
		dispatch(getFaqProductRequest());
		return axios
			.get("/admin/faq/product/", {
				headers: { token: token },
				params: { faq_product_id: id },
				data: {},
			})
			.then((res) => {
				return dispatch(getFaqProductSuccess(res.data));
			})
			.catch((err) => dispatch(getFaqProductFailure(err)));
	};
};

/*
params

data: {
	status(bool)
	sortBy(string)
	sortType(string)
}
*/
export const setFaqProductFilter = (data) => {
	return {
		type: types.SET_FAQ_PRODUCT_FILTER,
		data: data,
	};
};

export const clearFaqProductFilter = () => {
	return {
		type: types.CLEAR_FAQ_PRODUCT_FILTER,
	};
};

// FAQのカテゴリ一覧取得
const getFaqCategoriesRequest = () => {
	return {
		type: types.GET_FAQ_CATEGORIES_REQUEST,
	};
};

const getFaqCategoriesSuccess = (data) => {
	return {
		type: types.GET_FAQ_CATEGORIES_SUCCESS,
		data,
	};
};

const getFaqCategoriesFailure = (error) => {
	return {
		type: types.GET_FAQ_CATEGORIES_FAILURE,
		error,
	};
};

/*
params

data: {
	is_open(string)
	offset(int)
	limit(int)
	sort_by(string)
	sort_type(string)
}
*/
export const getFaqCategories = (token, data) => {
	return (dispatch) => {
		dispatch(getFaqCategoriesRequest());
		return axios
			.get("/admin/faq/category/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getFaqCategoriesSuccess(res.data));
			})
			.catch((err) => dispatch(getFaqCategoriesFailure(err)));
	};
};

// FAQのカテゴリ作成
const createFaqCategoryRequest = () => {
	return {
		type: types.CREATE_FAQ_CATEGORY_REQUEST,
	};
};

const createFaqCategorySuccess = (data) => {
	return {
		type: types.CREATE_FAQ_CATEGORY_SUCCESS,
		data,
	};
};

const createFaqCategoryFailure = (error) => {
	return {
		type: types.CREATE_FAQ_CATEGORY_FAILURE,
		error,
	};
};

/*
params

data: {
	is_open(string)
	name(string)*
}
*/
export const createFaqCategory = (token, data) => {
	return (dispatch) => {
		dispatch(createFaqCategoryRequest());
		return axios
			.post("/admin/faq/category/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createFaqCategorySuccess(res.data));
			})
			.catch((err) => dispatch(createFaqCategoryFailure(err)));
	};
};

// FAQのカテゴリ編集
const updateFaqCategoryRequest = () => {
	return {
		type: types.UPDATE_FAQ_CATEGORY_REQUEST,
	};
};

const updateFaqCategorySuccess = (data) => {
	return {
		type: types.UPDATE_FAQ_CATEGORY_SUCCESS,
		data,
	};
};

const updateFaqCategoryFailure = (error) => {
	return {
		type: types.UPDATE_FAQ_CATEGORY_FAILURE,
		error,
	};
};

/*
params

data: {
	category_id(int)*
	name(string)*
	is_open(bool)
}
*/
export const updateFaqCategory = (token, data) => {
	return (dispatch) => {
		dispatch(updateFaqCategoryRequest());
		return axios
			.put("/admin/faq/category/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateFaqCategorySuccess(res.data));
			})
			.catch((err) => dispatch(updateFaqCategoryFailure(err)));
	};
};

// FAQのカテゴリ取得
const getFaqCategoryRequest = () => {
	return {
		type: types.GET_FAQ_CATEGORY_REQUEST,
	};
};

const getFaqCategorySuccess = (data) => {
	return {
		type: types.GET_FAQ_CATEGORY_SUCCESS,
		data,
	};
};

const getFaqCategoryFailure = (error) => {
	return {
		type: types.GET_FAQ_CATEGORY_FAILURE,
		error,
	};
};

/*
params

data: {
	category_id(int)
}
*/
export const getFaqCategory = (token, id) => {
	return (dispatch) => {
		dispatch(getFaqCategoryRequest());
		return axios
			.get("/admin/faq/category/", {
				headers: { token: token },
				params: { category_id: id },
				data: {},
			})
			.then((res) => {
				return dispatch(getFaqCategorySuccess(res.data));
			})
			.catch((err) => dispatch(getFaqCategoryFailure(err)));
	};
};

/*
params

data: {
	faqProductId(string)
	status(bool)
	sortBy(string)
	sortType(string)
}
*/
export const setCategoryFilter = (data) => {
	return {
		type: types.SET_FAQ_CATEGORY_FILTER,
		data: data,
	};
};

export const clearCategoryFilter = () => {
	return {
		type: types.CLEAR_FAQ_CATEGORY_FILTER,
	};
};

// FAQ一覧取得
const getFaqsRequest = () => {
	return {
		type: types.GET_FAQS_REQUEST,
	};
};

const getFaqsSuccess = (data) => {
	return {
		type: types.GET_FAQS_SUCCESS,
		data,
	};
};

const getFaqsFailure = (error) => {
	return {
		type: types.GET_FAQS_FAILURE,
		error,
	};
};

/*
params

data: {
	is_open(string)
	offset(int)
	limit(int)
	sort_by(string)
	sort_type(string)
}
*/
export const getFaqs = (token, data) => {
	return (dispatch) => {
		dispatch(getFaqsRequest());
		return axios
			.get("/admin/faq/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => dispatch(getFaqsSuccess(res.data)))
			.catch((err) => dispatch(getFaqsFailure(err)));
	};
};

// FAQ作成
const createFaqRequest = () => {
	return {
		type: types.CREATE_FAQ_REQUEST,
	};
};

const createFaqSuccess = (data) => {
	return {
		type: types.CREATE_FAQ_SUCCESS,
		data,
	};
};

const createFaqFailure = (error) => {
	return {
		type: types.CREATE_FAQ_FAILURE,
		error,
	};
};

/*
params

data: {
	question(string)*
	answer(string)*
	category_id(int)*
	is_open(bool)
}
*/
export const createFaq = (token, data) => {
	return (dispatch) => {
		dispatch(createFaqRequest());
		return axios
			.post("/admin/faq/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createFaqSuccess(res.data));
			})
			.catch((err) => dispatch(createFaqFailure(err)));
	};
};

// FAQ編集
const updateFaqRequest = () => {
	return {
		type: types.UPDATE_FAQ_REQUEST,
	};
};

const updateFaqSuccess = (data) => {
	return {
		type: types.UPDATE_FAQ_SUCCESS,
		data,
	};
};

const updateFaqFailure = (error) => {
	return {
		type: types.UPDATE_FAQ_FAILURE,
		error,
	};
};

/*
params

data: {
	faq_id(int)*
	question(string)*
	answer(string)*
	category_id(int)*
	is_open(bool)
}
*/
export const updateFaq = (token, data) => {
	return (dispatch) => {
		dispatch(updateFaqRequest());
		return axios
			.put("/admin/faq/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateFaqSuccess(res.data));
			})
			.catch((err) => dispatch(updateFaqFailure(err)));
	};
};

// FAQのカテゴリ取得
const getFaqRequest = () => {
	return {
		type: types.GET_FAQ_REQUEST,
	};
};

const getFaqSuccess = (data) => {
	return {
		type: types.GET_FAQ_SUCCESS,
		data,
	};
};

const getFaqFailure = (error) => {
	return {
		type: types.GET_FAQ_FAILURE,
		error,
	};
};

/*
params

data: {
	category_id(int)
}
*/
export const getFaq = (token, id) => {
	return (dispatch) => {
		dispatch(getFaqRequest());
		return axios
			.get("/admin/faq/", {
				headers: { token: token },
				params: { faq_id: id },
				data: {},
			})
			.then((res) => {
				return dispatch(getFaqSuccess(res.data));
			})
			.catch((err) => dispatch(getFaqFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_FAQ_STATUS,
	};
};

/*
params

data: {
	faqProductId(string)
	isOpen(bool)
	sortBy(string)
	sortType(string)
}
*/
export const setFilter = (data) => {
	return {
		type: types.SET_FAQ_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_FAQ_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_FAQ_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_FAQ_PAGE,
		page: page,
	};
};
