export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const GET_PRODUCT_INFO_REQUEST = "GET_PRODUCT_INFO_REQUEST";
export const GET_PRODUCT_INFO_SUCCESS = "GET_PRODUCT_INFO_SUCCESS";
export const GET_PRODUCT_INFO_FAILURE = "GET_PRODUCT_INFO_FAILURE";

export const UPDATE_PRODUCT_INFO_REQUEST = "UPDATE_PRODUCT_INFO_REQUEST";
export const UPDATE_PRODUCT_INFO_SUCCESS = "UPDATE_PRODUCT_INFO_SUCCESS";
export const UPDATE_PRODUCT_INFO_FAILURE = "UPDATE_PRODUCT_INFO_FAILURE";

export const CREATE_PRODUCT_INFO_REQUEST = "CREATE_PRODUCT_INFO_REQUEST";
export const CREATE_PRODUCT_INFO_SUCCESS = "CREATE_PRODUCT_INFO_SUCCESS";
export const CREATE_PRODUCT_INFO_FAILURE = "CREATE_PRODUCT_INFO_FAILURE";

export const DELETE_PRODUCT_INFO_REQUEST = "DELETE_PRODUCT_INFO_REQUEST";
export const DELETE_PRODUCT_INFO_SUCCESS = "DELETE_PRODUCT_INFO_SUCCESS";
export const DELETE_PRODUCT_INFO_FAILURE = "DELETE_PRODUCT_INFO_FAILURE";

export const CLEAR_PRODUCT_STATUS = "CLEAR_PRODUCT_STATUS";

export const SET_PRODUCT_FILTER = "SET_PRODUCT_FILTER";
export const CLEAR_PRODUCT_FILTER = "CLEAR_PRODUCT_FILTER";

export const CLEAR_PRODUCT_ERROR = "CLEAR_PRODUCT_ERROR";
export const CHANGE_PRODUCT_PAGE = "CHANGE_PRODUCT_PAGE";
