// libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { updateGift, clearStatus } from "redux/actions/gift";

// utils
import { dateToString } from "utils/date";
import { provinceList } from "constants/provinces";

// style
import "./style.scss";

const GiftEdit = (props) => {
  const {
    id,
    name,
    postal_code,
    province,
    city_address_line,
    phone_number,
    label,
    memo,
    created_at,
  } = props.gift;

  const giftLabelList = props.giftLabelList;

  const [isShowNewLabelForm, setIsShowNewLabelForm] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isUpdateComplete = useSelector((state) => state.gift.isUpdateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    postalCode: "postal_code",
    province: "province",
    cityAdressLine: "city_address_line",
    phoneNumber: "phone_number",
    label: "label",
    memo: "memo",
    isShowNewLabelForm: "is_show_new_label_form",
  };

  let elements = {};

  const changeGift = () => {
    const data = {
      gift_id: id,
      name: elements[FIELDS.name].value,
      postal_code: elements[FIELDS.postalCode].value,
      province: elements[FIELDS.province].value,
      city_address_line: elements[FIELDS.cityAdressLine].value,
      phone_number: elements[FIELDS.phoneNumber].value,
      label: elements[FIELDS.label].value,
      memo: elements[FIELDS.memo].value,
    };

    if (token) {
      dispatch(updateGift(token, data));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("編集を確定しますか？")) {
      changeGift();
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="gift_edit edit">
      <form
        className="edit_form"
        onSubmit={handleSubmit}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>氏名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                  defaultValue={name}
                />
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.postalCode}
                  defaultValue={postal_code}
                />
              </td>
            </tr>
            <tr>
              <th>都道府県</th>
              <td>
                <select
                  className="design_select"
                  name={FIELDS.province}
                  defaultValue={province}
                >
                  {provinceList.map((province) => (
                    <option value={province} key={province}>
                      {province}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>都道府県以降の住所</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.cityAdressLine}
                  defaultValue={city_address_line}
                />
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.phoneNumber}
                  defaultValue={phone_number}
                />
              </td>
            </tr>
            <tr>
              <th>ラベル</th>
              <td>
                <input
                  className=""
                  name={FIELDS.is_show_new_label_form}
                  type="checkbox"
                  onChange={(e) => setIsShowNewLabelForm(e.target.checked)}
                />
                新規ラベルを登録
                <div className="label_form">
                  {isShowNewLabelForm ? (
                    <input
                      className="design_input"
                      type="text"
                      name={FIELDS.label}
                      defaultValue={label}
                    />
                  ) : (
                    <select
                      className="design_select"
                      name={FIELDS.label}
                      defaultValue={label}
                    >
                      <option value="">-</option>
                      {giftLabelList.map((label, index) => (
                        <option value={label} key={index}>
                          {label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <th>メモ</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.memo}
                  defaultValue={memo}
                />
              </td>
            </tr>
            <tr>
              <th>作成日時</th>
              <td>{dateToString(created_at, "yyyy/MM/dd HH:mm")}</td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="編集内容を確定"
            disabled={isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default GiftEdit;
