import axios from "../network";
import * as types from "../types/gift";

const getGiftsRequest = () => {
  return {
    type: types.GET_GIFTS_REQUEST,
  };
};

const getGiftsSuccess = (data) => {
  return {
    type: types.GET_GIFTS_SUCCESS,
    data,
  };
};

const getGiftsFailure = (error) => {
  return {
    type: types.GET_GIFTS_FAILURE,
    error,
  };
};

export const getGifts = (token, data) => {
  return (dispatch) => {
    dispatch(getGiftsRequest());
    return axios
      .get("/admin/gift/list/", {
        headers: { token: token },
        params: data,
        data: {},
      })
      .then((res) => {
        return dispatch(getGiftsSuccess(res.data));
      })
      .catch((err) => dispatch(getGiftsFailure(err)));
  };
};

const getGiftLabelsRequest = () => {
  return {
    type: types.GET_GIFT_LABELS_REQUEST,
  };
};

const getGiftLabelsSuccess = (data) => {
  return {
    type: types.GET_GIFT_LABELS_SUCCESS,
    data,
  };
};

const getGiftLabelsFailure = (error) => {
  return {
    type: types.GET_GIFT_LABELS_FAILURE,
    error,
  };
};

export const getGiftLabels = (token) => {
  return (dispatch) => {
    dispatch(getGiftLabelsRequest());
    return axios
      .get("/admin/gift/label/list/", {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(getGiftLabelsSuccess(res.data));
      })
      .catch((err) => dispatch(getGiftLabelsFailure(err)));
  };
};

const getGiftInfoRequest = () => {
  return {
    type: types.GET_GIFT_INFO_REQUEST,
  };
};

const getGiftInfoSuccess = (data) => {
  return {
    type: types.GET_GIFT_INFO_SUCCESS,
    data,
  };
};

const getGiftInfoFailure = (error) => {
  return {
    type: types.GET_GIFT_INFO_FAILURE,
    error,
  };
};

export const getGiftInfo = (token, giftId) => {
  return (dispatch) => {
    dispatch(getGiftInfoRequest());
    return axios
      .get("/admin/gift/", {
        headers: { token: token },
        params: { gift_id: giftId },
        data: {},
      })
      .then((res) => {
        return dispatch(getGiftInfoSuccess(res.data));
      })
      .catch((err) => dispatch(getGiftInfoFailure(err)));
  };
};

const getGiftProductDeliveryDateRequest = () => {
  return {
    type: types.GET_GIFT_PRODUCT_DELIVERY_DATE_REQUEST,
  };
};

const getGiftProductDeliveryDateSuccess = (data, requestData) => {
  return {
    type: types.GET_GIFT_PRODUCT_DELIVERY_DATE_SUCCESS,
    data,
    requestData,
  };
};

const getGiftProductDeliveryDateFailure = (error) => {
  return {
    type: types.GET_GIFT_PRODUCT_DELIVERY_DATE_FAILURE,
    error,
  };
};

export const getGiftProductDeliveryDate = (token, data) => {
  return (dispatch) => {
    dispatch(getGiftProductDeliveryDateRequest());
    return axios
      .post("/admin/gift/delivery-date/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(getGiftProductDeliveryDateSuccess(res.data, data));
      })
      .catch((err) => dispatch(getGiftProductDeliveryDateFailure(err)));
  };
};

const createGiftRequest = () => {
  return {
    type: types.CREATE_GIFT_REQUEST,
  };
};

const createGiftSuccess = (data) => {
  return {
    type: types.CREATE_GIFT_SUCCESS,
    data,
  };
};

const createGiftFailure = (error) => {
  return {
    type: types.CREATE_GIFT_FAILURE,
    error,
  };
};

export const createGift = (token, data) => {
  return (dispatch) => {
    dispatch(createGiftRequest());
    return axios
      .post("/admin/gift/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(createGiftSuccess(res.data));
      })
      .catch((err) => dispatch(createGiftFailure(err)));
  };
};

const updateGiftRequest = () => {
  return {
    type: types.UPDATE_GIFT_REQUEST,
  };
};

const updateGiftSuccess = (data) => {
  return {
    type: types.UPDATE_GIFT_SUCCESS,
    data,
  };
};

const updateGiftFailure = (error) => {
  return {
    type: types.UPDATE_GIFT_FAILURE,
    error,
  };
};

export const updateGift = (token, data) => {
  return (dispatch) => {
    dispatch(updateGiftRequest());
    return axios
      .put("/admin/gift/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(updateGiftSuccess(res.data));
      })
      .catch((err) => dispatch(updateGiftFailure(err)));
  };
};

const createGiftProductRequest = () => {
  return {
    type: types.CREATE_GIFT_PRODUCT_REQUEST,
  };
};

const createGiftProductSuccess = (data) => {
  return {
    type: types.CREATE_GIFT_PRODUCT_SUCCESS,
    data,
  };
};

const createGiftProductFailure = (error) => {
  return {
    type: types.CREATE_GIFT_PRODUCT_FAILURE,
    error,
  };
};

export const createGiftProduct = (token, data) => {
  return (dispatch) => {
    dispatch(createGiftProductRequest());
    return axios
      .post("/admin/gift/product/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(createGiftProductSuccess(res.data));
      })
      .catch((err) => dispatch(createGiftProductFailure(err)));
  };
};

const updateGiftProductRequest = () => {
  return {
    type: types.UPDATE_GIFT_PRODUCT_REQUEST,
  };
};

const updateGiftProductSuccess = (data) => {
  return {
    type: types.UPDATE_GIFT_PRODUCT_SUCCESS,
    data,
  };
};

const updateGiftProductFailure = (error) => {
  return {
    type: types.UPDATE_GIFT_PRODUCT_FAILURE,
    error,
  };
};

export const updateGiftProduct = (token, data) => {
  return (dispatch) => {
    dispatch(updateGiftProductRequest());
    return axios
      .put("/admin/gift/product/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(updateGiftProductSuccess(res.data));
      })
      .catch((err) => dispatch(updateGiftProductFailure(err)));
  };
};

const cancelGiftProductRequest = () => {
  return {
    type: types.CANCEL_GIFT_PRODUCT_REQUEST,
  };
};

const cancelGiftProductSuccess = (data) => {
  return {
    type: types.CANCEL_GIFT_PRODUCT_SUCCESS,
    data,
  };
};

const cancelGiftProductFailure = (error) => {
  return {
    type: types.CANCEL_GIFT_PRODUCT_FAILURE,
    error,
  };
};

export const cancelGiftProduct = (token, data) => {
  return (dispatch) => {
    dispatch(cancelGiftProductRequest());
    return axios
      .post("/admin/gift/product/cancel/", data, {
        headers: { token: token },
      })
      .then((res) => {
        return dispatch(cancelGiftProductSuccess(res.data));
      })
      .catch((err) => dispatch(cancelGiftProductFailure(err)));
  };
};

export const clearStatus = () => {
  return {
    type: types.CLEAR_GIFT_STATUS,
  };
};

export const setFilter = (data) => {
  return {
    type: types.SET_GIFT_FILTER,
    data: data,
  };
};

export const clearFilter = () => {
  return {
    type: types.CLEAR_GIFT_FILTER,
  };
};

export const clearError = () => {
  return {
    type: types.CLEAR_GIFT_ERROR,
  };
};

export const changePage = (page) => {
  return {
    type: types.CHANGE_GIFT_PAGE,
    page: page,
  };
};
