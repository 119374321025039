export const GET_OFFLINE_STORES_REQUEST = "GET_OFFLINE_STORES_REQUEST";
export const GET_OFFLINE_STORES_SUCCESS = "GET_OFFLINE_STORES_SUCCESS";
export const GET_OFFLINE_STORES_FAILURE = "GET_OFFLINE_STORES_FAILURE";

export const GET_OFFLINE_STORE_INFO_REQUEST = "GET_OFFLINE_STORE_INFO_REQUEST";
export const GET_OFFLINE_STORE_INFO_SUCCESS = "GET_OFFLINE_STORE_INFO_SUCCESS";
export const GET_OFFLINE_STORE_INFO_FAILURE = "GET_OFFLINE_STORE_INFO_FAILURE";

export const CREATE_OFFLINE_STORE_REQUEST = "CREATE_OFFLINE_STORE_REQUEST";
export const CREATE_OFFLINE_STORE_SUCCESS = "CREATE_OFFLINE_STORE_SUCCESS";
export const CREATE_OFFLINE_STORE_FAILURE = "CREATE_OFFLINE_STORE_FAILURE";

export const DELETE_OFFLINE_STORE_REQUEST = "DELETE_OFFLINE_STORE_REQUEST";
export const DELETE_OFFLINE_STORE_SUCCESS = "DELETE_OFFLINE_STORE_SUCCESS";
export const DELETE_OFFLINE_STORE_FAILURE = "DELETE_OFFLINE_STORE_FAILURE";

export const RESET_PASSWORD_OFFLINE_STORE_REQUEST =
  "RESET_PASSWORD_OFFLINE_STORE_REQUEST";
export const RESET_PASSWORD_OFFLINE_STORE_SUCCESS =
  "RESET_PASSWORD_OFFLINE_STORE_SUCCESS";
export const RESET_PASSWORD_OFFLINE_STORE_FAILURE =
  "RESET_PASSWORD_OFFLINE_STORE_FAILURE";

export const CLEAR_OFFLINE_STORE_STATUS = "CLEAR_OFFLINE_STORE_STATUS";

export const SET_OFFLINE_STORE_FILTER = "SET_OFFLINE_STORE_FILTER";
export const CLEAR_OFFLINE_STORE_FILTER = "CLEAR_OFFLINE_STORE_FILTER";

export const CLEAR_OFFLINE_STORE_ERROR = "CLEAR_OFFLINE_STORE_ERROR";
export const CHANGE_OFFLINE_STORE_PAGE = "CHANGE_OFFLINE_STORE_PAGE";
