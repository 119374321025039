// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createReview, clearStatus } from "redux/actions/review";

// style
import "./style.scss";

const CouponCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.review.isProcessing);
  const isCreateComplete = useSelector(
    (state) => state.review.isCreateComplete
  );

  const dispatch = useDispatch();

  const FIELDS = {
    title: "title",
    score: "score",
    name: "name",
    text: "text",
    isOpen: "is_open",
    isOpenLP: "is_open_lp",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      title: elements[FIELDS.title].value,
      score: parseInt(elements[FIELDS.score].value, 10),
      name: elements[FIELDS.name].value,
      text: elements[FIELDS.text].value,
      is_open: elements[FIELDS.isOpen].value,
      is_open_lp: elements[FIELDS.isOpenLP].value,
    };

    if (token) {
      dispatch(createReview(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="review_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>タイトル</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.title}
                />
              </td>
            </tr>
            <tr>
              <th>スコア</th>
              <td>
                <input
                  className="design_input"
                  type="number"
                  name={FIELDS.score}
                />
              </td>
            </tr>
            <tr>
              <th>レビュー者名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                />
              </td>
            </tr>
            <tr>
              <th>内容</th>
              <td>
                <textarea
                  className="design_input"
                  name={FIELDS.text}
                  style={{ resize: "none", height: "30vh" }}
                />
              </td>
            </tr>
            <tr>
              <th>サイト公開ステータス</th>
              <td>
                <label className="radio_button">
                  <input name={FIELDS.isOpen} type="radio" value={true} />
                  公開
                </label>
                <label className="radio_button">
                  <input name={FIELDS.isOpen} type="radio" value={false} />
                  非公開
                </label>
              </td>
            </tr>
            <tr>
              <th>LP公開ステータス</th>
              <td>
                <label className="radio_button">
                  <input name={FIELDS.isOpenLP} type="radio" value={true} />
                  公開
                </label>
                <label className="radio_button">
                  <input name={FIELDS.isOpenLP} type="radio" value={false} />
                  非公開
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default CouponCreate;
