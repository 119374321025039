// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { createNotice, clearStatus } from "redux/actions/notice";

// style
import "./style.scss";

const NoticeCreate = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.notice.isProcessing);
  const isCreateComplete = useSelector(
    (state) => state.notice.isCreateComplete
  );
  const dispatch = useDispatch();

  const FIELDS = {
    text: "text",
    isOpen: "is_open",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every(
      (field) => elements[field].value
    );

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      text: elements[FIELDS.text].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    if (token) {
      dispatch(createNotice(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      props.close();
    }
  }, [isCreateComplete]);

  return (
    <div className="notice_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>お知らせ内容</th>
              <td>
                <textarea
                  className="design_input"
                  name={FIELDS.text}
                  style={{ resize: "none", height: "30vh" }}
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <label className="radio_button">
                  <input name={FIELDS.isOpen} type="radio" value={true} />
                  公開
                </label>
                <label className="radio_button">
                  <input name={FIELDS.isOpen} type="radio" value={false} />
                  非公開
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default NoticeCreate;
