// libs
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// action
import { getMyAccountInfo, clearError } from "redux/actions/myAccount";
import { logout } from "redux/actions/auth";

const Auth = ({ children }) => {
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.myAccount.error);
  const myAccount = useSelector((state) => state.myAccount.info);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      switch (error.response.data.code) {
        case 1000:
          window.alert("再度ログインしてください");
          dispatch(logout(token));
          break;
        default:
          window.alert("予期せぬエラーが発生しました");
          break;
      }
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (token && myAccount.id === "") {
      dispatch(getMyAccountInfo(token));
    }
  }, []);

  return token ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        search: "?redirect_uri=" + location.pathname,
      }}
    />
  );
};

export default Auth;
