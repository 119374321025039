import axios from "../network";
import * as types from "../types/sale";

const getSaleListRequest = () => {
	return {
		type: types.GET_SALE_LIST_REQUEST,
	};
};

const getSaleListSuccess = (data) => {
	return {
		type: types.GET_SALE_LIST_SUCCESS,
		data,
	};
};

const getSaleListFailure = (error) => {
	return {
		type: types.GET_SALE_LIST_FAILURE,
		error,
	};
};

export const getSaleList = (token, data) => {
	return (dispatch) => {
		dispatch(getSaleListRequest());
		return axios
			.get("/admin/sale/list/", {
				headers: { token: token },
				params: data,
				data: {},
			})
			.then((res) => {
				return dispatch(getSaleListSuccess(res.data));
			})
			.catch((err) => dispatch(getSaleListFailure(err)));
	};
};

const getSaleInfoRequest = () => {
	return {
		type: types.GET_SALE_INFO_REQUEST,
	};
};

const getSaleInfoSuccess = (data) => {
	return {
		type: types.GET_SALE_INFO_SUCCESS,
		data,
	};
};

const getSaleInfoFailure = (error) => {
	return {
		type: types.GET_SALE_INFO_FAILURE,
		error,
	};
};

export const getSaleInfo = (token, saleID) => {
	return (dispatch) => {
		dispatch(getSaleInfoRequest());
		return axios
			.get("/admin/sale/", {
				headers: { token: token },
				params: { sale_id: saleID },
				data: {},
			})
			.then((res) => {
				return dispatch(getSaleInfoSuccess(res.data));
			})
			.catch((err) => dispatch(getSaleInfoFailure(err)));
	};
};

const createSaleRequest = () => {
	return {
		type: types.CREATE_SALE_REQUEST,
	};
};

const createSaleSuccess = (data) => {
	return {
		type: types.CREATE_SALE_SUCCESS,
		data,
	};
};

const createSaleFailure = (error) => {
	return {
		type: types.CREATE_SALE_FAILURE,
		error,
	};
};

export const createSale = (token, data) => {
	return (dispatch) => {
		dispatch(createSaleRequest());
		return axios
			.post("/admin/sale/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(createSaleSuccess(res.data));
			})
			.catch((err) => dispatch(createSaleFailure(err)));
	};
};

const updateSaleRequest = () => {
	return {
		type: types.UPDATE_SALE_REQUEST,
	};
};

const updateSaleSuccess = (data) => {
	return {
		type: types.UPDATE_SALE_SUCCESS,
		data,
	};
};

const updateSaleFailure = (error) => {
	return {
		type: types.UPDATE_SALE_FAILURE,
		error,
	};
};

export const updateSale = (token, data) => {
	return (dispatch) => {
		dispatch(updateSaleRequest());
		return axios
			.put("/admin/sale/", data, {
				headers: { token: token },
			})
			.then((res) => {
				return dispatch(updateSaleSuccess(res.data));
			})
			.catch((err) => dispatch(updateSaleFailure(err)));
	};
};

export const clearStatus = () => {
	return {
		type: types.CLEAR_SALE_STATUS,
	};
};

export const setFilter = (data) => {
	return {
		type: types.SET_SALE_FILTER,
		data: data,
	};
};

export const clearFilter = () => {
	return {
		type: types.CLEAR_SALE_FILTER,
	};
};

export const clearError = () => {
	return {
		type: types.CLEAR_SALE_ERROR,
	};
};

export const changePage = (page) => {
	return {
		type: types.CHANGE_SALE_PAGE,
		page: page,
	};
};
