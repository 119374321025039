// libs
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

// components
import Loader from "components/commons/Loader";
import Modal from "components/commons/Modal";
import GiftEdit from "./GiftEdit";
import GiftProductCreate from "./GiftProductCreate";
import GiftProductEdit from "./GiftProductEdit";
import ErrorMessage from "components/commons/ErrorMessage";

// action
import {
  getGiftInfo,
  clearError,
  cancelGiftProduct,
  getGiftLabels,
} from "redux/actions/gift";

// utils
import { dateToString, parseDeliveryTime } from "utils/date";
import { checkPermission } from "utils/checkPermission";

// constants
import { applicationList } from "constants/application";

// style
import "./style.scss";

const GiftDetail = () => {
  const { giftId } = useParams();
  const applicationId = applicationList.gift.id;

  const [isGiftEdit, setGiftEdit] = useState(false);
  const [isGiftProductCreate, setGiftProductCreate] = useState(false);
  const [isGiftProductEdit, setGiftProductEdit] = useState(false);
  const [targetProductInfo, setTargetProductInfo] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const myAccount = useSelector((state) => state.myAccount.info);
  const gift = useSelector((state) => state.gift.info);
  const giftLabelList = useSelector((state) => state.gift.giftLabelList);
  const error = useSelector((state) => state.gift.error);
  const isProcessing = useSelector((state) => state.gift.isProcessing);
  const isCancelComplete = useSelector((state) => state.gift.isCancelComplete);

  const dispatch = useDispatch();

  const writeIsAllowed = useMemo(
    () => checkPermission(myAccount, applicationId, "write"),
    [myAccount, applicationId]
  );

  const fetchGift = () => {
    dispatch(getGiftInfo(token, giftId));
  };

  const giftProductCancel = (nellProductId) => {
    if (window.confirm("キャンセルしますか？")) {
      const data = {
        product_id: nellProductId,
      };
      dispatch(cancelGiftProduct(token, data));
    }
  };

  useEffect(() => {
    if (isCancelComplete) {
      fetchGift();
    }
  }, [isCancelComplete]);

  useEffect(() => {
    fetchGift();
    dispatch(getGiftLabels(token));
  }, []);

  return (
    <>
      <div className="gift_detail detail">
        <div className="list_back_container">
          <Link to="/gift">一覧に戻る</Link>
        </div>
        <div className="gift_flex">
          <div className="gift_detail_container">
            <table className="detail_table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{gift.id}</td>
                </tr>
                <tr>
                  <th>氏名</th>
                  <td>{gift.name}</td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>{gift.postal_code}</td>
                </tr>
                <tr>
                  <th>都道府県</th>
                  <td>{gift.province}</td>
                </tr>
                <tr>
                  <th>都道府県以降の住所</th>
                  <td>{gift.city_address_line}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>{gift.phone_number}</td>
                </tr>
                <tr>
                  <th>ラベル</th>
                  <td>{gift.label}</td>
                </tr>
                <tr>
                  <th>メモ</th>
                  <td>{gift.memo}</td>
                </tr>
                <tr>
                  <th>作成日時</th>
                  <td>
                    {gift.created_at &&
                      dateToString(gift.created_at, "yyyy/MM/dd HH:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="button_container">
              {writeIsAllowed && (
                <button
                  className="design_button"
                  onClick={() => setGiftEdit(true)}
                  disabled={isProcessing}
                >
                  編集
                </button>
              )}
            </div>
          </div>
          <div className="gift_product_container">
            {writeIsAllowed && (
              <div className="button_container">
                <button
                  className="design_button"
                  onClick={() => setGiftProductCreate(true)}
                  disabled={isProcessing}
                >
                  + 商品発送
                </button>
              </div>
            )}
            {gift.product_list.map((product) => (
              <ProductItem
                province={gift.province}
                product={product}
                openGiftProductEdit={() => {
                  setGiftProductEdit(true);
                  setTargetProductInfo(product);
                }}
                cancelGiftProduct={() => giftProductCancel(product.id)}
                writeIsAllowed={writeIsAllowed}
                key={product.id}
              />
            ))}
          </div>
        </div>
      </div>
      <ErrorMessage error={error} clear={() => dispatch(clearError())} />
      {isProcessing && <Loader />}
      {isGiftEdit && (
        <Modal close={() => setGiftEdit(false)}>
          <GiftEdit
            gift={gift}
            giftLabelList={giftLabelList}
            close={() => {
              setGiftEdit(false);
              fetchGift();
            }}
          />
        </Modal>
      )}
      {isGiftProductCreate && (
        <Modal close={() => setGiftProductCreate(false)}>
          <GiftProductCreate
            gift={gift}
            close={() => {
              setGiftProductCreate(false);
              fetchGift();
            }}
          />
        </Modal>
      )}
      {isGiftProductEdit && (
        <Modal close={() => setGiftProductEdit(false)}>
          <GiftProductEdit
            province={gift.province}
            product={targetProductInfo}
            close={() => {
              setGiftProductEdit(false);
              fetchGift();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default GiftDetail;

const ProductItem = (props) => {
  const { status, name, delivery_date, delivery_time, shipment_id } =
    props.product;

  const writeIsAllowed = props.writeIsAllowed;

  return (
    <div className="product_item">
      <table>
        <tbody>
          <tr>
            <th>商品名</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>配送状況</th>
            <td>{status}</td>
          </tr>
          <tr>
            <th>配送予定日</th>
            <td>{delivery_date || "なし"}</td>
          </tr>
          <tr>
            <th>配送予定時間帯</th>
            <td>{parseDeliveryTime(delivery_time)}</td>
          </tr>
          <tr>
            <th>出庫ID</th>
            <td>{shipment_id || "未設定"}</td>
          </tr>
        </tbody>
      </table>
      {status !== "発送済み" &&
        status !== "キャンセル済み" &&
        writeIsAllowed && (
          <>
            <div className="product_button_container">
              <button
                className="design_button"
                onClick={() => props.openGiftProductEdit()}
              >
                内容を編集
              </button>
              <button
                className="design_button"
                onClick={() => props.cancelGiftProduct()}
              >
                キャンセル
              </button>
            </div>
          </>
        )}
    </div>
  );
};
