export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const GET_ORDER_INFO_REQUEST = "GET_ORDER_INFO_REQUEST";
export const GET_ORDER_INFO_SUCCESS = "GET_ORDER_INFO_SUCCESS";
export const GET_ORDER_INFO_FAILURE = "GET_ORDER_INFO_FAILURE";

export const CHANGE_ORDER_REQUEST = "CHANGE_ORDER_REQUEST";
export const CHANGE_ORDER_SUCCESS = "CHANGE_ORDER_SUCCESS";
export const CHANGE_ORDER_FAILURE = "CHANGE_ORDER_FAILURE";

export const CHANGE_COUPON_REQUEST = "CHANGE_COUPON_REQUEST";
export const CHANGE_COUPON_SUCCESS = "CHANGE_COUPON_SUCCESS";
export const CHANGE_COUPON_FAILURE = "CHANGE_COUPON_FAILURE";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const CHANGE_STATUS_ORDER_REQUEST = "CHANGE_STATUS_ORDER_REQUEST";
export const CHANGE_STATUS_ORDER_SUCCESS = "CHANGE_STATUS_ORDER_SUCCESS";
export const CHANGE_STATUS_ORDER_FAILURE = "CHANGE_STATUS_ORDER_FAILURE";

export const REFUND_ORDER_REQUEST = "REFUND_ORDER_REQUEST";
export const REFUND_ORDER_SUCCESS = "REFUND_ORDER_SUCCESS";
export const REFUND_ORDER_FAILURE = "REFUND_ORDER_FAILURE";

export const CHANGE_ORDER_PRODUCT_REQUEST = "CHANGE_ORDER_PRODUCT_REQUEST";
export const CHANGE_ORDER_PRODUCT_SUCCESS = "CHANGE_ORDER_PRODUCT_SUCCESS";
export const CHANGE_ORDER_PRODUCT_FAILURE = "CHANGE_ORDER_PRODUCT_FAILURE";

export const CHANGE_ORDER_SHIPMENT_REQUEST = "CHANGE_ORDER_SHIPMENT_REQUEST";
export const CHANGE_ORDER_SHIPMENT_SUCCESS = "CHANGE_ORDER_SHIPMENT_SUCCESS";
export const CHANGE_ORDER_SHIPMENT_FAILURE = "CHANGE_ORDER_SHIPMENT_FAILURE";

export const SEND_ORDER_MAIL_REQUEST = "SEND_ORDER_MAIL_REQUEST";
export const SEND_ORDER_MAIL_SUCCESS = "SEND_ORDER_MAIL_SUCCESS";
export const SEND_ORDER_MAIL_FAILURE = "SEND_ORDER_MAIL_FAILURE";

export const REFUND_PRODUCT_INFO_REQUEST = "REFUND_PRODUCT_INFO_REQUEST";
export const REFUND_PRODUCT_INFO_SUCCESS = "REFUND_PRODUCT_INFO_SUCCESS";
export const REFUND_PRODUCT_INFO_FAILURE = "REFUND_PRODUCT_INFO_FAILURE";

export const CANCEL_PRODUCT_REQUEST = "CANCEL_PRODUCT_REQUEST";
export const CANCEL_PRODUCT_SUCCESS = "CANCEL_PRODUCT_SUCCESS";
export const CANCEL_PRODUCT_FAILURE = "CANCEL_PRODUCT_FAILURE";

export const SET_ORDER_FILTER = "SET_ORDER_FILTER";

export const CLEAR_ORDER_FILTER = "CLEAR_ORDER_FILTER";
export const CLEAR_ORDER_STATUS = "CLEAR_ORDER_STATUS";
export const CLEAR_ORDER_ERROR = "CLEAR_ORDER_ERROR";

export const CHANGE_ORDER_PAGE = "CHANGE_ORDER_PAGE";
