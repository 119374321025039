// libs
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// action
import { createSale, clearStatus } from "redux/actions/sale";

// style
import "./style.scss";

const SaleCreate = ({ close }) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isLimitNone, setIsLimitNone] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.sale.isProcessing);
  const isCreateComplete = useSelector((state) => state.sale.isCreateComplete);

  const dispatch = useDispatch();

  const FIELDS = {
    name: "name",
    coupon: "coupon",
    startAt: "start_at",
    endAt: "end_at",
    isOpen: "is_open",
  };

  let elements = {};

  const handleChange = () => {
    setSubmitEnabled();
  };

  const setSubmitEnabled = () => {
    if (!elements) {
      return;
    }

    const isNoEmptyFields = Object.values(FIELDS).every((field) => {
      if (field === FIELDS.expirationDate) {
        return isLimitNone ? true : elements[field].value;
      }
      return elements[field].value;
    });

    setIsSubmitEnabled(isNoEmptyFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.confirm("作成しますか？")) {
      create();
    }
  };

  const create = () => {
    const data = {
      name: elements[FIELDS.name].value,
      coupon: elements[FIELDS.coupon].value,
      start_at: elements[FIELDS.startAt].value,
      end_at: elements[FIELDS.endAt].value,
      is_open: elements[FIELDS.isOpen].value,
    };

    if (token) {
      dispatch(createSale(token, data));
    }
  };

  useEffect(() => {
    if (isCreateComplete) {
      window.alert("作成しました");
      dispatch(clearStatus());
      close();
    }
  }, [isCreateComplete]);

  useEffect(() => {
    setSubmitEnabled();
  }, [isLimitNone]);

  return (
    <div className="sale_create create">
      <form
        className="create_form"
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={(el) => (elements = el && el.elements)}
      >
        <table className="product_item">
          <tbody>
            <tr>
              <th>セール名</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.name}
                />
              </td>
            </tr>
            <tr>
              <th>クーポンコード</th>
              <td>
                <input
                  className="design_input"
                  type="text"
                  name={FIELDS.coupon}
                />
              </td>
            </tr>
            <tr>
              <th>開始日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.startAt}
                />
              </td>
            </tr>
            <tr>
              <th>終了日時</th>
              <td>
                <input
                  className="design_input"
                  type="datetime-local"
                  name={FIELDS.endAt}
                />
              </td>
            </tr>
            <tr>
              <th>ステータス</th>
              <td>
                <select className="design_select" name={FIELDS.isOpen}>
                  <option value={true}>公開</option>
                  <option value={false}>非公開</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="button_container">
          <input
            className="design_button"
            type="submit"
            value="作成"
            disabled={!isSubmitEnabled || isProcessing}
          />
        </div>
      </form>
    </div>
  );
};

export default SaleCreate;
