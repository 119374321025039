// libs
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// actions
import { updateUgc, clearStatus } from "redux/actions/ugc";

// style
import "./style.scss";

const UgcMediaEdit = ({ ucgID, media, close }) => {
  const { id, is_video, video_url, image_url, is_main } = media;

  const token = useSelector((state) => state.auth.token);
  const isProcessing = useSelector((state) => state.ugc.isProcessing);
  const isUpdateComplete = useSelector((state) => state.ugc.isUpdateComplete);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (window.confirm("このコンテンツを設定しますか？")) {
      changeUgc();
    }
  };

  const changeUgc = () => {
    const data = {
      ugc_id: ucgID,
      main_media_id: id,
    };

    if (token) {
      dispatch(updateUgc(token, data));
    }
  };

  useEffect(() => {
    if (isUpdateComplete) {
      window.alert("変更しました");
      dispatch(clearStatus());
      close();
    }
  }, [isUpdateComplete]);

  return (
    <div className="ugc_media_select edit">
      <div className="image_container">
        {is_video ? (
          <video muted loop playsInline autoPlay>
            <source src={video_url} type="video/mp4" />
          </video>
        ) : (
          <img src={image_url} alt="instagram" />
        )}
      </div>
      <div className="button_container">
        <button
          className="design_button"
          onClick={handleSubmit}
          disabled={isProcessing || is_main}
        >
          {is_main ? "設定中" : "このコンテンツを設定"}
        </button>
      </div>
    </div>
  );
};

export default UgcMediaEdit;
