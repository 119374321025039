export const GET_OFFLINE_STORE_SALES_REQUEST =
  "GET_OFFLINE_STORE_SALES_REQUEST";
export const GET_OFFLINE_STORE_SALES_SUCCESS =
  "GET_OFFLINE_STORE_SALES_SUCCESS";
export const GET_OFFLINE_STORE_SALES_FAILURE =
  "GET_OFFLINE_STORE_SALES_FAILURE";

export const CREATE_OFFLINE_STORE_SALE_REQUEST =
  "CREATE_OFFLINE_STORE_SALE_REQUEST";
export const CREATE_OFFLINE_STORE_SALE_SUCCESS =
  "CREATE_OFFLINE_STORE_SALE_SUCCESS";
export const CREATE_OFFLINE_STORE_SALE_FAILURE =
  "CREATE_OFFLINE_STORE_SALE_FAILURE";

export const GET_OFFLINE_STORE_SALE_INFO_REQUEST =
  "GET_OFFLINE_STORE_SALE_INFO_REQUEST";
export const GET_OFFLINE_STORE_SALE_INFO_SUCCESS =
  "GET_OFFLINE_STORE_SALE_INFO_SUCCESS";
export const GET_OFFLINE_STORE_SALE_INFO_FAILURE =
  "GET_OFFLINE_STORE_SALE_INFO_FAILURE";

export const UPDATE_OFFLINE_STORE_SALE_REQUEST =
  "UPDATE_OFFLINE_STORE_SALE_REQUEST";
export const UPDATE_OFFLINE_STORE_SALE_SUCCESS =
  "UPDATE_OFFLINE_STORE_SALE_SUCCESS";
export const UPDATE_OFFLINE_STORE_SALE_FAILURE =
  "UPDATE_OFFLINE_STORE_SALE_FAILURE";

export const CLEAR_OFFLINE_STORE_SALE_STATUS =
  "CLEAR_OFFLINE_STORE_SALE_STATUS";

export const SET_OFFLINE_STORE_SALE_FILTER = "SET_OFFLINE_STORE_SALE_FILTER";
export const CLEAR_OFFLINE_STORE_SALE_FILTER =
  "CLEAR_OFFLINE_STORE_SALE_FILTER";

export const CLEAR_OFFLINE_STORE_SALE_ERROR = "CLEAR_OFFLINE_STORE_SALE_ERROR";
export const CHANGE_OFFLINE_STORE_SALE_PAGE = "CHANGE_OFFLINE_STORE_SALE_PAGE";
